import React from 'react'
import styles from './style.module.scss'

const Footer = () => {
  return (
    <div className={styles.newFooter}>
      <div className={styles.copyright}>
        <span>
          © {`${new Date().getFullYear()} `}
          <a href="https://entri.app" target="_blank" rel="noopener noreferrer" className='d-block ml-2'>
            Entri.app
          </a>
          <span className='d-block mr-1'>v{process.env.REACT_APP_VERSION} | All rights reserved </span>
        </span>
      </div>

    </div>
  )
}

export default Footer
