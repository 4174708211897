import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../..'
import { getProfile, getStaffProfiles } from '../../api/profile'
import { Profile } from '../../types/Profile'

export const initialProfilesList: Profile.ProfileListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const profileListSlice = createSlice({
  name: 'profilesSlice',
  initialState: initialProfilesList,
  reducers: {
    requestProfiles(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedProfilesFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedProfiles(state, action: PayloadAction<Profile.ProfileListPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: Profile.Filter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
  },
})

export const { receivedProfiles, requestProfiles, requestedProfilesFailed } =
  profileListSlice.actions

export const fetchProfiles =
  (filter: Profile.Filter): AppThunk =>
  async dispatch => {
    dispatch(requestProfiles())
    try {
      const response = await getStaffProfiles(filter)
      if (response) {
        const { results: data, ...meta } = response.data
        dispatch(
          receivedProfiles({
            data,
            filter,
            meta,
          }),
        )
      }
    } catch (error) {
      dispatch(requestedProfilesFailed())
    }
  }

export const selectStaffProfiles = (state: RootState) => state.staffProfiles

export default profileListSlice.reducer

//
// Profile
//

export const initialState: Profile.ProfileState = {
  data: null,
  isLoading: false,
  hasError: false,
}

const profileSlice = createSlice({
  name: 'staffProfile',
  initialState,
  reducers: {
    requestProfile(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedProfileFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedProfile(state, action: PayloadAction<Profile.User>) {
      return { ...state, data: action.payload, isLoading: false, hasError: false }
    },
    resetProfileDetails() {
      return initialState
    },
  },
})

const { requestProfile, receivedProfile, requestedProfileFailed, resetProfileDetails } =
  profileSlice.actions

export const fetchProfile =
  (profileId: number): AppThunk =>
  async dispatch => {
    dispatch(requestProfile())
    try {
      const response = await getProfile(profileId)
      if (response) {
        dispatch(receivedProfile(response.data))
      }
    } catch (error) {
      dispatch(requestedProfileFailed())
    }
  }

export const resetProfile = (): AppThunk => dispatch => {
  dispatch(resetProfileDetails())
}

export const selectProfile = (state: RootState) => state.staffProfile

export const staffProfile = profileSlice.reducer
