import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import store from 'store'
import { login, logout } from '../../api/auth'
import { getCurrentUser, getUsers } from '../../api/lookups'
import { AppThunk, RootState } from '../..'
import { LoginImpl, LogoutImpl, UserImpl, UserListState, UserState } from './model'
import { UserFilter } from './types'

const initialState: UserState = {
  data: {
    id: '',
    name: '',
    isVerified: false,
    role: null,
    email: '',
    avatar: '',
    authorized: !!store.get('token'),
    permissions: null,
    requestedPath: '',
  },
  isLoading: false,
  hasError: false,
}
export const userSlice = createSlice({
  name: 'user_v2',
  initialState,
  reducers: {
    requestUser: state => {
      state.isLoading = true
      state.hasError = false
    },
    requestUserSuccess: state => {
      state.isLoading = false
      state.hasError = false
    },
    requestUserError: state => {
      state.isLoading = false
      state.hasError = true
    },
    receivedUser: (state, action: PayloadAction<UserImpl>) => {
      state.data = action.payload
    },
    resetUser: () => initialState,
  },
})

const { requestUser, requestUserSuccess, requestUserError, receivedUser, resetUser } =
  userSlice.actions

export const loginUser =
  (userLoginDetails: LoginImpl): AppThunk =>
    async dispatch => {
      const { phone, password } = userLoginDetails
      dispatch(requestUser())
      try {
        const response = await login(phone, password)
        if (response) {
          store.set('token', response.data.token)
          dispatch(loadCurrentUser())
          dispatch(requestUserSuccess())
        }
      } catch (error) {
        dispatch(requestUserError())
        // dispatch(showErrorNotification('Phone number / Password is wrong'))
      }
    }
export const logoutUser =
  (logoutData: LogoutImpl): AppThunk =>
    async dispatch => {
      const { shouldCallAPI = true } = logoutData
      try {
        if (shouldCallAPI) {
          await logout()
        }
      } catch (e) {
        console.log(e)
      }
      //   dispatch(deleteCookie, 'csrftoken')
      store.remove('token')
      store.remove('user')
      dispatch(resetUser())
    }

export const loadCurrentUser = (): AppThunk => async dispatch => {
  dispatch(requestUser())
  try {
    let data = null
    if (!store.get('user')) {
      const response = await getCurrentUser()
      data = response.data
    } else {
      data = store.get('user')
    }
    if (data) {
      dispatch(
        receivedUser({
          ...data,
          authorized: true,
        }),
      )
      store.set('user', data)
      dispatch(requestUserSuccess())
    }
  } catch (e) {
    console.error(e)
    dispatch(requestUserError())
  }
}

export const selectUser = (state: RootState) => state.user
export const userDataSelector = (state: RootState) => ({ email: state.user.email, name: state.user.name })

export default userSlice.reducer

const initialUserListState: UserListState = {
  data: [],
  isLoading: false,
  hasError: false,
}

const userListSlice = createSlice({
  name: 'userList',
  initialState: initialUserListState,
  reducers: {
    requestUserList: state => {
      state.isLoading = true
      state.hasError = false
    },
    requestUserListError: state => {
      state.isLoading = false
      state.hasError = true
    },
    receivedUserList: (state, action: PayloadAction<UserImpl[]>) => {
      state.data = action.payload
      state.isLoading = false
      state.hasError = false
    },
    resetUser: () => initialUserListState,
  },
})

const { requestUserList, requestUserListError, receivedUserList } = userListSlice.actions

export const fetchUserList =
  (filter: UserFilter = {}): AppThunk =>
    async dispatch => {
      try {
        dispatch(requestUserList())
        const response = await getUsers(filter)
        if (response.data) dispatch(receivedUserList(response.data))
      } catch (error) {
        dispatch(requestUserListError)
      }
    }
export const selectUserList = (state: RootState) => state.userListReducer

export const userListReducer = userListSlice.reducer
