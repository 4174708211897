import httpService from '../httpService'
import { MENTOR_ROLES } from '../pages/batches/constants'
import { PackUpdateStatus } from '../pages/batches/types'
import { Filter } from '../redux/students/types'
import qs from 'qs'

/**
 * Returns list of batches
 */
export function listBatches(filters: any) {
  return httpService.get('/v9/batches/', {
    params: filters,
  })
}

/**
 * Returns batch details
 */
export function getBatch(batchId: string) {
  return httpService.get(`/v9/batches/${batchId}/`)
}

/**
 * Returns users (mentors and coordinators)
 */
export function getUsers(username: string) {
  return httpService.get('/v4/content/sources/users/', {
    params: {
      username,
      role: MENTOR_ROLES,
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

/**
 * Create new batch
 */
export function createBatch(data: any) {
  return httpService.post(`/v9/batches/`, data)
}

/**
 * Update batch details
 */
export function updateBatch(batchId: string, data: any) {
  return httpService.put(`/v9/batches/${batchId}/`, data)
}

/**
 * Archive/Unarchive batch
 */
export function updateBatchStatus(batchId: string, action: 'UNARCHIVE' | 'ARCHIVE') {
  return httpService.patch(`/v9/batches/${batchId}/`, { isArchived: action === 'ARCHIVE' })
}

/**
 * Add students to batch
 */
export function addStudents(batchId: string, data: Object) {
  return httpService.post(`/v9/batches/${batchId}/users/add/`, data)
}

/**
 * Get students list
 */
export function getStudents(batchId: string, filters?: Filter) {
  return httpService.get(`/v9/batches/${batchId}/users/`, {
    params: filters,
  })
}

/**
 * Remove student from batch
 */
export function removeStudent(batchId: number | string, userId: number | string) {
  return httpService.delete(`/v9/batches/${batchId}/users/${userId}/`)
}

/**
 * Map pack to batch
 */
export function addPack(batchId: number | string, data: any) {
  return httpService.post(`/v9/batches/${batchId}/packs/`, data)
}

/**
 * Returns packs of batch
 */
export function fetchPacks(batchId: number | string) {
  return httpService.get(`/v9/batches/${batchId}/packs/`)
}

/**
 * Update pack status
 */
export function updatePackStatus(data: PackUpdateStatus) {
  return httpService.patch(`v9/batches/${data.batchId}/packs/${data.packId}/`, data.updatedValues)
}
