import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../..";
import { FilterPayload, InitialState, LiveClassList } from "./types"
import { LiveClassAttendance } from './types';
const initialState: InitialState = {
    students: {
        data: null,
        loading: false,
        error: null,
        filteredResult: null
    },
    lives: {
        data: null,
        loading: false,
        error: null,
        filteredResult: null
    },
    filtersApplied: {
        name: undefined,
        phone: undefined,
        status: undefined,
        lives: undefined,
        page: undefined
    },
    filteringInProgress: false
}

const batchAttendance = createSlice({
    name: 'batchAttendance',
    initialState: initialState,
    reducers: {
        fetchStudents: (state) => {
            state.students.loading = true;
            state.filteringInProgress = true;
        },
        fetchStudentSuccess: (state, action: PayloadAction<LiveClassAttendance.Students>) => {
            state.students = {
                loading: false,
                data: action.payload,
                error: null,
                filteredResult: null
            }
            state.filteringInProgress = state.lives.data === null;
        },
        fetchStudentError: (state) => {
            state.students = {
                loading: false,
                data: null,
                error: "Some Error Occured while fetching StudentsList",
                filteredResult: null
            }
        },
        filteredStudents: (state, action: PayloadAction<LiveClassAttendance.Students>) => {
            state.students.loading = false;
            state.students.filteredResult = action.payload;
        },
        fetchLives: (state) => {
            state.lives.loading = true;
            state.filteringInProgress = true
            state.lives = {
                loading: true,
                data: null,
                error: null,
                filteredResult: null
            }
        },
        fetchLiveSuccess: (state, action: PayloadAction<LiveClassList.Root>) => {
            state.lives = {
                loading: true,
                data: action.payload,
                error: null,
                filteredResult: null
            }
            state.filteringInProgress = state.students.data === null;
        },
        fetchLiveError: (state) => {
            state.lives = {
                loading: false,
                data: null,
                filteredResult: null,
                error: "Some error occured while fetching Live Classes"
            }
        },
        applyFilters: (state, action: PayloadAction<FilterPayload>) => {
            const { phone, status, lives, name, fromDate, toDate } = action.payload;
            state.filteringInProgress = true
            state.filtersApplied = {
                phone, status, lives, name, page: 1, fromDate, toDate
            }
        },
        changeLivePage: (state, action: PayloadAction<number | string>) => {
            state.filtersApplied.page = action.payload;
        },
        filteredLives: (state, action) => {
            state.lives.loading = false;
            state.lives.filteredResult = action.payload;
        },
        filteringStatus: (state, action: PayloadAction<boolean>) => {
            state.filteringInProgress = action.payload
        },
        resetFilters: (state) => {
            state.filtersApplied = {
                name: undefined,
                phone: undefined,
                status: undefined,
                lives: undefined,
                page: undefined
            }
        }
    },
})


export const studentsSelector = (state: RootState) => {
    const { filteredResult, loading, error } = state.studentAttendance.students;
    return {
        data: filteredResult,
        loading, error
    }
};

export const livesForFilterSelector = (state: RootState) => state.studentAttendance.lives;
export const livesSelector = (state: RootState) => {
    const { filteredResult, loading, error } = state.studentAttendance.lives
    return {
        data: filteredResult, loading, error
    }
};
export const loadingSelector = (state: RootState) => (state.studentAttendance.filteringInProgress)

export const {
    fetchStudents,
    fetchStudentSuccess,
    fetchStudentError,
    fetchLives,
    fetchLiveSuccess,
    fetchLiveError,
    applyFilters,
    filteredLives,
    filteredStudents,
    changeLivePage,
    filteringStatus,
    resetFilters
} = batchAttendance.actions;


export default batchAttendance.reducer;