import { all, takeEvery, put, call } from 'redux-saga/effects'
import store from 'store'
import qs from 'qs'
import { history, store as reduxStore } from 'index'
import { showErrorNotification } from 'redux/notifications/actions'
import { CHANGE_SETTING, APPLY_UPDATE, updateSettings, updateSuccess } from './actions'
import clearCaches from '../../helper/clearCaches'

export function* CHANGE_DASH_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put(
    updateSettings({
      [setting]: value,
    }),
  )
}

export function* SETUP() {
  // load settings from url on app load
  const changeSettings = search => {
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    Object.keys(query).forEach(key => {
      reduxStore.dispatch(
        updateSettings({
          setting: key,
          value: query[key] === 'true',
        }),
      )
    })
  }
  yield changeSettings(history.location.search)
  yield history.listen(params => {
    const { search } = params
    changeSettings(search)
  })

  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = global.window.innerWidth < 768
    const prevState = store.get('app.settings.isMobileView')
    if (currentState !== prevState || load) {
      reduxStore.dispatch(
        updateSettings({
          setting: 'isMobileView',
          value: currentState,
        }),
      )
    }
  }
  yield isMobileView(true)
  yield window.addEventListener('resize', () => {
    isMobileView()
  })
}

/**
 * Invoked when use wants to apply new update to dashboard
 */
export function* APPLY_UPDATE_DASH() {
  try {
    yield put(updateSuccess())
    yield call(clearCaches)
    yield window.location.reload()
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while applying update'))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(CHANGE_SETTING, CHANGE_DASH_SETTING), // run once on app load to init listeners
    takeEvery(APPLY_UPDATE, APPLY_UPDATE_DASH),
  ])
}
