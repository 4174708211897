// import { AxiosResponse } from 'axios'
import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { AccessControlsResponse, PermissionLevels, ViewPermissions } from '../types/AccessControls'

export const getViewAccess = async (): Promise<ViewPermissions> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        track: true,
        job: true,
      })
    }, 2000)
  })
}

export const getAccess = async (
  permissionLevel: PermissionLevels,
): Promise<AxiosResponse<AccessControlsResponse>> => {
  return httpService.get('/v7/content/sources/users/permissions/', {
    params: {
      permission_level: permissionLevel,
    },
  })
}
