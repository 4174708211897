import { sendMixpanelEvent } from '../mixPanel'

export const mixpanelLoginStatusEvent = ({
    status, userId, method = "PASSWORD"
}: { status: "success" | "fail", userId: number | string | undefined, method?: string }) => {
    sendMixpanelEvent("login", {
        user_id: userId,
        status: status,
        login_method: method?.toLowerCase()
    })
}