import { isEmpty } from 'lodash'
import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import { VideoPlaylistAction } from './types'
import {
  FETCH_EDITORS,
  FETCH_CAMERA_OPERATORS,
  FETCH_TEACHERS,
  FETCH_VIDEO_PLAYLISTS,
} from './actions'
import { showErrorNotification } from '../notifications/actions'
import {
  requestLookups,
  receivedLookups,
  requestedLookupsFailed,
  requestVideoPlaylists,
  receivedVideoPlaylists,
  requestedVideoPlaylistsFailed,
} from './slice'
import {
  getEditors,
  getCameraOperators,
  getTeachers,
  getVideoPlaylists,
  getVideos,
} from '../../api/lookups'
import { AxiosResponse } from 'axios'
interface lookupsActionImps {
  type: string
  name: string
}

export function* GET_LOOKUPS({ name }: lookupsActionImps) {
  try {
    // is lookups data is already present in the store
    const isLoaded: boolean = yield select(state => !isEmpty(state?.lookups?.[name]?.data))
    // is a fetch request currently running
    const isLoading: boolean = yield select(state => state?.lookups?.[name]?.isLoading)
    if (isLoaded || isLoading) return

    yield put(requestLookups({ name }))

    let response = { data: [] }
    switch (name) {
      case 'teachers':
        response = yield call(getTeachers)
        break
      case 'editors':
        response = yield call(getEditors)
        break
      case 'cameraOperators':
        response = yield call(getCameraOperators)
        break
      default:
        yield put(showErrorNotification(`Something went wrong while fetching ${name}`))
    }
    yield put(receivedLookups({ data: response.data, name }))
  } catch (error) {
    yield put(showErrorNotification(`Something went wrong while fetching ${name}`))
    yield put(requestedLookupsFailed({ name }))
  }
}

/**
 * Invoked when getVideoPlaylists is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* GET_VIDEO_PLAYLISTS({ payload: { filters } }: VideoPlaylistAction) {
  try {
    yield put(requestVideoPlaylists())

    const response: AxiosResponse = yield call(getVideoPlaylists, filters)
    const { data } = response
    yield put(receivedVideoPlaylists({ data, filters }))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching video playlist'))
    yield put(requestedVideoPlaylistsFailed())
  }
}

export default function* rootSaga() {
  yield all([takeLatest(FETCH_EDITORS, GET_LOOKUPS)])
  yield all([takeLatest(FETCH_CAMERA_OPERATORS, GET_LOOKUPS)])
  yield all([takeLatest(FETCH_TEACHERS, GET_LOOKUPS)])
  yield all([takeLatest(FETCH_VIDEO_PLAYLISTS, GET_VIDEO_PLAYLISTS)])
}
