import httpService from 'httpService'
// import qs from 'qs'
import { QUESTION_STATUS } from '../helper/constants'

export function getQuestions(filter) {
  return httpService.get('/v8/questions', {
    params: {
      ...filter,
    },
  })
}

export function mapQuestions(data) {
  const URL = `/v8/platforms/${data.platformId}/questions/map/`

  const options = {
    params: {
      'questions': data.questionIds.join(',')
    },
  };

  return httpService.patch(URL, {}, options)
}

export function unmapQuestions(data) {
  const URL = `/v8/platforms/${data.platformId}/questions/map/`

  const options = {
    params: {
      'questions': data.questionIds.join(',')
    },
  };

  return httpService.delete(URL, options)
}

export function createQuestion(payload) {
  return httpService.post('/v4/content/sources/questions/', { ...payload })
}

export function updateQuestion(id, lang, payload) {
  return httpService.put(`/v4/content/sources/questions/${id}/${lang}/`, { ...payload })
}

export function translateQuestion(id, payload) {
  return httpService.post(`/v4/content/sources/questions/translate/${id}/`, { ...payload })
}

/**
 * Fetch question
 * @param {number} id question id
 */
export function getQuestion(id) {
  return httpService.get(`/v4/content/sources/questions/${id}/`)
}

/**
 * Fetch question
 * @param {number} id question id
 * @param {object} payload question details
 */
export function approveQuestion(id, payload) {
  return httpService.post(`/v4/content/sources/questions/${id}/approve/`, { ...payload })
}

/**
 * Appeal question rejection by data specialist
 * @param {number} questionGroupId id of question group
 * @param {string} languageCode question language code
 */
export function appealQuestion(questionGroupId, languageCode) {
  return httpService.post(`/v4/content/review/${questionGroupId}/appeal_question/`, {
    languageCode,
  })
}

/**
 * Accept question appeal
 * @param {number} questionGroupId id of question group
 * @param {string} languageCode question language code
 */
export function acceptAppeal(questionGroupId, languageCode) {
  return httpService.post(`/v4/content/review/${questionGroupId}/approve_appeal/`, { languageCode })
}

/**
 * Reject question appeal
 * @param {number} questionGroupId id of question group
 * @param {string} rejectionPayload contains languageCode and comments
 */
export function rejectAppeal(questionGroupId, rejectionPayload) {
  return httpService.post(`/v4/content/review/${questionGroupId}/reject_appeal/`, rejectionPayload)
}

/**
 * Reject question
 * @param {number} jobId job id
 * @param {number} qcId question collection id
 * @param {number} questionGroupId question group id
 * @param {object} rejectionPayload contains array of questionErrors and comment
 */
export function rejectQuestion(jobId, qcId, questionGroupId, rejectionPayload) {
  return httpService.post(
    `/v4/content/review/jobs/${jobId}/question-collections/${qcId}/question-groups/${questionGroupId}/status/`,
    rejectionPayload,
  )
}

/**
 * Fetch raw markdown version of question
 * @param {number} id question id
 * @param {string} lang question language
 */
export function getRawQuestion(id, lang) {
  return httpService.get(`/v4/content/sources/questions/raw/${id}/${lang}/`)
}

export function checkDuplicates(questionText) {
  return httpService.post(`/v4/content/sources/questions/search/`, {
    questionText,
  })
}

// QA

/**
 * Fetch QA questions
 * @param {number} jobId job id
 * @param {number} qcId question collection id
 * @param {Object} filter listing filter
 */
export function getQAListing(jobId, qcId, filter) {
  return httpService.get(
    `v4/content/review/jobs/${jobId}/question-collections/${qcId}/question-groups/minimal/`,
    {
      params: filter,
    },
  )
}

/**
 * Fetch QA questions
 * @param {number} jobId job id
 * @param {number} qcId question collection id
 * @param {Object} filter listing filter
 */
export function getQAReviewQuestions(jobId, qcId, filter) {
  return httpService.get(
    `v4/content/review/jobs/${jobId}/question-collections/${qcId}/question-groups/`,
    {
      params: filter,
    },
  )
}

/**
 * Fetch Appealed question for Academic Head
 * @param {string} language filter by language code
 */
export function getAppealQuestions(language) {
  return httpService.get('v4/content/review/appealed_questions/', {
    params: {
      language,
    },
  })
}

/**
 * Fetches expired questions
 * @param {object} filter question filter object
 */
export function getExpiredQuestions(filter) {
  return httpService.get('v4/content/sources/questions/expired_questions/', {
    params: {
      ...filter,
    },
  })
}

/**
 * Submit review
 * @param {Object} reviewDetails
 */
export function submitReview(reviewDetails) {
  return httpService.post(`/v4/content/review/question-feedbacks/`, reviewDetails)
}

export function getStatusClass(status) {
  if (!status) return ''
  switch (status) {
    case QUESTION_STATUS.APPROVED:
      return 'bg-success'
    case QUESTION_STATUS.IN_APPEAL:
      return 'bg-warning'
    case QUESTION_STATUS.PENDING:
      return 'bg-primary'
    case QUESTION_STATUS.REJECTED:
      return 'bg-danger'
    default:
      return 'bg-dark'
  }
}

export function getCommonMistakes() {
  return httpService.get(`v4/content/review/question_errors/`)
}

/**
 * Decides if question edit should be enabled or disabled for CEs
 * @param {object} question question object
 * @param {string} workType work type of the job
 */
export function isQuestionEditDisabled(question, workType) {
  if (!question) return undefined

  let isEditDisabled = true

  if (
    question.status === QUESTION_STATUS.REJECTED ||
    ['PROOFREADING', 'MODIFICATION', 'TRANSLATION'].includes(workType)
  ) {
    isEditDisabled = false
  }
  return isEditDisabled
}
