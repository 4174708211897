import renderLatexHTML from 'helper/renderLatexHTML'

/**
 * Converts empty string to null
 * @param {string} value Text to test
 */
const nullifyEmptyString = value => {
  if (!value) return null
  return value.trim().length <= 0 ? null : value
}

/**
 * This is a question serializer, Consumes question in form format
 *  and outputs server format
 *
 * @param {object} question question object
 * @param {object} questionCount number of questions in compounded question
 */
export const questionSerializer = ({ instructionRaw, sharedStatementRaw, questions }) => ({
  instructionRaw: nullifyEmptyString(instructionRaw),
  sharedStatementRaw: nullifyEmptyString(sharedStatementRaw),
  questions: questions.map(question => {
    const questionObject = {
      statementRaw: question.statementRaw,
      category: question.category,
      solutionRaw: question.solutionRaw,
      correctAnswerScore: question.correctAnswerScore,
      wrongAnswerScore: question.wrongAnswerScore,
    }
    if (question.category === 'NAT') {
      return {
        ...questionObject,
        correctAnswer: question.correctAnswer,
        natAnswerRangeFrom: question.natAnswerRangeFrom,
        natAnswerRangeTo: question.natAnswerRangeTo,
      }
    }
    return {
      ...questionObject,
      choiceARaw: nullifyEmptyString(question.choiceARaw),
      choiceBRaw: nullifyEmptyString(question.choiceBRaw),
      choiceCRaw: nullifyEmptyString(question.choiceCRaw),
      choiceDRaw: nullifyEmptyString(question.choiceDRaw),
      choiceERaw: nullifyEmptyString(question.choiceERaw),
      correctChoices:
        question.category !== 'MCQ_MCA' ? [question.correctChoice] : question.correctChoices,
    }
  }),
})

/**
 * When question needs to be translated, choice marked as correct
 * and the module for the question needs to be selected
 * and disabled to prevent it from being changed.
 *
 * so this function returns just those two data to initialize
 * to the interface compounded question state.
 *
 * @param {object} question question object
 * @param {object} questionCount number of questions in compounded question
 */
export const questionInitTransSerializer = (
  { platform, subject, module, topic, expiresAt, ...compoundedQuestion },
  translationLanguage,
) => {
  return {
    platform,
    subject,
    module,
    topic,
    language: translationLanguage,
    expiresAt,
    questions: compoundedQuestion.questions.map(question => ({
      category: question.category,
      correctChoices:
        question.category !== 'MCQ_MCA' ? [question.correctChoice] : question.correctChoices,
      correctAnswer: question.correctAnswer,
      natAnswerRangeFrom: question.natAnswerRangeFrom,
      natAnswerRangeTo: question.natAnswerRangeTo,
      correctAnswerScore: question.correctAnswerScore,
      wrongAnswerScore: question.wrongAnswerScore,
    })),
  }
}

/**
 * Converts question form object => listing question object model
 * @param {Object} param0 question form object
 * @param {number} questionCount number of questions in compounded question
 */
export const questionPreviewSerializer = ({ instructionRaw, sharedStatementRaw, questions }) => ({
  instruction: renderLatexHTML(instructionRaw),
  sharedStatement: renderLatexHTML(sharedStatementRaw),
  questions: questions?.map(question => ({
    statement: renderLatexHTML(question.statementRaw),
    choices: Array.from(Array(5).keys()).map(choice => {
      const optionAlpha = String.fromCharCode('A'.charCodeAt() + choice)
      return {
        id: choice,
        isCorrect:
          question.category === 'MCQ'
            ? question.correctChoice === optionAlpha.toLowerCase()
            : question.correctChoices?.includes(optionAlpha.toLowerCase()),
        choiceStatement: renderLatexHTML(question[`choice${optionAlpha}Raw`]),
      }
    }),
    category: question.category,
    correctChoice: question.correctChoice,
    correctChoices: question.correctChoices,
    correctAnswer: question.correctAnswer,
    natAnswerRangeFrom: question.natAnswerRangeFrom,
    natAnswerRangeTo: question.natAnswerRangeTo,
    solution: renderLatexHTML(question.solutionRaw),
    correctAnswerScore: question.correctAnswerScore,
    wrongAnswerScore: question.wrongAnswerScore,
  })),
})

/**
 * Sort the questions based on its id
 * @param {object} questionData question object received from api
 */
export const sortQuestions = questionData => {
  const questionDetails = Object.assign({}, questionData)
  const { questionsJson } = questionDetails
  Object.keys(questionsJson).forEach(key => {
    const { questions } = questionsJson[key]
    questions.sort((q1, q2) => q1.id - q2.id)
    questionsJson[key].questions = questions
  })
  questionDetails.questionsJson = questionsJson
  return questionDetails
}
