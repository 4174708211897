import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../..'
import { getAccess } from '../../api/access'
import { getSessionStorageData, saveSessionStorageData } from '../../helper/sessionStorage'
import {
  AccessControls,
  AccessControlsResponse,
  PermissionLevels,
  PermissionLevelsMap,
  ViewPermissions,
} from '../../types/AccessControls'
import { showErrorNotification } from '../notifications/actions'

interface AccessControlState {
  data: AccessControls | undefined
  hasError: boolean
  isLoading: boolean
}

const initialState: AccessControlState = {
  data: undefined,
  isLoading: false,
  hasError: false,
}

export const accessControlsSlice = createSlice({
  name: 'accessControls',
  initialState,
  reducers: {
    requestAccess(state) {
      state.isLoading = true
      state.hasError = false
    },
    requestedAccessSucceeded(state) {
      return {
        ...state,
        isLoading: false,
        hasError: false,
      }
    },
    saveAccess(state, action: PayloadAction<AccessControls>) {
      const newAccessControls = { ...state.data, ...action.payload }
      return {
        ...state,
        data: newAccessControls,
      }
    },

    saveViewAccess(state, action: PayloadAction<ViewPermissions>) {
      const newAccessControls: AccessControls = { ...state.data, viewPermissions: action.payload }
      return {
        ...state,
        data: newAccessControls,
      }
    },

    savePageAccess(state, action: PayloadAction<PermissionLevelsMap>) {
      if (state.data) {
        const permissionLevels = state.data?.permissionLevels
        const newAccessControls: AccessControls = {
          ...state.data,
          permissionLevels: {
            ...permissionLevels,
            ...action.payload,
          },
        }
        return {
          ...state,
          data: newAccessControls,
        }
      }
      return state
    },
    requestedAccessFailed(state) {
      state.isLoading = false
      state.hasError = true
    },
    resetAccessControl() {
      return initialState
    },
  },
})

export const {
  requestAccess,
  requestedAccessSucceeded,
  requestedAccessFailed,
  savePageAccess,
  saveViewAccess,
  saveAccess,
  resetAccessControl,
} = accessControlsSlice.actions

export const selectAccessControl = (state: RootState) => state.accessControls

export const fetchAccessControls =
  (permissionLevel: PermissionLevels): AppThunk =>
  async (dispatch, getState) => {
    const store = getState()
    const accessControls = store.accessControls.data
    try {
      const response = await getAccess(permissionLevel)
      if (response) {
        const accessControlData: AccessControlsResponse = response.data
        dispatch(
          savePageAccess({
            [permissionLevel]: accessControlData.permissions,
          }),
        )
        dispatch(requestedAccessSucceeded())
        saveSessionStorageData(
          'access',
          JSON.stringify({
            viewPermissions: accessControls?.viewPermissions,
            permissionLevels: {
              ...accessControls?.permissionLevels,
              [permissionLevel]: accessControlData.permissions,
            },
          }),
        )
      }
    } catch (e) {
      dispatch(requestedAccessFailed())
      dispatch(showErrorNotification('Not able to fetch permissions. Please try again later'))
    }
  }

export const loadAccessControls =
  (permissionLevel: PermissionLevels): AppThunk =>
  async dispatch => {
    dispatch(requestAccess())
    const storedData = JSON.parse(getSessionStorageData('access') || '{}')
    try {
      if (storedData) {
        const isPermissionExist = !!storedData.permissionLevels?.[permissionLevel]
        if (isPermissionExist) {
          dispatch(saveAccess(storedData))
          dispatch(requestedAccessSucceeded())
        } else {
          dispatch(fetchAccessControls(permissionLevel))
        }
      } else {
        dispatch(fetchAccessControls(permissionLevel))
      }
    } catch (e) {
      dispatch(requestedAccessFailed())
    }
  }

export default accessControlsSlice.reducer
