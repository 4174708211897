import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { ModulesListFilter, Module, ModulesResponse } from '../types/Modules'

/**
 * Returns list of subjects for a given platform without pagination
 */
export function listModules(params: { platformId: number, subjectId: number, platformGroup: number }) {
  return httpService.get('/v4/content/sources/modules/', {
    params: {
      platform: params.platformId,
      subject: params.subjectId,
      group: params.platformGroup,
    },
  })
}

/**
 * Returns list of modules with subject for a given platform without pagination
 */
export function listModulesWithSubject(params: { platform: number, subjects: number, language: string }) {
  return httpService.get('/v4/content/sources/subject_modules/', {
    params: {
      platform: params.platform,
      subjects: params.subjects,
      language: params.language,
    },
  })
}

/**
 * Returns list of mapped modules under a subject
 * @param subjectId
 * @param filter
 * @returns
 */
export function getSubjectModules(
  subjectId: number,
  platformId: number,
  filter: ModulesListFilter
): Promise<AxiosResponse<ModulesResponse>> {
  return httpService.get(`/v7/content/sources/subjects/${subjectId}/platforms/${platformId}/modules/`, {
    params: filter,
  })
}

/**
 * Returns list of modules for given filter
 * @param filter
 */
export function getModules(
  filter: ModulesListFilter
): Promise<AxiosResponse<ModulesResponse>> {
  return httpService.get('/v7/content/sources/modules/', {
    params: filter,
  })
}

/**
 * Fetch Module details
 */
export function getModule(
  moduleId: number,
  platformId: number,
): Promise<AxiosResponse<Module>> {
  return httpService.get(`/v7/content/sources/modules/${moduleId}/`, {
    params: { platform: platformId }
  })
}

/**
 * Create Module
 * @param moduleDetails
 * @returns
 */
export function createModule(moduleDetails: FormData): Promise<AxiosResponse<Module>> {
  return httpService.post(`/v7/content/sources/modules/`, moduleDetails)
}

/**
 * Update Module
 * @param moduleId
 * @param moduleDetails
 * @returns
 */
export function updateModule(
  moduleId: number,
  moduleDetails: FormData,
): Promise<AxiosResponse<Module>> {
  return httpService.put(`/v7/content/sources/modules/${moduleId}/`, moduleDetails)
}

/**
 * Map Module to a subject
 * @param moduleId
 * @param subjectId
 * @param vertical
 * @returns
 */
export function mapModule(
  moduleId: number,
  platformId: number,
  vertical: 'SKILLING' | 'TESTPREP'
): Promise<AxiosResponse<Module>> {
  return httpService.post(`v7/content/sources/platforms/${platformId}/plot/modules/${moduleId}/`, {
    params: { vertical }
  })
}

/**
 * Remove Module mapping for a subject
 * @param moduleId
 * @param subjectId
 * @param vertical
 * @returns
 */
export function removeModule(
  moduleId: number,
  platformId: number,
  vertical: 'SKILLING' | 'TESTPREP'
): Promise<AxiosResponse<Module>> {
  return httpService.delete(`v7/content/sources/platforms/${platformId}/plot/modules/${moduleId}/`, {
    params: { vertical }
  })
}

/**
 * Activate a Module for a Subject Pack
 * @param moduleId
 * @param platformId
 * @returns
 */
export function activateModule(
  moduleId: number,
  platformId: number
): Promise<AxiosResponse<Module>> {
  return httpService.post(`/v7/content/sources/platforms/${platformId}/activate/modules/${moduleId}/`)
}

/**
 * Activate a Module for a Subject Pack
 * @param moduleId
 * @param platformId
 * @returns
 */
export function deactivateModule(
  moduleId: number,
  platformId: number
): Promise<AxiosResponse<Module>> {
  return httpService.delete(`/v7/content/sources/platforms/${platformId}/activate/modules/${moduleId}/`)
}
