import { notification } from "antd"

export const successNotification = (description: string, highLight?: string) => {
    notification.success({
        type: 'success',
        message: highLight || "Success",
        description: description,
    })
}

export const errorNotification = (description: string, highLight?: string) => {
    notification.error({
        type: 'error',
        message: highLight || "Error",
        description,
    })
}