import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import Loader from 'components/LayoutComponents/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import { UserRoles } from '../redux/user/types'

// Sentry Configuration
Sentry.init({
  dsn: 'https://6386d91c1ba49c3f9be937a17a509791@sentry-prod.entri.me/12',
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  environment: process.env.REACT_APP_CUSTOM_NODE_ENV,
  enabled: process.env.REACT_APP_CUSTOM_NODE_ENV === 'production',
  release: `${process.env.REACT_APP_VERSION} - ${process.env.REACT_APP_CUSTOM_NODE_ENV}`,
})

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

@withRouter
@connect(({ user, accessControls }) => ({
  user,
  isAccessControlsLoading: accessControls.isLoading,
}))
class IndexLayout extends React.PureComponent {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
      isAccessControlsLoading,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public'
      }
      if (/^\/questioncollections\/(\d+)\/print/.test(pathname)) {
        return 'public'
      }
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return 'login'
      }
      return 'main'
    }

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.isLoading
    const isLoginLayout = getLayout() === 'login'
    const userRole = user.role
    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
        return <Loader />
      }
      // redirect to login page if current is not login page and user not authorized
      if (!isLoginLayout && !isUserAuthorized) {
        return <Redirect to="/user/login/" />
      }
      // wait until view access is fetched
      if (isAccessControlsLoading) {
        return <Loader />
      }

      // redirect to main dashboard when user on login page and authorized
      if (isLoginLayout && isUserAuthorized) {
        switch (userRole) {
          case UserRoles.videoManager:
            return <Redirect to="/videos/packs/" />
          case UserRoles.videoEditor:
            return <Redirect to="/videos/convertedVideos/" />
          default:
            return <Redirect to="/jobs/" />
        }
      }
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="Entri | %s" title="Entri Content Platform" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
