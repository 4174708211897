const actions = {
  GET_LIST: 'questionCollection/GET_LIST',
  SET_LIST: 'questionCollection/SET_LIST',
  SAVE_FILTER: 'questionCollection/SAVE_FILTER',
  SET_FILTER: 'questionCollection/SET_FILTER',
  SET_ITEM: 'questionCollection/SET_ITEM',
  CREATE_ITEM: 'questionCollection/CREATE_ITEM',
  GET_ITEM: 'questionCollection/GET_ITEM',
  UPDATE_ITEM: 'questionCollection/UPDATE_ITEM',
}

export default actions

export const REQUESTED_COLLECTION = 'collection/REQUESTED'
export const REQUESTED_COLLECTION_FAILED = 'collection/FAILED'
export const REQUESTED_COLLECTION_SUCCEEDED = 'collection/SUCCEEDED'
export const RECEIVED_COLLECTION = 'collection/RECEIVED'
export const FETCH_COLLECTION = 'collection/FETCH'

export const AH_COLLECTION_APPROVE = 'collection/AH_COLLECTION_APPROVE'
export const AH_COLLECTION_REJECT = 'collection/AH_COLLECTION_REJECT'

export const saveQCFilter = filter => ({
  type: actions.SAVE_FILTER,
  payload: filter,
})

export const getCollections = filter => ({
  type: actions.GET_LIST,
  payload: filter,
})

/**
 * Notifies store that collection is being fetched
 */
export const requestCollection = () => ({ type: REQUESTED_COLLECTION })

/**
 * Notifies store that collection is fetch was a success
 */
export const requestCollectionSuccess = () => ({ type: REQUESTED_COLLECTION_SUCCEEDED })

/**
 * Notifies store that collection is fetch failed
 */
export const requestCollectionError = () => ({ type: REQUESTED_COLLECTION_FAILED })

/**
 * Sets received collection to store
 */
export const receivedCollection = data => ({ type: RECEIVED_COLLECTION, data })

/**
 * Initiates request to fetch collection
 */
export const getCollection = id => ({
  type: FETCH_COLLECTION,
  payload: {
    id,
  },
})

/**
 * /////////////////////////////////////////////////////
 * ************************ QA *******************
 * /////////////////////////////////////////////////////
 */

export const requestCollectionApproval = (jobId, qcId) => ({
  type: AH_COLLECTION_APPROVE,
  payload: {
    jobId,
    qcId,
    isApproved: true,
  },
})

export const requestCollectionReject = (jobId, qcId) => ({
  type: AH_COLLECTION_REJECT,
  payload: {
    jobId,
    qcId,
    isApproved: false,
  },
})
