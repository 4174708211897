import { VideoPlaylistsFilters, VideoFilters } from '../redux/lookups/types'
import httpService from '../httpService'
import { UserFilter } from '../redux/user/types'

export const getTeachers = () => {
  return httpService.get(`/v6/video-dashboard/teachers/`)
}

export const getEditors = () => {
  return httpService.get(`/v6/video-dashboard/hdvideos/editors/`)
}

export const getCameraOperators = () => {
  return httpService.get(`/v6/video-dashboard/hdvideos/camera_operators/`)
}

export function getCurrentUser() {
  return httpService.get('/v4/content/sources/users/current/')
}

export function getUsers(filter: UserFilter) {
  return httpService.get('/v4/content/sources/users/', {
    params: filter,
  })
}

export const getVideoPlaylists = (filters: VideoPlaylistsFilters) => {
  return httpService.get(`/v6/content/sources/playlists/`, {
    params: filters,
  })
}

export const getVideos = (filters: VideoFilters) => {
  return httpService.get(`/v6/content/sources/videos/`, {
    params: filters,
  })
}
