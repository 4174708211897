import { all, put, call, takeLatest } from 'redux-saga/effects'
import { listInactivePackages, listPurchases } from '../../api/purchase';
import { pushParamsToUrl } from '../../helper/urlParamSerializer';
import { showErrorNotification } from '../notifications/actions';
import {
    requestPurchases,
    receivedPurchses,
    errorOnPurchase,
    requestInactivePackages,
    receivedInactivePackages,
    errorOnInactivePackages
} from './slice';
import { GetInactivePackageParams, GetPurchasesParams, InactivePackages, PurchaseListRootResponse } from './types';


interface PurchaseListParm {
    payload: GetPurchasesParams,
    type: string
}

interface InactivePackageListParm {
    payload: GetInactivePackageParams,
    type: string
}

export function* LIST_PURCHASES({ payload }: PurchaseListParm) {
    try {
        if (payload) {
            pushParamsToUrl({
                productPackageName: payload.productPackageName,
                productPackageId: payload.productPackageId,
                page: payload.page,
                pageSize: payload.pageSize,
            });
        }
        const response: { data: PurchaseListRootResponse } = yield call(listPurchases, payload);
        yield put(receivedPurchses((response.data)))
    } catch (error) {
        yield put(showErrorNotification('Something went wrong while fetching Study Plans'))
        yield put(errorOnPurchase())

    }
}

export function* LIST_INACTIVE_PACKAGES({ payload }: InactivePackageListParm) {
    try {
        if (payload) {
            pushParamsToUrl({ inactivePage: payload.inactivePage, });
        }
        const response: { data: InactivePackages.Root } = yield call(listInactivePackages, payload);
        yield put(receivedInactivePackages((response.data)))
    } catch (error) {
        yield put(showErrorNotification('Something went wrong while fetching Study Plans'))
        yield put(errorOnInactivePackages())

    }
}


export default function* rootSaga() {
    yield all([
        takeLatest(requestPurchases.type, LIST_PURCHASES),
        takeLatest(requestInactivePackages.type, LIST_INACTIVE_PACKAGES)
    ])
}