import { AxiosResponse } from 'axios';
import httpService from '../httpService'
import { CertificateListFilter, CertificateListResponse, CertificateTemplate, GenerateCertificate, GeneratedCertificates } from 'pages/certificate/models';


export function createCertificateTemplates(
    certificateTemplateDetails: FormData
): Promise<AxiosResponse<CertificateTemplate>> {
    return httpService.post(`/v9/certification/templates/`, certificateTemplateDetails)
}


export function getTemplateList(): Promise<AxiosResponse<CertificateTemplate[]>> {
    return httpService.get('/v9/certification/templates/');
}

export function getTemplateDetails(templateId: number): Promise<AxiosResponse<CertificateTemplate>> {
    return httpService.get(`/v9/certification/templates/${templateId}/`);
}

// generate certifictaes

export function generateCertificate(
    certificateDetails: Object
): Promise<AxiosResponse<GenerateCertificate>> {
    return httpService.post(`/v9/certificates/`, certificateDetails)
}

export function generateBulkCertificates(
    certificateDetails: Object
): Promise<AxiosResponse<GenerateCertificate>> {
    return httpService.post(`/v9/certificates/create_bulk/`, certificateDetails)
}

export function getCertificateList(filter: CertificateListFilter): Promise<AxiosResponse<CertificateListResponse>> {
    return httpService.get('/v9/certificates/', {
        params: filter
    });
}

export function getCertificateDetails(userId: number) {
    return httpService.get(`/v9/certificates/${userId}/`)
}