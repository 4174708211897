import { all, takeEvery } from 'redux-saga/effects'
import { notification, message } from 'antd'
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
  NotificationActionImpl,
  NotificationImpl,
  MessageImpl,
  NotificationTypes,
} from './types'

const { success, error } = notification
const defaultMsgDuration = 3

export function DISPLAY_ERROR_NOTIFICATION({ payload }: NotificationActionImpl) {
  const { type, data } = payload
  if (type === NotificationTypes.notification) {
    const _data = data as NotificationImpl
    error({ message: _data.title || 'Error', description: _data.description })
  } else {
    const _data = data as MessageImpl
    message.error(_data.message, _data.duration || defaultMsgDuration)
  }
}

export function DISPLAY_SUCCESS_NOTIFICATION({ payload }: NotificationActionImpl) {
  const { type, data } = payload
  if (type === NotificationTypes.notification) {
    const _data = data as NotificationImpl
    success({ message: _data.title || 'Success', description: _data.description })
  } else {
    const _data = data as MessageImpl
    message.success(_data.message, _data.duration || defaultMsgDuration)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(NOTIFICATION_ERROR, DISPLAY_ERROR_NOTIFICATION),
    takeEvery(NOTIFICATION_SUCCESS, DISPLAY_SUCCESS_NOTIFICATION),
  ])
}
