import httpService from '../httpService'
import { FilterImpl } from '../redux/discuss/types'
import { number } from 'prop-types'
import { AxiosResponse } from 'axios'

export const getPosts = (filter: FilterImpl): Promise<AxiosResponse> => {
  return httpService.get(`/v4/content/discuss/posts/`, {
    params: filter,
  })
}
export const getPost = (postId: number) => {
  return httpService.get(`/v4/content/discuss/posts/${postId}`)
}

export const markPostReplyRequired = (postId: number, status: Boolean) => {
  return httpService.patch(`/v4/content/discuss/posts/${postId}/reply_required`, { status })
}

export const getComments = (postId: number, filter: FilterImpl) => {
  return httpService.get(`/v4/content/discuss/posts/${postId}/comments/`, {
    params: filter,
  })
}
export const getCommentReplies = (postId: number, commentId: number, filter: FilterImpl) => {
  return httpService.get(`/v4/content/discuss/posts/${postId}/comments/${commentId}/replies/`, {
    params: filter,
  })
}

export const postComment = (postId: number, commentDetails: FormData) => {
  return httpService.post(`/v4/content/discuss/posts/${postId}/comments/`, commentDetails)
}

export const postReply = (postId: number, commentId: number, replyDetails: FormData) => {
  return httpService.post(
    `/v4/content/discuss/posts/${postId}/comments/${commentId}/replies/`,
    replyDetails,
  )
}

export const patchComment = (postId: number, commentId: number, commentDetails: FormData) => {
  return httpService.put(
    `/v4/content/discuss/posts/${postId}/comments/${commentId}/`,
    commentDetails,
  )
}

export const markCommentReplyNotRequired = (commentId: number, status: Boolean) =>
  httpService.patch(`/v4/content/discuss/posts/${commentId}/reply_required/`, { status })

export const patchReply = (
  postId: number,
  commentId: number,
  replyId: number,
  replyDetails: FormData,
) => {
  return httpService.put(
    `/v4/content/discuss/posts/${postId}/comments/${commentId}/replies/${replyId}/`,
    replyDetails,
  )
}

export const deleteDiscuss = (commentId: number) => {
  return httpService.patch(`/v4/content/discuss/posts/${commentId}/delete`)
}
