import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../..'
import { getReportedQuestions } from '../../api/reportedQuestions'
import { ReportedQuestions } from '../../types/ReportedQuestions'

export const initialReportedQuestions: ReportedQuestions.ReportedQuestionState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
    reportType: 'unresolved',
  },
  isLoading: false,
  hasError: false,
}

const reportedQuestionsSlice = createSlice({
  name: 'reportedQuestionsSlice',
  initialState: initialReportedQuestions,
  reducers: {
    requestReportedQuestion(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedReportedQuestionFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedReportedQuestions(
      state,
      action: PayloadAction<ReportedQuestions.ReportedQuestionPayload>,
    ) {
      const { data, filter, meta } = action.payload
      const newFilter: ReportedQuestions.Filter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
    updateReportedQuestions(state, action: PayloadAction<ReportedQuestions.ReportedQuestion[]>) {
      const data = action.payload
      return {
        ...state,
        data,
      }
    },
  },
})

export const {
  receivedReportedQuestions,
  requestReportedQuestion,
  requestedReportedQuestionFailed,
  updateReportedQuestions,
} = reportedQuestionsSlice.actions

export const fetchReportedQuestions =
  (filter: ReportedQuestions.Filter): AppThunk =>
  async dispatch => {
    dispatch(requestReportedQuestion())
    try {
      const response = await getReportedQuestions(filter)
      if (response) {
        const { results: data, ...meta } = response.data
        dispatch(
          receivedReportedQuestions({
            data,
            filter,
            meta,
          }),
        )
      }
    } catch (error) {
      dispatch(requestReportedQuestion())
    }
  }

export const selectReportedQuestions = (state: RootState) => state.reportedQuestions

export default reportedQuestionsSlice.reducer
