const actions = {
  GET_LIST: 'topics/GET_LIST',
  SET_LIST: 'topics/SET_LIST',
}

export default actions

export const getTopics = ({ moduleId, platformId }) => ({
  type: actions.GET_LIST,
  payload: {
    moduleId,
    platformId,
  },
})
