import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { combineReducers } from 'redux'
import { AppThunk, RootState } from "../.."
import { getQuestions } from "../../api/questions"
import { Filter } from "../../types/Platforms"
import { QuestionsListPayload, QuestionsMappingState } from "../../types/Mapping"

export const initialQuestionsList: QuestionsMappingState = {
    data: [],
    meta: {
        page: 1,
        pageCount: 1,
        limit: 20,
        totalObjectCount: 0,
    },
    filter: {
        pageSize: 20,
        page: 1,
    },
    isLoading: false,
    hasError: false,
}

const QuestionsMapping = createSlice({
    name: 'questionsMapping',
    initialState: initialQuestionsList,
    reducers: {
        requestQuestions(state) {
            return { ...state, isLoading: true, hasError: false }
        },
        requestedQuestionsFailed(state) {
            return { ...state, isLoading: false, hasError: true }
        },
        receivedQuestions(state, action: PayloadAction<QuestionsListPayload>) {
            const { data, filter, meta } = action.payload
            const newFilter = {
                ...state.filter,
                ...filter,
            }
            return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
        }
    }
})

export const { receivedQuestions, requestQuestions, requestedQuestionsFailed } = QuestionsMapping.actions;

export const fetchQuestions =
 (filter: Filter = {}): AppThunk =>  
 async dispatch => {
        dispatch(requestQuestions());
        try {
            const response = await getQuestions(filter);
            if (response) {
                const { results: data, ...meta } = response.data;
                dispatch(receivedQuestions({ data, filter, meta }));
            }
        } catch (error) {
            dispatch(requestedQuestionsFailed());
        }
    }

export const selectQuestions = (state: RootState) => state.questionsMapping.list;

export default combineReducers({
    list: QuestionsMapping.reducer,
})