import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../..'
import {
  InitialState,
  BatchProgress,
  StudentList,
  StudentIndividualReport,
  FluencyEvaluationScheduledTest,
} from './types'
import { cloneDeep } from 'lodash'

const initialState: InitialState = {
  progress: {
    loading: false,
    data: null,
    error: null,
  },
  students: {
    loading: false,
    data: null,
    error: undefined,
  },
  reports: {
    loading: false,
    error: null,
    data: null,
  },
}

const batchReport = createSlice({
  name: 'batchReport',
  initialState: initialState,
  reducers: {
    requestBatchProgress: (state, _) => {
      state.progress = {
        loading: true,
        data: null,
        error: null,
      }
    },
    receiveBatchProgress: (state, action: PayloadAction<BatchProgress.RootObject>) => {
      state.progress = {
        loading: false,
        data: action.payload,
        error: null,
      }
    },
    errorBatchProgress: (state, action) => {
      state.progress = {
        loading: false,
        data: null,
        error: action.payload,
      }
    },
    requestStudentsList: (state, _) => {
      state.students = {
        loading: true,
        data: null,
        error: undefined,
      }
    },
    receiveStudentsList: (state, action: PayloadAction<StudentList.RootObject>) => {
      state.students = {
        loading: false,
        data: action.payload,
        error: undefined,
      }
    },
    errorStudentsList: (state, action) => {
      state.students = {
        loading: false,
        data: null,
        error: action.payload,
      }
    },
    requestStudentReport: (state, _) => {
      state.reports = {
        loading: true,
        data: null,
        error: null,
      }
    },
    receviveStudentReport: (state, action: PayloadAction<StudentIndividualReport.RootObject>) => {
      state.reports = {
        loading: false,
        data: action.payload,
        error: null,
      }
    },
    errorStudentReport: (state, action) => {
      state.reports = {
        loading: false,
        data: null,
        error: null,
      }
    },
  },
})

// Updating scheduled fluency quiz details locally
export const updateScheduledFluencyQuiz =
  (data: FluencyEvaluationScheduledTest): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    let batchDetails = cloneDeep(state.batchReport.progress.data)
    if (!batchDetails) return

    if (batchDetails.scheduledEvaluationQuizzes.length) {
      const index = batchDetails.scheduledEvaluationQuizzes.findIndex(
        quiz => quiz.quizId === data.quizId,
      )
      if (index > -1) {
        batchDetails.scheduledEvaluationQuizzes[index] = data
      } else batchDetails.scheduledEvaluationQuizzes.push(data)
    } else batchDetails.scheduledEvaluationQuizzes.push(data)

    dispatch(receiveBatchProgress(batchDetails))
  }

export const progressSelector = (state: RootState) => state.batchReport.progress
export const studentListSelector = (state: RootState) => state.batchReport.students
export const studentReportSelector = (state: RootState) => state.batchReport.reports
export const studentNameSelector = (state: RootState) => ({
  studentName: state.batchReport.reports.data?.studentDetails.name,
  studentPhone: state.batchReport.reports.data?.studentDetails.phone,
})
export const studentProgressSelector = (state: RootState) => ({
  loading: state.batchReport.reports.loading,
  data: state.batchReport.reports.data?.studentDetails.progressDetails,
  dailyLessons: state.batchReport.reports.data?.dailyLessons,
})
export const {
  requestBatchProgress,
  receiveBatchProgress,
  errorBatchProgress,
  requestStudentsList,
  receiveStudentsList,
  errorStudentsList,
  requestStudentReport,
  receviveStudentReport,
  errorStudentReport,
} = batchReport.actions

export default batchReport.reducer
