import { useEffect } from 'react'
import { connect } from 'react-redux'
import { UserRoles } from '../../redux/user/types'

const RedirectRootPath = ({ user, history }) => {
  const userRole = user.role

  useEffect(() => {
    switch (userRole) {
      case UserRoles.videoManager:
        history.push('/videos/packs/')
        break
      case UserRoles.videoEditor:
        history.push('/videos/convertedVideos/')
        break
      default:
        history.push('/jobs/')
    }
  }, [userRole])

  return null
}

export default connect(({ user }) => ({ user }))(RedirectRootPath)
