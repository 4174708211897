import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { Filter, LiveClass, LiveClassDetails } from '../redux/liveclasses/types'
import { LiveServiceProvider } from '../pages/live/listing/utils'

export function getAllStudyPlanBatches(studyPlanId: number) {
  return httpService.get(`v7/gold/studyplans/${studyPlanId}/batches/`)
}

export function getLiveClasses(courseVertical?: string, packCategory?: string, filter?: Filter) {
  return httpService.get(`v7/live/`, {
    params: {
      ...filter,
      courseVertical: packCategory ? undefined : courseVertical,
      packCategory,
    },
  })
}

export function getLiveClassDetails(liveClassId: number, isElevate: boolean = false) {
  return httpService.get(`v7/live/${liveClassId}/`, {
    params: { packCategory: isElevate ? 'ELEVATE' : undefined },
  })
}

export function getLicensedUsers() {
  return httpService.get(`v7/gold/zoom-users/`)
}

export function getLiveTemplates() {
  return httpService.get(`v9/100ms/templates/`)
}

type CreateLiveClass = LiveClass & Pick<LiveClassDetails, 'serviceProvider'>

export function createLiveClass(payload: CreateLiveClass) {
  return httpService.post(`v7/live/`, { ...payload })
}

export function editLiveClass(liveClassId: number, payload: LiveClass) {
  return httpService.put(`v7/live/${liveClassId}/`, { ...payload })
}

export function cancelLiveClass(liveId: number) {
  return httpService.delete(`v7/live/${liveId}/`)
}

export function endLiveClass(liveId: number) {
  return httpService.post(`v7/live/${liveId}/end/`)
}
export function getElevatePacks(optionalParams?: { languages?: string, isOldLiveClassCreation?: boolean }) {
  return httpService.get('v7/content/sources/packs/', {
    params: { packCategory: 'ELEVATE', ...optionalParams },
  })
}

export function getElevateBatches(packId: number) {
  return httpService.get(`v7/packs/${packId}/batches/`)
}

export function getFaculties() {
  return httpService.get('v7/content/sources/faculties/')
}

export function getLanguages() {
  return httpService.get('v4/content/sources/languages/')
}

export function getSections(packId: number | string) {
  return httpService.get('v7/content/sources/studyplans/sections/', {
    params: { packId, category: 'NORMAL', isActive: true },
  })
}

export function getLessons(sectionId: number | string) {
  return httpService.get('v7/content/sources/elevate/lessons/', {
    params: { sectionId, isActive: true },
  })
}

export function reconvertVideo(liveId: number): Promise<AxiosResponse> {
  return httpService.post(`/v7/live/${liveId}/reconvert/`)
}


export function downloadRecrodedVideo(videoRecordingId: number): Promise<AxiosResponse> {
  return httpService.get(`/v7/live/recorded-video/${videoRecordingId}/`)
}
