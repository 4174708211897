import { FilterImpl, DiscussPostPatchImpl, DiscussTypes } from './types'

export const FETCH_DISCUSS_POSTS = 'discusses/FETCH'
export const FETCH_DISCUSS_POST = 'discuss/FETCH'
export const UPDATE_DISCUSS_POSTS = 'discusses/UPDATE'
export const FETCH_COMMENTS = 'discuss-comments/FETCH'
export const FETCH_REPLIES = 'discuss-replies/FETCH'
export const CREATE_COMMENT = 'discuss/CREATE_COMMENT'
export const CREATE_REPLY = 'discuss/CREATE_REPLY'
export const UPDATE_COMMENT = 'discuss/UPDATE_COMMENT'
export const UPDATE_REPLY = 'discuss/UPDATE_REPLY'
export const MARK_COMMENT_REPLY_NOT_REQUIRED = 'discuss/COMMENT_REPLY_NOT_REQUIRED'
export const REMOVE_DISCUSS = 'discuss/DELETE_COMMENT'

export const getDiscusses = (filter: FilterImpl) => {
  return {
    type: FETCH_DISCUSS_POSTS,
    payload: filter,
  }
}

export const getDiscussPost = (postId: Number) => {
  return {
    type: FETCH_DISCUSS_POST,
    payload: postId,
  }
}

export const updateDiscussPosts = (postId: number, postDetails: DiscussPostPatchImpl) => ({
  type: UPDATE_DISCUSS_POSTS,
  payload: { postId, postDetails },
})

export const getPostComments = (postId: Number, filter: FilterImpl) => {
  return {
    type: FETCH_COMMENTS,
    payload: {
      postId,
      filter,
    },
  }
}

export const getCommentReplies = (postId: number, commentId: number, filter: FilterImpl) => {
  return {
    type: FETCH_REPLIES,
    payload: {
      postId,
      commentId,
      filter,
    },
  }
}

export const postComment = (postId: number, commentDetails: FormData) => ({
  type: CREATE_COMMENT,
  payload: { commentDetails, postId },
})

export const postReply = (postId: number, commentId: Number, replyDetails: FormData) => ({
  type: CREATE_REPLY,
  payload: { postId, commentId, replyDetails },
})

export const patchComment = (postId: number, commentId: number, commentDetails: FormData) => ({
  type: UPDATE_COMMENT,
  payload: {
    postId,
    commentId,
    commentDetails,
  },
})

export const patchReply = (
  postId: number,
  commentId: number,
  replyId: number,
  replyDetails: FormData,
) => ({
  type: UPDATE_REPLY,
  payload: {
    postId,
    replyId,
    commentId,
    replyDetails,
  },
})

export const markCommentReplyNotRequired = (commentId: number, replyRequired: Boolean) => ({
  type: MARK_COMMENT_REPLY_NOT_REQUIRED,
  payload: {
    commentId,
    replyRequired,
  },
})

export const deleteDiscuss = (type: DiscussTypes, id: number, commentId: number) => ({
  type: REMOVE_DISCUSS,
  payload: {
    id,
    type,
    commentId,
  },
})
