import httpService from '../httpService'
import { Filter } from "../types/Platforms"

/**
 * Get Categories
 * @param filter 
 */

export const getCategories = (filter: Filter) => {
  return httpService.get('/v8/business-category/', {
    params: {
      ...filter,
    }
  })
}

/**
 * Create Category
 * @param formData 
 */
export const createCategory = (formData: FormData) => {
  return httpService.post(
      `v8/business-category/`,
      formData,
  )
}

/**
 * Set Global Component
 * @param data 
 */

export const setGlobalComponent = (data: any) => {
  return httpService.post(
      `v8/global-component/`,
      data
  )
}

/**
 * Get Global Component
 * @param id 
 * @param name 
 */

export const getGlobalComponent = (id: number, name: string): any => {
  return httpService.get(`/v8/global-component/?businessCategoryId=${id}&componentType=${name}`)
}

/**
 * Update Global Component
 * @param id 
 * @param data 
 */

export const updateGlobalComponent = (id: number, data: any) => {
  return httpService.patch(
      `/v8/global-component/${id}/`, 
      data
  )
}
