import {
  FETCH_USERS,
  RECEIVED_USERS,
  REQUESTED_USERS,
  REQUESTED_USERS_FAILED,
  REQUESTED_USERS_SUCCEEDED,
  FETCH_USER,
  RECEIVED_USER,
  REQUESTED_USER,
  REQUESTED_USER_FAILED,
  REQUESTED_USER_SUCCEEDED,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_USER,
  UsersImpl,
  UserImpl,
  LoginImpl,
  UserFilter,
} from './types'

/**
 * Notifies store that users are being fetched
 */
export const requestUsers = () => ({ type: REQUESTED_USERS })

/**
 * Notifies store that users fetch was a success
 */
export const requestUsersSuccess = () => ({ type: REQUESTED_USERS_SUCCEEDED })

/**
 * Notifies store that users fetch failed
 */
export const requestUsersError = () => ({ type: REQUESTED_USERS_FAILED })

/**
 * Sets received users to store
 */
export const receivedUsers = (data: UsersImpl) => ({
  type: RECEIVED_USERS,
  payload: data,
})

/**
 * Fetch
 */
export const getUsers = (filter: UserFilter = {}) => ({
  type: FETCH_USERS,
  payload: { filter },
})

/**
 * Notifies store that users are being fetched
 */
export const requestUser = () => ({ type: REQUESTED_USER })

/**
 * Notifies store that users fetch was a success
 */
export const requestUserSuccess = () => ({ type: REQUESTED_USER_SUCCEEDED })

/**
 * Notifies store that users fetch failed
 */
export const requestUserError = () => ({ type: REQUESTED_USER_FAILED })

/**
 * Sets received users to store
 */
export const receivedUser = (data: UserImpl) => ({
  type: RECEIVED_USER,
  payload: data,
})

/**
 * Loads current user details
 */
export const getUser = () => ({ type: FETCH_USER })

export const resetUser = () => ({ type: RESET_USER })

export const loginUser = (userLoginDetails: LoginImpl) => ({
  type: LOGIN_USER,
  payload: userLoginDetails,
})

export const logoutUser = (shouldCallAPI: Boolean) => ({
  type: LOGOUT_USER,
  payload: {
    shouldCallAPI,
  },
})
