import { Filter, VideoPack, PlayList } from './types'

export const FETCH_PACKS = 'video/FETCH_PACKS'
export const FETCH_HD_VIDEOS = 'video/FETCH_HD_VIDEOS'
export const FETCH_TRACKS = 'video/FETCH_TRACKS'
export const FETCH_PLAYLISTS = 'video/FETCH_PLAYLISTS'
export const FETCH_VIDEOS = 'video/FETCH_VIDEOS'
export const SET_SELECTED_PACK = 'video/SET_SELECTED_PACK'
export const SET_SELECTED_PLAYLIST = 'video/SET_SELECTED_PLAYLIST'
export const REVOKE_REMOVE_VIDEO_DATA = 'video/REVOKE_REMOVE_VIDEO_DATA'
export const SET_DEFAULT_PLAYLISTS_FILTERS = 'video/SET_DEFAULT_PLAYLISTS_FILTERS'
export const SET_DEFAULT_VIDEOS_FILTERS = 'video/SET_DEFAULT_VIDEOS_FILTERS'
export const FETCH_SORTED_VIDEO_PACKS = 'video/FETCH_SORTED_VIDEO_PACKS'
export const FETCH_QA_VIDEOS = 'video/FETCH_QA_VIDEOS'

// These actions are declared for sagas
export const getVideoPacks = (filter: Filter) => {
  return {
    type: FETCH_PACKS,
    payload: filter,
  }
}

export const getSortedVideoPacks = (filter: Filter) => {
  return {
    type: FETCH_SORTED_VIDEO_PACKS,
    payload: filter,
  }
}

export const setSelectedPack = (pack: VideoPack) => {
  return {
    type: SET_SELECTED_PACK,
    payload: pack,
  }
}

export const getPlaylists = (packId: number, filter: Filter) => {
  return {
    type: FETCH_PLAYLISTS,
    payload: { packId, filter },
  }
}

export const setSelectedPlaylist = (playlist: PlayList) => {
  return {
    type: SET_SELECTED_PLAYLIST,
    payload: playlist,
  }
}

export const getVideos = (playlistId: number, filter: Filter) => {
  return {
    type: FETCH_VIDEOS,
    payload: { playlistId, filter },
  }
}

export const getHDVideos = (filter: Filter) => {
  return {
    type: FETCH_HD_VIDEOS,
    payload: filter,
  }
}

export const getTracks = () => {
  return {
    type: FETCH_TRACKS,
  }
}

export const revokeRemoveVideoData = () => {
  return {
    type: REVOKE_REMOVE_VIDEO_DATA,
  }
}

export const setDefaultPlaylistsFilters = () => {
  return {
    type: SET_DEFAULT_PLAYLISTS_FILTERS,
    name: 'playlists',
  }
}

export const setDefaultVideosFilters = () => {
  return {
    type: SET_DEFAULT_VIDEOS_FILTERS,
    name: 'videos',
  }
}

// QA videos
export const getQAVideos = (filter: Filter) => {
  return {
    type: FETCH_QA_VIDEOS,
    payload: filter,
  }
}
