import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { history, store as reduxStore } from './index'
import { logoutUser } from './redux/user/actions'
import { showErrorNotification } from './redux/notifications/actions'
import { getCookie } from './api/cookie'
import store from 'store'

const { REACT_APP_API_ENDPOINT, REACT_APP_CUSTOM_NODE_ENV } = process.env

if (!REACT_APP_API_ENDPOINT) {
  const errorMessage = `Missing REACT_APP_API_ENDPOINT in .env.${REACT_APP_CUSTOM_NODE_ENV} file \n Add and restart server`
  alert(errorMessage)
  throw errorMessage
}

export const baseURL = `${
  REACT_APP_CUSTOM_NODE_ENV === 'staging'
    ? store.get('channel') || REACT_APP_API_ENDPOINT
    : REACT_APP_API_ENDPOINT
}`

axios.defaults.withCredentials = true

const instance = axios.create({
  baseURL: baseURL + '/api',
  withCredentials: true,
})

const onRequestSuccess = (config: AxiosRequestConfig) => {
  const token = getCookie('csrftoken')
  if (token) {
    config.headers['X-CSRFToken'] = token
  }
  return config
}

const onRequestFail = (error: AxiosError) => {
  return Promise.reject(error)
}

instance.interceptors.request.use(onRequestSuccess, onRequestFail)

const onResponseSuccess = (response: AxiosResponse) => {
  return response
}

const onResponseFail = (error: AxiosError) => {
  let shouldLogOut = false
  if (error && !error.response) {
    reduxStore.dispatch(showErrorNotification('Cant connect to the server. Please try again later'))
  } else {
    const { status, data } = error.response || {}
    const { detail, message } = data || {}
    if (status === 401) {
      shouldLogOut = true
    }
    if (status === 403) {
      const isUserAuthorized = reduxStore.getState()?.user.authorized
      if (isUserAuthorized) history.replace('/403')
    }
    if (detail || message) {
      reduxStore.dispatch(showErrorNotification(detail || message))
    }
  }
  if (shouldLogOut) {
    reduxStore.dispatch(logoutUser(false))
  }
  return Promise.reject(error)
}
instance.interceptors.response.use(onResponseSuccess, onResponseFail)

export default instance
