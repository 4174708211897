import { BatchProgress, FetchPayload, ProgressPayload, StudentIndividualReport, StudentIndividualReportPayload, StudentList, StudentListPayload } from "./types";
import {
    requestBatchProgress,
    receiveBatchProgress,
    errorBatchProgress,
    requestStudentsList,
    receiveStudentsList,
    errorStudentsList,
    requestStudentReport,
    receviveStudentReport,
    errorStudentReport
} from './slice';
import { all, call, put, takeLatest } from "redux-saga/effects";
import { showErrorNotification } from "../notifications/actions";
import { studentReport, studentsListing, studentsProgress } from "../../api/batchReports";
import { AxiosError } from "axios";

export function* GET_BATCH_PROGRESS({ payload: { packId, batchId } }: FetchPayload<ProgressPayload>) {
    try {
        const response: { data: BatchProgress.RootObject } = yield call(studentsProgress, packId, batchId)
        yield put(receiveBatchProgress(response.data))
    } catch (error) {
        yield put(showErrorNotification('Something went wrong while fetching Batch Report'))
        yield put(errorBatchProgress((error as Error).message))
    }
}


export function* GET_BATCH_STUDENTS({ payload: { packId, batchId, params } }: FetchPayload<StudentListPayload>) {
    try {
        const response: { data: StudentList.RootObject } = yield call(studentsListing, packId, batchId, params)
        yield put(receiveStudentsList(response.data))
    } catch (error) {
        yield put(errorStudentsList({
            status: (error as AxiosError).response?.status,
            message: (error as AxiosError).response?.data?.error
        }))
    }
}

export function* GET_STUDENTS_REPORT({ payload: { packId, batchId, studentId, params } }: FetchPayload<StudentIndividualReportPayload>) {
    try {
        const response: { data: StudentIndividualReport.RootObject } = yield call(studentReport, packId, batchId, studentId, params)
        yield put(receviveStudentReport(response.data))
    } catch (error) {
        yield put(showErrorNotification('Something went wrong while fetching Student Report'))
        yield put(errorStudentReport((error as Error).message))
    }
}


export default function* rootSaga() {
    yield all([
        takeLatest(requestBatchProgress.type, GET_BATCH_PROGRESS),
        takeLatest(requestStudentsList.type, GET_BATCH_STUDENTS),
        takeLatest(requestStudentReport.type, GET_STUDENTS_REPORT)
    ])
}