import {
  CommentsImpl,
  CommentImpl,
  REQUESTED_COMMENTS,
  REQUESTED_COMMENTS_SUCCEEDED,
  REQUESTED_COMMENTS_FAILED,
  RECEIVED_COMMENTS,
  FETCH_COMMENTS,
  CREATE_COMMENT,
  ADD_NEW_COMMENT,
} from './types'

/**
 * Notifies store that comments is being fetched
 */
export const requestComments = () => ({ type: REQUESTED_COMMENTS })

/**
 * Notifies store that comments fetch was a success
 */
export const requestCommentsSuccess = () => ({ type: REQUESTED_COMMENTS_SUCCEEDED })

/**
 * Notifies store that comments fetch failed
 */
export const requestCommentsError = () => ({ type: REQUESTED_COMMENTS_FAILED })

/**
 * Sets received comments to store
 */
export const receivedComments = (data: CommentsImpl) => ({
  type: RECEIVED_COMMENTS,
  payload: data,
})

/**
 * Initiates request to fetch comments
 */
export const getComments = (jobId: Number, filter: { page: Number }) => {
  return {
    type: FETCH_COMMENTS,
    payload: {
      jobId,
      filter,
    },
  }
}

/**
 * Initiates request to create comment
 */
export const createComment = (newComment: { task: Number; comment: string; user: Number }) => {
  return {
    type: CREATE_COMMENT,
    payload: {
      ...newComment,
    },
  }
}

/**
 * Initiates add new comment
 */
export const addComment = (comment: CommentImpl) => {
  return {
    type: ADD_NEW_COMMENT,
    payload: comment,
  }
}
