import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { Topic, TopicsListFilter, TopicsResponse } from '../types/Topics'

/**
 * Returns list of topics for a given platform without pagination
 */
export default function listTopics(params: any) {
  return httpService.get('/v4/content/sources/topics/', {
    params: {
      platform: params?.platformId,
      module: params?.moduleId,
    },
  })
}

/**
 * Returns list of mapped modules under a subject
 * @param subjectId
 * @param filter
 * @returns
 */
export function getModuleTopics(
  moduleId: number,
  platformId: number,
  filter: TopicsListFilter
): Promise<AxiosResponse<TopicsResponse>> {
  return httpService.get(`/v7/content/sources/modules/${moduleId}/platforms/${platformId}/topics/`, {
    params: filter,
  })
}

/**
 * Returns list of topics for given filter
 */
export function getTopics(
  filter: TopicsListFilter
): Promise<AxiosResponse<TopicsResponse>> {
  return httpService.get('/v7/content/sources/topics/', {
    params: filter,
  })
}

/**
 * Fetch Topic details
 */
export function getTopic(topicId: number): Promise<AxiosResponse<Topic>> {
  return httpService.get(`/v7/content/sources/topics/${topicId}`)
}

/**
 * Create Topic
 * @param topicDetails
 * @returns
 */
export function createTopic(topicDetails: FormData): Promise<AxiosResponse<Topic>> {
  return httpService.post(`/v7/content/sources/topics/`, topicDetails)
}

/**
 * Update Topic
 * @param topicId
 * @param topicDetails
 * @returns
 */
export function updateTopic(
  topicId: number,
  topicDetails: FormData,
): Promise<AxiosResponse<Topic>> {
  return httpService.put(`/v7/content/sources/topics/${topicId}/`, topicDetails)
}

/**
 * Map Topic to a subject
 * @param topicId
 * @param moduleId
 * @param vertical
 * @returns
 */
export function mapTopic(
  topicId: number,
  platformId: number,
): Promise<AxiosResponse<Topic>> {
  return httpService.post(`v7/content/sources/platforms/${platformId}/plot/topics/${topicId}/`)
}

/**
 * Remove Topic mapping from a Subject
 * @param topicId
 * @param moduleId
 * @param vertical
 * @returns
 */
export function removeTopic(
  topicId: number,
  platformId: number,
): Promise<AxiosResponse<Topic>> {
  return httpService.delete(`v7/content/sources/platforms/${platformId}/plot/topics/${topicId}/`)
}