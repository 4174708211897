import { message } from 'antd'
import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import { showErrorNotification } from '../../../redux/notifications/actions'
import {
  getCommonMistakes,
  acceptAppeal,
  rejectAppeal,
  appealQuestion,
  rejectQuestion,
} from '../../../api/questions'
import {
  getQuestion,
  APPEAL_QA_QUESTION,
  FETCH_COMMON_MISTAKES,
  receivedCommonMistakes,
  requestCommonMistakes,
  requestCommonMistakesSuccess,
  requestCommonMistakesError,
  AH_APPEAL_ACCEPT,
  AH_APPEAL_REJECT,
  AH_REJECT_QUESTION,
  getQuestionsAdmin,
} from '../actions'
import { showSuccessMessage, showErrorMessage } from '../../notifications/actions'
import { getCollection } from '../../questionCollection/actions'

/**
 * Invoked to get common mistakes
 */
// eslint-disable-next-line consistent-return
export function* watchGetCommonMistakes() {
  try {
    // is common mistakes data is already present in the store
    const isCommonMistakesLoaded = yield select((state) => !!state.questions?.commonMistakes?.data)
    // is a fetch request currently running
    const isCommonMistakesLoading = yield select(
      (state) => state.questions?.commonMistakes?.isLoading,
    )
    if (isCommonMistakesLoaded || isCommonMistakesLoading) return undefined

    yield put(requestCommonMistakes())
    const response = yield call(getCommonMistakes)
    yield put(receivedCommonMistakes(response.data))
    yield put(requestCommonMistakesSuccess())
  } catch (error) {
    yield put(requestCommonMistakesError())
    yield put(showErrorNotification('Something went wrong while fetching common mistakes'))
  }
}

/**
 * Invoked when QA submit review : approve or reject
 */
export function* watchAppeal({ payload }) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    yield call(appealQuestion, payload.questionGroupId, payload.languageCode)
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
    yield put(getQuestion(payload.questionGroupId))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

export function* watchAppealStatusChange({ type, payload }) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    if (type === AH_APPEAL_ACCEPT) {
      yield call(acceptAppeal, payload.questionGroupId, payload.languageCode)
    } else if (type === AH_APPEAL_REJECT) {
      yield call(rejectAppeal, payload.questionGroupId, payload.rejectionPayload)
    }
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
    yield put(getQuestion(payload.questionGroupId))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

export function* watchQuestionRejectInCollection({ payload }) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { jobId, qcId, questionGroupId, rejectionPayload } = payload
    yield call(rejectQuestion, jobId, qcId, questionGroupId, rejectionPayload)
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
    yield put(getQuestionsAdmin(qcId))
    yield put(getCollection(qcId))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(APPEAL_QA_QUESTION, watchAppeal),
    takeLatest(AH_APPEAL_ACCEPT, watchAppealStatusChange),
    takeLatest(AH_APPEAL_REJECT, watchAppealStatusChange),
    takeLatest(AH_REJECT_QUESTION, watchQuestionRejectInCollection),
    takeLatest(FETCH_COMMON_MISTAKES, watchGetCommonMistakes),
  ])
}
