import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BatchListPayload, Filter, StudentListState, StudentResponse } from "./types";
import { AppThunk, RootState } from "../..";
import { getStudents } from "../../api/batches";
import { errorNotification } from "../../pages/elevate/courses/NotificationHelper";

export const initialState: StudentListState = {
  data: [],
  filter: {
    page: 1,
    pageSize: 1,
  },
  hasError: false,
  isLoading: false,
  meta: {
    limit: 20,
    page: 1,
    pageCount: 1,
    totalObjectCount: 1,
  },
};

const StudentsListSlice = createSlice({
  name: "studentsList",
  initialState,
  reducers: {
    requestStudentsList: state => {
      return { ...state, isLoading: true, hasError: false };
    },
    requestedStudentsListFailed(state) {
      return { ...state, isLoading: false, hasError: true };
    },
    recievedStudentsList(state, action: PayloadAction<BatchListPayload>) {
      const { data, filter, meta } = action.payload;
      return { ...state, data, meta, filter, isLoading: false, hasError: false };
    },
  },
});

export const { recievedStudentsList, requestStudentsList, requestedStudentsListFailed } =
  StudentsListSlice.actions;

function instanceOfBatchListResponse(object: any): object is StudentResponse {
  return "results" in object;
}

export const fetchStudentsList =
  (filter: Filter, batchId: string): AppThunk =>
  async dispatch => {
    dispatch(requestStudentsList());
    try {
      const response = await getStudents(batchId, filter);
      if (response) {
        if (instanceOfBatchListResponse(response.data)) {
          const { results: data, ...meta } = response.data;
          dispatch(recievedStudentsList({ data, filter, meta }));
        } else {
          const data = response.data;
          dispatch(
            recievedStudentsList({
              data,
              filter,
            }),
          );
        }
      }
    } catch (error) {
      errorNotification("Failed to fetch students list. Please try again");
      dispatch(requestedStudentsListFailed());
    }
  };

export const selectStudentsList = (state: RootState) => state.studentsList.list;

export default combineReducers({
  list: StudentsListSlice.reducer,
});
