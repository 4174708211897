import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../.."
import { ElevateBatchResult, EleveteBatchesState } from "./types"

const initialState: EleveteBatchesState = {
    loading: false,
    data: null,
    error: false
}
const elevateBatches = createSlice({
    name: 'elevateBatches',
    initialState: initialState,
    reducers: {
        requestElevateBatches: (state) => {
            return { ...state, loading: true }
        },
        receivedBatches: (state, action: PayloadAction<ElevateBatchResult>) => {
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            }
        },
        requestBatchesFailed: (state) => {
            return {
                ...state,
                data: null,
                loading: false,
                error: true
            }
        }
    }
})

export const {
    requestElevateBatches,
    receivedBatches,
    requestBatchesFailed
} = elevateBatches.actions;

export const elevateBatchMetaSelector = (state: RootState) => state.elevateBatches;

export default elevateBatches.reducer;