import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { SubjectListFilter, Subject, SubjectsResponse } from '../types/Subjects'
import { Vertical } from '../types/TrackCategory'

/**
 * Returns list of subjects for given filter without pagination
 */
export default function listSubjects(params: any) {
  return httpService.get('/v4/content/sources/subjects/', {
    params: {
      platform: params?.platformId,
      group: params?.platformGroup,
      packId: params?.packId
    },
  })
}

/**
 * Returns list of subjects
 * @param filter
 */
export function getSubjects(
  filter: SubjectListFilter
): Promise<AxiosResponse<SubjectsResponse>> {
  return httpService.get('/v7/content/sources/subjects/', {
    params: filter,
  })
}

/**
 * Returns list of mapped subjects for given filter
 * @param platformId
 * @param subjectId
 * @returns
 */
export function getPlatformSubjects(
  platformId: number,
  filter: SubjectListFilter
): Promise<AxiosResponse<SubjectsResponse>> {
  return httpService.get(`/v7/content/sources/platforms/${platformId}/subjects/`, {
    params: {
      ...filter
    },
  })
}

/**
 * Fetch Subject Details
 * @param subjectId
 * @param platformId
 * @param vertical
 * @returns
 */
export function getSubject(
  subjectId: number,
  filter: SubjectListFilter
): Promise<AxiosResponse<Subject>> {
  return httpService.get(`/v7/content/sources/subjects/${subjectId}/`,
    {
      params: filter
    })
}


/**
 * Create Subject
 * @param platformId
 * @param subjectDetails
 * @returns
 */
export function createSubject(subjectDetails: FormData): Promise<AxiosResponse<Subject>> {
  return httpService.post(`/v7/content/sources/subjects/`, subjectDetails)
}

/**
 * Update Subject
 * @param subjectId
 * @param subjectDetails
 * @returns
 */
export function updateSubject(
  subjectId: number,
  subjectDetails: FormData,
): Promise<AxiosResponse<Subject>> {
  return httpService.put(`/v7/content/sources/subjects/${subjectId}/`, subjectDetails)
}

/**
 * Map Subject to a platform
 * @param subjectId
 * @param platformId
 * @param vertical
 * @returns
 */
export function mapSubject(
  subjectId: number,
  platformId: number,
  vertical: Vertical,
): Promise<AxiosResponse<Subject>> {
  return httpService.post(`/v7/content/sources/platforms/${platformId}/plot/subjects/${subjectId}/`, {
    params: { vertical }
  })
}

/**
 * Remove Subject mapping from a platform
 * @param subjectId
 * @param platformId
 * @param vertical
 * @returns
 */
export function removeSubject(
  subjectId: number,
  platformId: number,
  vertical: Vertical,
): Promise<AxiosResponse<Subject>> {
  return httpService.delete(`/v7/content/sources/platforms/${platformId}/plot/subjects/${subjectId}/`, {
    params: { vertical }
  })
}

/**
 * Activate a Subject Pack for a platform
 * @param subjectId
 * @param platformId
 * @param vertical
 * @returns
 */
export function activateSubject(
  subjectId: number,
  platformId: number,
  vertical: Vertical,
): Promise<AxiosResponse<Subject>> {
  return httpService.post(`/v7/content/sources/platforms/${platformId}/activate/subjects/${subjectId}/`, {
    params: { vertical }
  })
}

/**
 * Activate a Subject Pack for a platform
 * @param subjectId
 * @param platformId
 * @param vertical
 * @returns
 */
export function deactivateSubject(
  subjectId: number,
  platformId: number,
  vertical: Vertical,
): Promise<AxiosResponse<Subject>> {
  return httpService.delete(`/v7/content/sources/platforms/${platformId}/activate/subjects/${subjectId}/`, {
    params: { vertical }
  })
}
