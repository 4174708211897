export enum Permissions {
  LIST = 'LIST',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  ACTIVATE = 'ACTIVATE',
  APPROVE = 'APPROVE',
  PAYMENT = 'PAYMENT',
  REMUNERATION = 'REMUNERATION',
  DOWNLOAD = 'DOWNLOAD',
}

export enum PermissionLevels {
  TRACKS = 'track',
  PLATFORM = 'platform',
  EXAMS = 'exam',
  JOBS = 'job',
  QUESTION_COLLECTIONS = 'questioncollection',
  QUESTIONS = 'question',
  QUESTIONS_MAPPING = 'questionmapping',
  REPORTED_QUESTIONS = 'questionreport',
  APPEALED_QUESTIONS = 'appealedquestion',
  EXPIRED_QUESTIONS = 'expiredquestion',
  STUDY_CARD_PACKS = 'studycardpack',
  STUDY_CARD_STACKS = 'studycardstack',
  STUDY_CARD_CARDS = 'studycardcard',
  DISCUSS = 'discuss',
  VIDEOS = 'video',
  VIDEO_PACKS = 'video_pack',
  VIDEO_QA = 'videoqa',
  VIDEO_PACK_SORTING = 'video_pack_sorting',
  HD_VIDEOS = 'hd_videos',
  PACKS = 'pack',
  TEST_SERIES = 'testseries',
  QUIZ = 'quiz',
  MOCK = 'mockpull',
  SCREENLET = 'screenlet',
  PLAYLIST = 'playlist',
  SUBJECT = 'subject',
  MODULE = 'module',
  TOPIC = 'topic',
  DAILYSTUDYPLAN = 'dailystudyplan',
  FREETRIALSTUDYPLAN = 'freetrialstudyplan',
  ONBOARDINGQUESTION = 'onboardingquestion',
  PRODUCTPACKAGE = 'productpackage',
  PLAN = 'plan',
  GOLDPROGRAM = 'goldprogram',
  PURCHASE = 'purchase',
  TEACHERS = 'teacher',
  PROFILE = 'profile',
  ELEVATE_MENTOR = 'elevate-mentor',
  ELEVATE_LIVE_CLASS = 'elevateliveclass',
  ELEVATE_PURCHASE = 'elevatepurchase',
  LANDING_PAGE = 'landingpage',
  ELEVATE = 'elevate',
  BATCH = 'batch',
  LIVECLASS_REMUNERATION = 'facultylivedetails',
  NEW_ELEVATE_BATCH_REPORT = 'newElevateBatchReport',
}

export type ViewPermissions = {
  [p in PermissionLevels]?: boolean
}

export type PermissionLevelsMap = {
  [p in PermissionLevels]?: Permissions[]
}

export interface AccessControls {
  viewPermissions: ViewPermissions
  permissionLevels?: PermissionLevelsMap | undefined
}
export interface AccessControlsResponse {
  resource: PermissionLevels
  permissions: Permissions[]
}
