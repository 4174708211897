import { createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { ReportedQuestions } from '../types/ReportedQuestions'

export const getReportedQuestions = (
  filter: ReportedQuestions.Filter,
): Promise<AxiosResponse<ReportedQuestions.ReportedQuestionsResponse>> => {
  return httpService.get(`/v7/content/sources/question-reports/`, {
    params: {
      ...filter,
    },
  })
}

export const postReviewComment = (
  id: number,
  ceComment: string,
): Promise<AxiosResponse<ReportedQuestions.ReportedQuestion>> => {
  return httpService.put(`/v7/content/sources/question-reports/${id}/correction/`, { ceComment })
}

export const reassignReportedQuestions = (
  id: number,
  ceComment: string,
): Promise<AxiosResponse<ReportedQuestions.ReportedQuestion>> => {
  return httpService.put(`/v7/content/sources/question-reports/${id}/reassign/`, { ceComment })
}
export const notifyReportedQuestions = (
  id: number,
  ceComment: string,
): Promise<AxiosResponse<ReportedQuestions.ReportedQuestion>> => {
  return httpService.put(`/v7/content/sources/question-reports/${id}/notify/`, { ceComment })
}
