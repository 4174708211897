import React, { useState } from 'react'
import { Form } from '@ant-design/compatible'
import { Col, Modal, Row, Select, Form as FormComp } from 'antd'
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import store from 'store'
import { devChannelsAvailable } from '../../helper/constants'
import { history } from '../../index'
import { logoutUser } from '../../redux/user/actions'
import { useDispatch } from 'react-redux'

interface DevChannelModalProps {
  isModalOpen: boolean
  cancelModal: Function
}

const ChangeDevChannelModal: React.FC<DevChannelModalProps> = ({ isModalOpen, cancelModal }) => {
  const dispatch = useDispatch()

  const [selectedChannel, setSelectedChannel] = useState<WrappedFormUtils>(store.get('channel'))

  const handleChange = (value: WrappedFormUtils) => {
    setSelectedChannel(value)
  }

  const handleOk = () => {
    if (selectedChannel == store.get('channel')) cancelModal()
    else {
      store.set('channel', selectedChannel)
      store.remove('user')
      dispatch(logoutUser(true))
      history.go(0)
      cancelModal()
    }
  }

  return (
    <Modal
      title="Update Dev Channel End Pont"
      open={isModalOpen}
      centered
      closable={false}
      onCancel={() => cancelModal()}
      maskClosable={false}
      onOk={handleOk}
    >
      <Form layout="vertical">
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="" className="mb-0">
              {
                <Select
                  defaultValue={store.get('channel') || process.env?.REACT_APP_API_ENDPOINT}
                  className="w-100"
                  allowClear
                  placeholder="Select a channel"
                  onChange={handleChange}
                >
                  {devChannelsAvailable?.map(channel => (
                    <Select.Option key={channel.value} value={channel.label} label={channel.label}>
                      {channel.label}
                    </Select.Option>
                  ))}
                </Select>
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ChangeDevChannelModal
