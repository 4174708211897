import { AxiosResponse } from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getAllStudyPlanBatches, getElevatePacks, getFaculties, getLanguages, getLicensedUsers, getLiveClasses, getSections } from '../../api/liveclasses'
import { getAllStudyPlan } from '../../api/studyPlans'
import { pushParamsToUrl } from '../../helper/urlParamSerializer'
import { requestLanguage } from '../languages/languageSlice'
import {
  FETCH_ALL_BATCHES,
  FETCH_ALL_FACULTIES,
  FETCH_ALL_LANGUAGES,
  FETCH_ALL_PACKS,
  FETCH_ALL_STUDY_PLAN,
  FETCH_LICENSED_USERS,
  FETCH_LIVE_CLASSES,
  FETCH_SECTION,
} from './actions'
import {
  receivedAllStudyPlan,
  receiveErrorOnAllStudyPlan,
  requestFetchAllStudyPlan,
  requestFetchStudyPlanBatches,
  receivedStudyPlanBatches,
  receiveErrorOnStudyPlanBatches,
  requestAllLiveClasses,
  receivedAllLiveClasses,
  receiveErrorAllLiveClasses,
  requestLicensedUsers,
  receivedLicensedUsers,
  receiveErrorLicensedUsers,
  requestPacks,
  receivedPacks,
  receiveErrorPacks,
  requestFaculties,
  receivedErrorFaculties,
  receivedFaculties,
  receivedErrorLanguages,
  receivedLanguages,
} from './slice'
import { BatchesPayloadType, Filter, FilterPayloadType, StudyPlanPayloadType } from './types'

export function* GET_ALL_STUDY_PLAN({ payload }: StudyPlanPayloadType) {
  yield put(requestFetchAllStudyPlan())
  try {
    const response: AxiosResponse = yield call(getAllStudyPlan, payload)
    const { data } = response
    yield put(receivedAllStudyPlan(data))
  } catch (e) {
    yield put(receiveErrorOnAllStudyPlan())
  }
}

export function* GET_ALL_BATCHES({ payload }: BatchesPayloadType) {
  yield put(requestFetchStudyPlanBatches())
  try {
    const response: AxiosResponse = yield call(getAllStudyPlanBatches, payload)
    const { data } = response
    yield put(receivedStudyPlanBatches(data))
  } catch (e) {
    yield put(receiveErrorOnStudyPlanBatches())
  }
}

export function* GET_ALL_LIVE_CLASSES({ payload }: FilterPayloadType) {
  yield put(requestAllLiveClasses())
  try {
    const filter = payload.filter as Filter
    const courseVertical = payload.courseVertical
    const packCategory = payload.packCategory;
    yield pushParamsToUrl(filter)
    const response: AxiosResponse = yield call(getLiveClasses, courseVertical, packCategory, filter)
    const { data } = response
    yield put(receivedAllLiveClasses(data))
  } catch (e) {
    yield put(receiveErrorAllLiveClasses())
  }
}

export function* GET_LICENSED_USERS() {
  yield put(requestLicensedUsers())
  try {
    const response: AxiosResponse = yield call(getLicensedUsers)
    const { data } = response
    yield put(receivedLicensedUsers(data))
  } catch (e) {
    yield put(receiveErrorLicensedUsers())
  }
}

export function* GET_ALL_PACKS({ payload }: { type: string, payload?: { languages?: string, isOldLiveClass?: boolean } }) {
  yield put(requestPacks())
  try {
    const { data }: AxiosResponse = yield call(getElevatePacks, payload);
    yield put(receivedPacks(data))
  } catch (error) {
    yield put(receiveErrorPacks())
  }
}

export function* GET_ALL_FACULTIES() {
  yield put(requestFaculties())
  try {
    const { data }: AxiosResponse = yield call(getFaculties);
    yield put(receivedFaculties(data))
  } catch (error) {
    yield put(receivedErrorFaculties())
  }
}

export function* GET_ALL_LANGUAGES() {
  yield put(requestLanguage());
  try {
    const { data }: AxiosResponse = yield call(getLanguages);
    yield put(receivedLanguages(data))
  } catch (error) {
    yield put(receivedErrorLanguages())
  }
}


export default function* rootSaga() {
  yield all([takeLatest(FETCH_ALL_STUDY_PLAN, GET_ALL_STUDY_PLAN)])
  yield all([takeLatest(FETCH_ALL_BATCHES, GET_ALL_BATCHES)])
  yield all([takeLatest(FETCH_LIVE_CLASSES, GET_ALL_LIVE_CLASSES)])
  yield all([takeLatest(FETCH_LICENSED_USERS, GET_LICENSED_USERS)])
  yield all([takeLatest(FETCH_ALL_PACKS, GET_ALL_PACKS)])
  yield all([takeLatest(FETCH_ALL_FACULTIES, GET_ALL_FACULTIES)]);
  yield all([takeLatest(FETCH_ALL_LANGUAGES, GET_ALL_LANGUAGES)])
}
