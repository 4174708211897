import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import store from 'store'
import { AppThunk, RootState } from '../..'

export interface Settings {
  [key: string]: boolean | string | undefined
  isMobileView: boolean
  isMobileMenuOpen: boolean
  isLightTheme: boolean
  isSettingsOpen: boolean
  isMenuCollapsed: boolean
  isBorderless: boolean
  isSquaredBorders: boolean
  isFixedWidth: boolean
  isMenuShadow: boolean
  isMenuTop: boolean
  hasUpdate: boolean
  version: string
  locale: string
}

interface SettingsPayload {
  [key: string]: boolean | string | undefined
}

export interface SettingsData {
  settings: keyof Settings
  value: boolean | string
}

const STORED_SETTINGS = (storedSettings: Settings) => {
  const settings = {} as Settings
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState: Settings = {
  ...STORED_SETTINGS({
    isMobileView: false,
    isMobileMenuOpen: false,
    isLightTheme: true,
    isSettingsOpen: false,
    isMenuCollapsed: false,
    isBorderless: true,
    isSquaredBorders: false,
    isFixedWidth: false,
    isMenuShadow: false,
    isMenuTop: false,
    hasUpdate: false,
    version: '',
    locale: 'en-US',
  }),
}

export const settingsSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<SettingsPayload>) => {
      return { ...state, ...action.payload }
    },
  },
})

const { updateSettings } = settingsSlice.actions

export const changeSetting = (settingsData: SettingsData): AppThunk => async dispatch => {
  const { settings, value } = settingsData
  store.set(`app.settings.${settings}`, value)
  dispatch(updateSettings({ [settings]: value }))
}

export const selectSettings = (state: RootState) => state.settingsReducer

export default settingsSlice.reducer
