import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { Route, Redirect } from 'react-router-dom'
import { checkUserHasPermission } from '../../helper/checkUserPermission'
import useAccessControl from '../../hooks/useAccessControls'
import { UserImpl, UserRoles } from '../../redux/user/types'
import { PermissionLevels, Permissions } from '../../types/AccessControls'
import Loader from './Loader'

export interface AuthRouteProps {
  Component: React.ComponentType<any>
  path: string
  exact: boolean
  requiredPermission: [PermissionLevels, Permissions]
  user: UserImpl
}

const AuthRoute: React.FC<AuthRouteProps> = ({
  Component,
  path,
  exact = false,
  requiredPermission,
  user,
}) => {
  const [isPermissionsLoading, setIsPermissionLoading] = useState(true)
  const [userHasRequiredPermission, setUserHasRequiredPermission] = useState(false)
  const isAuthorized = user.authorized
  const [permissionLevel] = requiredPermission
  const [allowedPermission] = useAccessControl(permissionLevel)
  useEffect(() => {
    if (!allowedPermission) {
      setIsPermissionLoading(true)
      return
    }
    setUserHasRequiredPermission(checkUserHasPermission(requiredPermission[1], allowedPermission))
    setIsPermissionLoading(false)
  }, [allowedPermission, requiredPermission])

  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        isAuthorized && userHasRequiredPermission && !isPermissionsLoading ? (
          <Component {...props} />
        ) : isPermissionsLoading ? (
          <Loader spinning />
        ) : (
          <Redirect
            to={{
              pathname: userHasRequiredPermission ? '/user/login/' : '/404',
              state: { requestedPath: path },
            }}
          />
        )
      }
    />
  )
}

export default connect(({ user }: { user: UserImpl }) => ({ user }))(AuthRoute)
