import { combineReducers } from 'redux'
import actions, {
  RECEIVED_COLLECTION,
  REQUESTED_COLLECTION,
  REQUESTED_COLLECTION_FAILED,
  REQUESTED_COLLECTION_SUCCEEDED,
} from './actions'

const initialState = {
  data: null,
  isLoading: false,
  hasError: false,
}

export const initialListState = {
  ...initialState,
  filter: {
    mockPull: false,
    pageSize: 50,
    page: 1,
  },
}

function list(state = initialListState, action) {
  switch (action.type) {
    case actions.SET_LIST:
      return { ...state, ...action.payload }
    case actions.SET_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload } }
    default:
      return state
  }
}

function details(state = initialState, action) {
  switch (action.type) {
    case REQUESTED_COLLECTION:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case RECEIVED_COLLECTION:
      return {
        ...state,
        data: action.data,
      }
    case REQUESTED_COLLECTION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      }
    case REQUESTED_COLLECTION_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export { list, details }
export default combineReducers({
  list,
  details,
})
