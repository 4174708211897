import { AxiosResponse } from 'axios'
import { Teacher, TeachersListFilter, TeachersResponse } from '../types/Teachers'
import httpService from '../httpService'

/**
 * Returns list of teachers
 */
export const getTeachers = (filter?: TeachersListFilter): Promise<AxiosResponse<Teacher[]>> => {
  return httpService.get(`/v7/content/sources/teachers/`, {
    params: filter
  })
}

/**
 * Returns details of a teacher
 * @param teacherId
 */
export const getTeacher = (teacherId: number): Promise<AxiosResponse<Teacher>> => {
  return httpService.get(`/v7/content/sources/teachers/${teacherId}/`)
}

/**
 * Create new teacher
 * @param teacherDetails
 */
export const createTeacher = (teacherDetails: FormData): Promise<AxiosResponse<Teacher>> => {
  return httpService.post('/v7/content/sources/teachers/', teacherDetails)
}

/**
 * Updates details of a teacher
 * @param teacherId
 * @param teacherDetails
 */
export const updateTeacher = (teacherId: number, teacherDetails: FormData): Promise<AxiosResponse<Teacher>> => {
  return httpService.put(`/v7/content/sources/teachers/${teacherId}/`, teacherDetails)
}