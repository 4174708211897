import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { InitialState, Filters, BaseList } from './types';

const initialState: InitialState = {
    centers: {
        loading: false,
        data: null,
        error: null
    },
    batches: {
        loading: false,
        data: null,
        error: null
    },
    institutes: {
        loading: false,
        data: null,
        error: null
    },
    tracks: {
        loading: false,
        data: null,
        error: null
    }
}


const filterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        requestCenters: (state, _) => {
            state.centers = {
                loading: false,
                data: null,
                error: null
            }
        },
        receivedCenters: (state, action: PayloadAction<Filters.Center[]>) => {
            state.centers = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        requestBathes: (state) => {
            state.batches = {
                loading: false,
                data: null,
                error: null
            }
        },
        receivedBatches: (state, action: PayloadAction<Filters.Batch>) => {
            state.batches = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        requestInstitutes: (state) => {
            state.institutes = {
                loading: false,
                data: null,
                error: null
            }
        },
        receivedInstitutes: (state, action: PayloadAction<Filters.Institute[]>) => {
            state.institutes = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        requestTracks: (state) => {
            state.tracks = {
                loading: false,
                data: null,
                error: null
            }
        },
        receivedTracks: (state, action: PayloadAction<BaseList[]>) => {
            state.tracks = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
    }
})

export const institutesSelector = (state: RootState) => state.filters.institutes;
export const centersSelectors = (state: RootState) => state.filters.centers
export const tracksSelector = (state: RootState) => state.filters.tracks;
export const {
    requestCenters,
    receivedCenters,
    requestBathes,
    receivedBatches,
    requestInstitutes,
    receivedInstitutes,
    requestTracks,
    receivedTracks
} = filterSlice.actions


export default filterSlice.reducer;