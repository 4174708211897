import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { QuestionListingInterface, QuestionResponse, Questions } from './types';


const initialState: QuestionListingInterface = {
    questionListing: {
        data: null,
        loading: false,
        error: false
    },
    questionDetail: {
        data: null,
        loading: false,
        error: false
    }
}

const goldQuestionlice = createSlice({
    name: 'goldQuestions',
    initialState,
    reducers: {
        requestQuestions: (state) => {
            state.questionListing = {
                loading: true,
                data: null,
                error: false
            }
        },
        receivedQuestions: (state, action: PayloadAction<Questions>) => {
            state.questionListing = {
                loading: false,
                data: action.payload,
                error: false
            }
        },
        errorOnQuestions: (state) => {
            state.questionListing = {
                loading: false,
                data: null,
                error: true
            }
        },
        requestQuestionDetail: (state, _) => {
            state.questionDetail = {
                loading: true,
                data: null,
                error: false
            }
        },
        receivedQuestionDetail: (state, action: PayloadAction<QuestionResponse>) => {
            state.questionDetail = {
                loading: false,
                data: action.payload,
                error: false
            }
        },
        errorOnQuestionDetail: (state) => {
            state.questionDetail = {
                loading: false,
                data: null,
                error: true
            }
        },
        clearQuestion: (state) => {
            state.questionDetail = {
                loading: false,
                data: null,
                error: false
            }
        }
    }
})


export const questionSelector = (state: RootState) => state.goldQuestions.questionListing
export const questionDetailSelector = (state: RootState) => state.goldQuestions.questionDetail;


export const {
    requestQuestions,
    receivedQuestions,
    errorOnQuestions,
    requestQuestionDetail,
    receivedQuestionDetail,
    errorOnQuestionDetail,
    clearQuestion
} = goldQuestionlice.actions


export default goldQuestionlice.reducer