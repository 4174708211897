import { all, takeEvery, call, put } from 'redux-saga/effects'
import { deleteFile } from '../../api/files'
import { DELETE_FILE, FileActionImpl } from './types'
import { showErrorNotification, showSuccessNotification } from '../notifications/actions'
import { AxiosResponse } from 'axios'

/**
 * Invoked when Create Job is dispatched
 * @param {Object} object containing action and payload <Job data object>
 */
export function* REMOVE_FILE({ payload }: FileActionImpl) {
  try {
    const response: AxiosResponse = yield call(deleteFile, payload)
    if (response) {
      yield put(showSuccessNotification('Successfully removed file'))
    }
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while removing file'))
  }
}

export default function* rootSaga() {
  yield all([takeEvery(DELETE_FILE, REMOVE_FILE)])
}
