import { AxiosResponse } from 'axios';
import httpService from '../httpService';
import { ProductPackages } from '../redux/goldPurchase/types';
import { ProductPackageQuestionOrder, ProductPackageQuestions } from '../redux/productPackage/types';
import { RevenuePod } from '../types/ProductPackage';

export function getProductPackages(payload: string): Promise<AxiosResponse<ProductPackages>> {
    return httpService.get('v6/gold/product_packages/', {
        params: payload
    })
}

export function getQuestions(
    productPackageId: string,
): Promise<AxiosResponse<ProductPackageQuestions>> {
    return httpService.get(`v6/gold/product_packages/${productPackageId}/questions/`)
}

export function updateQuestionOrder(
    productPackageId: string,
    questionsOrder: ProductPackageQuestionOrder,
): Promise<AxiosResponse> {
    return httpService.post(`v6/gold/product_packages/${productPackageId}/questions/`, questionsOrder)
}