import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { TopicsListFilter, TopicListState, TopicListPayload } from '../../types/Topics'
import { AppThunk, RootState } from '../..'
import listTopics, { getModuleTopics, getTopics } from '../../api/topics'

export const initialTopicsList: TopicListState = {
    data: [],
    meta: {
        page: 1,
        pageCount: 1,
        limit: 20,
    },
    filter: {
        pageSize: 20,
        page: 1,
    },
    isLoading: false,
    hasError: false,
}

const TopicsListSlice = createSlice({
    name: 'topicListing',
    initialState: initialTopicsList,
    reducers: {
        requestTopics(state) {
            return { ...state, isLoading: true, hasError: false }
        },
        requestedTopicsFailed(state) {
            return { ...state, isLoading: false, hasError: true }
        },
        receivedTopics(state, action: PayloadAction<TopicListPayload>) {
            const { data, filter, meta } = action.payload
            const newFilter: TopicsListFilter = {
                ...state.filter,
                ...filter,
            }
            return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
        },
    },
})

export const { receivedTopics, requestTopics, requestedTopicsFailed } = TopicsListSlice.actions

// v4 API without pagination
export const fetchAllTopics =
    (filter: any = {}): AppThunk =>
        async dispatch => {
            dispatch(requestTopics())
            try {
                const response = await listTopics(filter)
                if (response) {
                    const data = response.data
                    dispatch(
                        receivedTopics({
                            data,
                            filter,
                        }),
                    )
                }
            } catch (error) {
                dispatch(requestedTopicsFailed())
            }
        }

export const fetchModuleTopics =
    (moduleId: number, platformId: number, filter: TopicsListFilter): AppThunk =>
        async dispatch => {
            dispatch(requestTopics())
            try {
                const response = await getModuleTopics(moduleId, platformId, filter)
                if (response) {
                    const { results: data, ...meta } = response.data
                    dispatch(
                        receivedTopics({
                            data,
                            filter,
                            meta,
                        }),
                    )
                }
            } catch (error) {
                dispatch(requestedTopicsFailed())
            }
        }

export const fetchTopics =
    (filter: TopicsListFilter): AppThunk =>
        async dispatch => {
            dispatch(requestTopics())
            try {
                const response = await getTopics(filter)
                if (response) {
                    const { results: data, ...meta } = response.data
                    dispatch(
                        receivedTopics({
                            data,
                            filter,
                            meta,
                        }),
                    )
                }
            } catch (error) {
                dispatch(requestedTopicsFailed())
            }
        }

export const selectTopics = (state: RootState) => state.topics.list

export default combineReducers({
    list: TopicsListSlice.reducer,
})
