import { all, put, call, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { showErrorNotification } from '../notifications/actions'
import { LiveDetails, StudyPlan, StudyPlanList } from './types'
import {
  requestStudyPlans,
  receivedStudyPlans,
  errorStudyPlans,
  receivedPlanDetails,
  requestPlanDetails,
  errorPlanDetails,
  requestFreePlans,
  requestLiveDetails,
  successLiveDetails,
  errorLiveDetails,
} from './slice'
import {
  listStudyPlans,
  detailsStudyPlan,
  listFreeStudyPlans,
  getLiveDetails,
} from '../../api/studyPlans'
import { pushParamsToUrl } from '../../helper/urlParamSerializer'

interface CenterPayload {
  payload: string
  type: string
}

interface StudyPlanListParams {
  payload?: StudyPlanList.Filters
  type: string
}

interface LivePayload {
  payload: { studyPlanId: string; liveClassId: number }
  type: string
}

export function* LIST_STUDY_PLANS({ payload }: StudyPlanListParams) {
  try {
    payload && pushParamsToUrl(payload)
    const response: { data: StudyPlanList.Root } = yield call(listStudyPlans, payload)
    yield put(receivedStudyPlans(response.data))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching Study Plans'))
    yield put(errorStudyPlans((error as Error).message))
  }
}

export function* LIST_FREE_STUDY_PLANS({ payload }: StudyPlanListParams) {
  try {
    payload && pushParamsToUrl(payload)
    const response: { data: StudyPlanList.Root } = yield call(listFreeStudyPlans, payload)
    yield put(receivedStudyPlans(response.data))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching Study Plans'))
    yield put(errorStudyPlans((error as Error).message))
  }
}

export function* STUDY_PLAN_DETAILS({ payload }: CenterPayload) {
  try {
    const response: { data: StudyPlan.Root } = yield call(detailsStudyPlan, payload)
    yield put(receivedPlanDetails(response.data))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching Study Plan Details'))
    yield put(errorPlanDetails((error as Error).message))
  }
}

export function* GET_LIVE_DETAILS({ payload }: LivePayload) {
  try {
    const response: { data: LiveDetails } = yield call(
      getLiveDetails,
      payload.studyPlanId,
      payload.liveClassId,
    )
    yield put(successLiveDetails(response.data))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching Live Class Details'))
    yield put(errorLiveDetails((error as Error).message))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(requestStudyPlans.type, LIST_STUDY_PLANS),
    takeLatest(requestPlanDetails.type, STUDY_PLAN_DETAILS),
    takeLatest(requestFreePlans.type, LIST_FREE_STUDY_PLANS),
    takeLatest(requestLiveDetails.type, GET_LIVE_DETAILS),
  ])
}
