import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  HDVideosState,
  HDVideos,
  PacksState,
  VideoPacks,
  Tracks,
  PlayListVideoState,
  PlaylistListing,
  VideosState,
  VideoPack,
  PlayList,
  Videos,
  QAVideos,
  QAVideosState,
  TracksResponse,
} from './types'

export const initialHDVideosList: HDVideosState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const HDVideoSlice = createSlice({
  name: 'HDVideos',
  initialState: initialHDVideosList,
  reducers: {
    requestHDVideos(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedHDVideos(state, action: PayloadAction<HDVideos>) {
      const { data, meta, filter } = action.payload as HDVideos
      return { ...state, data, meta, filter, isLoading: false, hasError: false }
    },
    requestedHDVideosFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
  },
})

export const { requestHDVideos, receivedHDVideos, requestedHDVideosFailed } = HDVideoSlice.actions

export const hDVideos = combineReducers({
  list: HDVideoSlice.reducer,
})

// video packs splice start from here
export const initialPackList: PacksState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const packList = createSlice({
  name: 'videoPacks',
  initialState: initialPackList,
  reducers: {
    requestPacks(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedPacks(state, action: PayloadAction<VideoPacks>) {
      const { data, meta, filter } = action.payload
      return { ...state, data, meta, filter, isLoading: false, hasError: false }
    },
    requestedPacksFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
  },
})

export const { requestPacks, receivedPacks, requestedPacksFailed } = packList.actions

export const initialSortedVideoPack: PacksState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 100,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const sortedVideoPackSlice = createSlice({
  name: 'sortedVideoPacks',
  initialState: initialSortedVideoPack,
  reducers: {
    requestSortedVideoPacks(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedSortedVideoPacks(state, action: PayloadAction<VideoPacks>) {
      const { data, meta, filter } = action.payload
      return { ...state, data, meta, filter, isLoading: false, hasError: false }
    },
    requestedSortedVideoPacksFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
  },
})

export const {
  requestSortedVideoPacks,
  receivedSortedVideoPacks,
  requestedSortedVideoPacksFailed,
} = sortedVideoPackSlice.actions

export const sortedVideoPacks = combineReducers({
  list: sortedVideoPackSlice.reducer,
})

export const initialTracks: Tracks = {
  data: [],
  isLoading: false,
  hasError: false,
}

const tracks = createSlice({
  name: 'tracks',
  initialState: initialTracks,
  reducers: {
    requestedTracks(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedTracks(state, action: PayloadAction<TracksResponse>) {
      const { data } = action.payload as TracksResponse
      return { ...state, data, isLoading: false, hasError: false }
    },
    requestedTracksFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
  },
})

export const { requestedTracks, receivedTracks, requestedTracksFailed } = tracks.actions

const selectedPack = createSlice({
  name: 'selectedPack',
  initialState: {},
  reducers: {
    receivedSelectedPack(state, action: PayloadAction<VideoPack>) {
      const { payload: data } = action
      return { ...state, ...data }
    },
  },
})

export const { receivedSelectedPack } = selectedPack.actions

export const videoPacks = combineReducers({
  list: packList.reducer,
  tracks: tracks.reducer,
  selectedPack: selectedPack.reducer,
})

// video playlists splice start from here
export const initialPlayLists: PlayListVideoState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const playlists = createSlice({
  name: 'videoPlaylists',
  initialState: initialPlayLists,
  reducers: {
    requestPlaylists(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedPlaylists(state, action: PayloadAction<PlaylistListing>) {
      const { data, meta, filter } = action.payload
      return { ...state, data, meta, filter, isLoading: false, hasError: false }
    },
    requestedPlaylistsFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    setDefaultPlaylistsFilter(state) {
      return { ...state, filter: initialPlayLists.filter }
    },
  },
})

export const {
  requestPlaylists,
  receivedPlaylists,
  requestedPlaylistsFailed,
  setDefaultPlaylistsFilter,
} = playlists.actions

const selectedPlaylist = createSlice({
  name: 'selectedPlaylist',
  initialState: {},
  reducers: {
    receivedSelectedPlaylist(state, action: PayloadAction<PlayList>) {
      const { payload: data } = action
      return { ...state, ...data }
    },
  },
})

export const { receivedSelectedPlaylist } = selectedPlaylist.actions

export const videoPlaylists = combineReducers({
  list: playlists.reducer,
  selectedPlaylist: selectedPlaylist.reducer,
})

// videos splice start from here
export const initialVideos: VideosState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const videosList = createSlice({
  name: 'videosList',
  initialState: initialVideos,
  reducers: {
    requestVideos(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedVideos(state, action: PayloadAction<Videos>) {
      const { data, meta, filter } = action.payload
      return { ...state, data, meta, filter, isLoading: false, hasError: false }
    },
    requestedVideosFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    removeVideoData(state) {
      return { ...state, data: [] }
    },
    setDefaultVideosFilter(state) {
      return { ...state, filter: initialVideos.filter }
    },
  },
})

export const {
  requestVideos,
  receivedVideos,
  requestedVideosFailed,
  removeVideoData,
  setDefaultVideosFilter,
} = videosList.actions

export const videos = combineReducers({
  list: videosList.reducer,
})

// Video QA related slice start from here
export const initialQaVideoListing: QAVideosState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    status: 'rejected',
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const QaVideoListingSlice = createSlice({
  name: 'qaVideoListing',
  initialState: initialQaVideoListing,
  reducers: {
    requestQaVideoListing(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedQaVideoListing(state, action: PayloadAction<QAVideos>) {
      const { data, meta, filter } = action.payload as QAVideos
      return { ...state, data, meta, filter, isLoading: false, hasError: false }
    },
    requestedVideoListingFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
  },
})

export const { requestQaVideoListing, receivedQaVideoListing, requestedVideoListingFailed } =
  QaVideoListingSlice.actions

export const qaVideos = combineReducers({
  list: QaVideoListingSlice.reducer,
})
