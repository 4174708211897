import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  DiscussesStateImpl,
  DiscussActionImpl,
  DiscussesImpl,
  DiscussPostStateImpl,
  DiscussPostImpl,
  CommentsStateImpl,
  CommentsImpl,
  RepliesUpdateImpl,
  CommentUpdateImpl,
  DiscussPostPatchImpl,
  FilterImpl,
  ReplyUpdateImpl,
} from './types'

export const initialDiscussList: DiscussesStateImpl = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

export const initialCommentsList: CommentsStateImpl = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  isRepliesLoading: false,
  hasError: false,
}

export const initialDiscussPost: DiscussPostStateImpl = {
  data: {},
  isLoading: false,
  hasError: false,
  statusCode: 200,
}

const listSlice = createSlice({
  name: 'discusses',
  initialState: initialDiscussList,
  reducers: {
    requestDiscusses(state, action: DiscussActionImpl) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedDiscussesSucceeded(state) {
      return { ...state, isLoading: false, hasError: false }
    },
    requestedDiscussesFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedDiscusses(state, action: PayloadAction<DiscussesImpl>) {
      const { data, meta, filter } = action.payload as DiscussesImpl
      return { ...state, data, meta, filter }
    },
    updatePosts(state, action: PayloadAction<number>) {
      const postId = action.payload
      const {
        data: posts,
        meta: { totalObjectCount },
      } = state
      const newPosts = posts.filter(post => post.id !== postId)
      if (totalObjectCount) state.meta.totalObjectCount = totalObjectCount - 1
      state.data = newPosts
    },
    markReplyRequired(state, action: PayloadAction<DiscussPostPatchImpl>) {
      const { postId, postDetails } = action.payload
      if (postId) {
        const { data: posts } = state
        const postIndex = posts.findIndex(post => post.id === postId)
        posts[postIndex] = { ...posts[postIndex], ...postDetails }
      }
    },
  },
})

const detailSlice = createSlice({
  name: 'discuss',
  initialState: initialDiscussPost,
  reducers: {
    requestDiscuss(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedDiscussSucceeded(state) {
      return { ...state, isLoading: false, hasError: false }
    },
    requestedDiscussFailed(state, action: PayloadAction<number>) {
      const statusCode = action.payload
      return { ...state, isLoading: false, hasError: true, statusCode }
    },
    receivedDiscuss(state, action: PayloadAction<DiscussPostImpl>) {
      const data = action.payload as DiscussPostImpl
      return { ...state, data }
    },
    updatePost(state, action: PayloadAction<DiscussPostImpl>) {
      const { data: currentPost } = state
      const newPost = action.payload
      state.data = { ...currentPost, ...newPost }
    },
  },
})

const commentsSlice = createSlice({
  name: 'discuss/comments',
  initialState: initialCommentsList,
  reducers: {
    requestComments(state, action: PayloadAction<FilterImpl>) {
      const filter = action.payload
      return {
        ...state,
        data: filter.page === 1 ? [] : state.data,
        isLoading: true,
        hasError: false,
      }
    },
    requestedCommentsSucceeded(state) {
      return { ...state, isLoading: false, hasError: false }
    },
    requestedCommentsFailed(state) {
      return { ...state, isLoading: false, isRepliesLoading: false, hasError: true }
    },
    receivedComments(state, action: PayloadAction<CommentsImpl>) {
      const { data: comments, meta, filter } = action.payload
      const { data = [] } = state
      return { ...state, data: [...data, ...comments], meta, filter }
    },
    requestReplies(state) {
      return { ...state, isRepliesLoading: true, hasError: false }
    },

    /**
     * Update a particular comment
     * Either delete a comment fromm the list or update a comment in the list
     */
    updateComment(state, action: PayloadAction<CommentUpdateImpl>) {
      const { comment, commentId, isDelete } = action.payload
      let {
        data: newData,
        filter,
        meta: { totalObjectCount = 1 },
      } = state
      if (commentId) {
        if (comment) {
          // Find the comment index to edit and update
          const commentIndex = newData.findIndex(commentData => commentData.id === commentId)
          const newComment = comment
          // Check if replies are loaded for the comment and if data exist add to the new comment
          const { replies } = newData[commentIndex]
          if (replies) {
            const { data } = replies
            if (data) {
              newComment.replies = replies
            }
          }
          newData[commentIndex] = newComment
        } else if (isDelete) {
          const newComments = newData.filter(commentData => commentData.id !== commentId)
          newData = [...newComments]
          totalObjectCount -= 1
          state.meta.totalObjectCount = totalObjectCount
          // Reduce the page count if the total object count goes below the range of current pagination
          if (totalObjectCount <= filter.pageSize * (filter.page - 1)) {
            state.filter = { ...filter, page: filter.page - 1 }
          }
        }
      }
      state.data = [...newData]
    },
    /**
     * Update the comments list as action of posting new comment
     * Add the latest comment posted to the top of the list
     */
    updateComments(state, action: PayloadAction<CommentUpdateImpl>) {
      const { comment } = action.payload
      const { data: newData } = state
      if (comment) newData.unshift(comment)
      state.data = [...newData]
    },

    /**
     * Update the reply of a comment as action of an edit
     */
    updateReply(state, action: PayloadAction<ReplyUpdateImpl>) {
      const { replyId, reply, commentId } = action.payload
      const { data: newData } = state
      if (commentId && reply) {
        // Fins the comment to which the reply belong to
        const commentIndex = newData.findIndex(commentData => commentData.id === commentId)
        const { replies } = newData[commentIndex]
        if (replies) {
          const { data: repliesData } = replies
          // If the reply exist and and its an array
          // find the index of the reply that need to be updated
          if (repliesData && Array.isArray(repliesData)) {
            const replyIndex = repliesData.findIndex(replyData => replyData.id === replyId)
            if (replyIndex >= 0) {
              repliesData[replyIndex] = reply
              newData[commentIndex].replies = { ...replies, data: [...repliesData] }
            }
          }
        }
      }
      state.data = [...newData]
    },
    /**
     * Update the replies of comment as its is not loaded along with comments and when a reply is deleted
     * This include adding replies to the comment after posting a reply to a particular comment
     */
    updateReplies(state, action: PayloadAction<RepliesUpdateImpl>) {
      const { replies, commentId, isDelete, replyId } = action.payload
      const { data } = state
      // Check if its a delete operation if no check it has replies
      if (!isDelete && replies) {
        const comments = data.map(comment => {
          const newComment = Object.assign({ replies: {}, ...comment })
          //Find the comment
          if (newComment.id === commentId) {
            // Take existing comment replies
            const { data: commentReplies = [] } = newComment?.replies
            // Reply/Replies fom the API
            const { data: newReplies, filter } = replies
            //If array add it to the existing ones
            if (Array.isArray(newReplies)) {
              if (filter) filter.page += 1
              newReplies.reverse()
              newComment.replies = { ...replies, filter, data: [...newReplies, ...commentReplies] }
            }
            // Or it will be an object coming from as result of posting a reply. Put the latest to the top of the list
            else {
              newComment.noOfComments += 1
              commentReplies.push(newReplies)
              newComment.replies = { ...newComment.replies, data: commentReplies }
            }
          }
          return newComment
        })
        state.isRepliesLoading = false
        state.data = comments
      }
      // Delete a reply
      else {
        // Find the comment index from the data
        const commentIndex = data.findIndex(commentData => commentData.id === commentId)
        // Find the replies
        const { replies: commentReplies } = data[commentIndex]
        if (commentReplies) {
          const { data: repliesData = [] } = commentReplies
          // Creates new replies data
          const newRepliesData = repliesData.filter(reply => reply.id !== replyId)
          commentReplies.data = newRepliesData
        }
        // Update the comments list
        data[commentIndex].replies = commentReplies
        data[commentIndex].noOfComments -= 1
        state.data = data
      }
    },
  },
})

export const {
  requestDiscusses,
  receivedDiscusses,
  requestedDiscussesSucceeded,
  requestedDiscussesFailed,
  markReplyRequired,
  updatePosts,
} = listSlice.actions

export const {
  requestDiscuss,
  receivedDiscuss,
  requestedDiscussFailed,
  requestedDiscussSucceeded,
  updatePost,
} = detailSlice.actions

export const {
  receivedComments,
  requestComments,
  requestedCommentsFailed,
  requestedCommentsSucceeded,
  requestReplies,
  updateComments,
  updateComment,
  updateReplies,
  updateReply,
} = commentsSlice.actions

const discusses = combineReducers({
  list: listSlice.reducer,
  details: detailSlice.reducer,
  comments: commentsSlice.reducer,
})

export default discusses
