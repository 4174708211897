/*
 * Cookie manipulation library v1.0.0
 */

/**
 * Fetches cookie value from cookies
 * @param {String} name name of cookie
 */
function getCookie(name) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
  return v ? v[2] : null
}

/**
 * Sets a new cookie
 * @param {String} name Name of cookie
 * @param {String} value Value of cookie
 * @param {number} days When should cookie expire
 */
function setCookie(name, value, days) {
  const date = new Date()
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days)
  document.cookie = `${name}=${value};path=/;expires=${date.toGMTString()}`
}

/**
 * Deletes a cookie by setting its expiry date to -1
 * @param {string} name name of cookie
 */
function deleteCookie(name) {
  setCookie(name, '', -1)
}

export { getCookie, setCookie, deleteCookie }
