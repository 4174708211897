import { Vertical } from '../../types/TrackCategory'
import { Filter } from './types'

export const FETCH_ALL_STUDY_PLAN = 'liveclasses/FETCH_ALL_STUDY_PLAN'
export const FETCH_ALL_BATCHES = 'liveclasses/FETCH_ALL_BATCHES'
export const FETCH_LIVE_CLASSES = 'liveclasses/FETCH_LIVE_CLASSES'
export const FETCH_LICENSED_USERS = 'liveclasses/FETCH_LICENSED_USERS';
export const DELETE_LIVE_CLASS = 'liveClasses/DELETE_LIVE_CLASS';
export const FETCH_ALL_PACKS = 'liveclasses/FETCH_ALL_PACKS'

export const FETCH_ALL_LANGUAGES = 'liveclasses/FETCH_ALL_LANGUAGES'
export const FETCH_ALL_FACULTIES = 'liveclasses/FETCH_ALL_FACULTIES';
export const FETCH_SECTION = 'liveclasses/FETCH_SECTION';


export const fetchAllStudyPlans = (vertical: Vertical) => {
  return {
    type: FETCH_ALL_STUDY_PLAN,
    payload: vertical,
  }
}

export const fetchStudyPlanBatches = (studyPlanId: number) => {
  return {
    type: FETCH_ALL_BATCHES,
    payload: studyPlanId,
  }
}

export const fetchAllLiveClasses = (courseVertical: Vertical, filter: Filter, packCategory?: string,) => {
  return {
    type: FETCH_LIVE_CLASSES,
    payload: { courseVertical, filter, packCategory },
  }
}

export const fetchLicensedUsers = () => {
  return {
    type: FETCH_LICENSED_USERS,
  }
}

export const fetchAllElevatePacks = (payload?: { languages?: string, isOldLiveClassCreation?: boolean }) => ({
  type: FETCH_ALL_PACKS,
  payload
})


export const fetchAllLanguages = () => ({
  type: FETCH_ALL_LANGUAGES
})

export const fetchAllFaculties = () => ({ type: FETCH_ALL_FACULTIES })

export const fetchSections = (packId: number | string) => ({
  type: FETCH_SECTION, payload: { packId }
}) 