import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
  NotificationActionImpl,
  NotificationTypes,
} from './types'

export const showSuccessNotification = (description: string): NotificationActionImpl => ({
  type: NOTIFICATION_SUCCESS,
  payload: {
    type: NotificationTypes.notification,
    data: {
      description,
    },
  },
})

export const showErrorNotification = (description: string): NotificationActionImpl => ({
  type: NOTIFICATION_ERROR,
  payload: {
    type: NotificationTypes.notification,
    data: {
      description,
    },
  },
})

export const showSuccessMessage = (message: string, duration?: number): NotificationActionImpl => ({
  type: NOTIFICATION_SUCCESS,
  payload: {
    type: NotificationTypes.message,
    data: {
      message,
      duration,
    },
  },
})

export const showErrorMessage = (message: string, duration?: number): NotificationActionImpl => ({
  type: NOTIFICATION_ERROR,
  payload: {
    type: NotificationTypes.message,
    data: {
      message,
      duration,
    },
  },
})
