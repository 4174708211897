import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { StudyTaskModal as StudyTaskModals } from '../types'
import { RootState } from '../../../../..'

type OpenActivityModal = StudyTaskModals<any>

const initialTaskInterface: OpenActivityModal = {
  open: false,
}

export const elevateTaskInterface = createSlice({
  name: 'elevateCoursesListing',
  initialState: initialTaskInterface,
  reducers: {
    showTaskModal: (state, action: PayloadAction<OpenActivityModal>) => {
      return (state = {
        ...state,
        ...action.payload,
        open: true,
      })
    },
    closeTaskModal: state => {
      return (state = { ...state, ...initialTaskInterface, open: false })
    },
  },
})

export const { showTaskModal, closeTaskModal } = elevateTaskInterface.actions

export const elevateStudyTaskModals = (state: RootState) => state.elevateCourses.modals
