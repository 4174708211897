import React from 'react'
import classNames from 'classnames'
import styles from './style.module.scss'

interface LoaderProps {
  spinning: Boolean
  fullScreen?: Boolean
}

const Loader: React.FC<LoaderProps> = ({ spinning = true, fullScreen }) => (
  <div
    className={classNames(styles.loader, {
      [styles.hidden]: !spinning,
      [styles.fullScreen]: fullScreen,
    })}
  />
)

export default Loader
