import {
  REQUESTED_COMMENTS,
  REQUESTED_COMMENTS_SUCCEEDED,
  REQUESTED_COMMENTS_FAILED,
  RECEIVED_COMMENTS,
  ADD_NEW_COMMENT,
} from './types'

import { CommentsImpl, CommentActionImpl, CommentImpl } from './types'

export const initialState: CommentsImpl = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 50,
    totalRemuneration: null,
  },
  isLoading: false,
  hasError: false,
}

export default function comments(state = initialState, action: CommentActionImpl) {
  switch (action.type) {
    case REQUESTED_COMMENTS:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case REQUESTED_COMMENTS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      }
    case REQUESTED_COMMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case RECEIVED_COMMENTS: {
      const { data, meta } = <CommentsImpl>action.payload
      return {
        ...state,
        data,
        meta,
      }
    }
    case ADD_NEW_COMMENT: {
      const newComment = <CommentImpl>action.payload
      return {
        ...state,
        data: [newComment, ...state.data],
      }
    }
    default:
      return state
  }
}
