import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { Filter, Plans, PlansResponse, PlansStatus } from '../types/Plans'

export const listPlans = (
  filter: Filter,
  productPackageId: number,
): Promise<AxiosResponse<PlansResponse | Plans[]>> => {
  return httpService.get(`/v7/content/sources/plans/`, {
    params: {
      ...filter,
      product_package: productPackageId,
    },
  })
}

export function createPlan(
  productPackageId: number,
  plansDetails: Plans,
): Promise<AxiosResponse<Plans>> {
  return httpService.post(
    `/v7/content/sources/plans/?product_package=${productPackageId}/`,
    plansDetails,
  )
}

export function updatePlan(plansId: number, plansDetails: Plans): Promise<AxiosResponse<Plans>> {
  return httpService.put(`/v7/content/sources/plans/${plansId}/`, plansDetails)
}

export function togglePlanStatus(
  plansId: number,
  plansStatus: PlansStatus,
): Promise<AxiosResponse<Plans>> {
  return httpService.patch(`/v7/content/sources/plans/${plansId}/`, plansStatus)
}

export function getPlan(plansId: number, filter?: Filter): Promise<AxiosResponse<Plans>> {
  return httpService.get(`/v7/content/sources/plans/${plansId}/`, {
    params: {
      ...filter,
    },
  })
}
