import httpService from '../httpService'

/**
 * Creates new comment
 * @param {Object} payload comment object
 */
export function createComment(payload: Object) {
  return httpService.post('/v4/content/sources/comments/', { ...payload })
}

/**
 * Creates a new job
 * @param {Number} jobId Job id
 */
export function listComments(jobId: Number, filter: { page: Number }) {
  return httpService.get(`/v4/content/sources/comments/job/${jobId}/`, {
    params: { ...filter },
  })
}
