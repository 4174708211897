import httpService from '../httpService'
import {
  FilterImpl,
  JobImpl,
  PatchPayloadImpl,
  JobFormImpl,
  QAVideos,
  DataEntryJob,
} from '../redux/jobs/types'
import { JOB_STATUS, VIDEO_QA_JOB_STATUS, VIDEO_QA_QC_STATUS } from '../helper/constants'

/**
 * Returns paginated list of jobs based on filter applied
 * @param {Object} payload filter object
 */
export function listJobs(payload: FilterImpl) {
  return httpService.get('/v4/content/sources/content-jobs/', {
    params: { ...payload },
  })
}

/**
 * Creates a new job
 * @param {Object} payload Job object
 */
export function createJob(payload: JobFormImpl) {
  return httpService.post(`/v4/content/sources/content-jobs/`, payload)
}

/**
 * Creates a new video QA job
 * @param {Object} payload Job object
 */
export function createVideoQAJob(payload: JobFormImpl) {
  return httpService.post(`/v6/content/sources/jobs/`, payload)
}

/**
 * Edits existing job
 * @param {number} id Job id
 * @param {object} payload job object
 */
export function editJob(id: Number, payload: JobFormImpl) {
  return httpService.put(`/v4/content/sources/content-jobs/${id}/`, { ...payload })
}

/**
 * Edits existing job
 * @param {number} id Job id
 * @param {object} payload job object
 */
export function editVideoQAJob(id: Number, payload: JobFormImpl) {
  return httpService.put(`/v6/content/sources/jobs/${id}/`, { ...payload })
}

/**
 * Update video QA parent job status
 * @param {number} id Job id
 * @param {object} payload job object
 */
export function changeVideoQAParentJobStatus(id: Number, payload: any) {
  return httpService.patch(`/v6/content/sources/jobs/${id}/`, { ...payload })
}

/**
 * view single job
 * @param {number} id Job id
 */
export function getJob(id: Number) {
  return httpService.get(`/v4/content/sources/content-jobs/${id}/`)
}

/**
 * view single Video QA job
 * @param {number} id Job id
 */
export function getVideoQAJob(id: Number) {
  return httpService.get(`/v6/content/sources/jobs/${id}/`)
}

/**
 * Changes status of video QA job
 * @param {number} id job id
 * @param {object} payload job object
 */
export function changeVideoQAJobStatus(
  parentJobId: number,
  childJobId: number,
  payload: {
    isStarted?: boolean
    hasResubmitted?: boolean
    isFinished?: boolean
    qcJobId?: number
  },
) {
  return httpService.patch(`/v6/content/sources/jobs/${parentJobId}/videos/${childJobId}/`, {
    ...payload,
  })
}

/**
 * Changes status of job
 * @param {number} id job id
 * @param {object} payload job object
 */
export function changeJobStatus(id: Number, payload: PatchPayloadImpl) {
  return httpService.patch(`/v4/content/sources/content-jobs/${id}/`, { ...payload })
}

/**
 * Fetch all job types
 */
export function getJobTypes() {
  return httpService.get(`/v4/content/sources/content-jobs/job-types/`)
}

/**
 * Returns current status of job
 * @param {Object} job Job data object
 */
export function getJobStatus(job: JobImpl) {
  if (!job) return false
  if (job.isPaid) return JOB_STATUS.PAID
  if (job.isApproved) return JOB_STATUS.APPROVED
  if (job.isFinished) return JOB_STATUS.COMPLETED
  if (job.isStarted) return JOB_STATUS.STARTED
  return JOB_STATUS.OPEN
}

/**
 * Returns current status of job
 * @param {Object} job Job data object
 */
export function getVideoQAJobStatus(job: QAVideos) {
  if (!job) return ''
  if (job.isApproved) return VIDEO_QA_JOB_STATUS.APPROVED
  if (job.hasResubmitted) return VIDEO_QA_JOB_STATUS.RE_SUBMITED
  if (job.isRejected) return VIDEO_QA_JOB_STATUS.REJECTED
  if (job.isStarted) return VIDEO_QA_JOB_STATUS.STARTED
  return ''
}

export function getVideoQAStatusClass(status: string) {
  switch (status) {
    case VIDEO_QA_JOB_STATUS.APPROVED:
      return 'bg-success'
    case VIDEO_QA_JOB_STATUS.RE_SUBMITED:
      return 'bg-warning'
    case VIDEO_QA_JOB_STATUS.STARTED:
      return 'bg-primary'
    case VIDEO_QA_JOB_STATUS.REJECTED:
      return 'bg-danger'
    default:
      return ''
  }
}

/**
 * Returns current status of job
 * @param {Object} job Job data object
 */
export function getQuestionCollectionStatus(job: DataEntryJob) {
  if (!job) return ''
  if (job.isFinished) return VIDEO_QA_QC_STATUS.FINISHED
  if (job.isStarted) return VIDEO_QA_QC_STATUS.STARTED

  return ''
}

export function getQuestionCollectionStatusClass(status: string) {
  switch (status) {
    case VIDEO_QA_QC_STATUS.FINISHED:
      return 'bg-success'
    case VIDEO_QA_JOB_STATUS.STARTED:
      return 'bg-primary'
    default:
      return ''
  }
}

/**
 * Returns status color of job
 * @param {Object} job Job data object
 */
export function getJobStatusColors(job: JobImpl) {
  if (!job) return false
  if (job.isPaid) return 'gold'
  if (job.isApproved) return 'green'
  if (job.isFinished) return 'purple'
  if (job.isStarted) return 'blue'
  return 'geekblue'
}
