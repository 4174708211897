import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { InitalState, ProductPackageQuestions, ProductPackages } from "./types";



const initialState: InitalState = {
    packages: {
        loading: false,
        data: null,
        error: false
    },
    details: {
        loading: false,
        data: null,
        error: false
    }
}


const productPackageSlice = createSlice({
    name: 'productPackage',
    initialState,
    reducers: {
        requestProductPackages: (state, _) => {
            state.packages = {
                loading: true,
                data: null,
                error: false
            }
        },
        receivedProductPackages: (state, action: PayloadAction<ProductPackages>) => {
            state.packages = {
                loading: false,
                data: action.payload,
                error: false
            }
        },
        errorOnProductPackage: (state) => {
            state.packages = {
                loading: false,
                data: null,
                error: true
            }
        },
        requestPackageDetails: (state, _) => {
            state.details = {
                loading: true,
                data: null,
                error: false
            }
        },
        receivedPackageDetails: (state, action: PayloadAction<ProductPackageQuestions>) => {
            state.details = {
                loading: false,
                data: action.payload,
                error: false
            }
        },
        errorOnPackageDetails: (state) => {
            state.details = {
                loading: false,
                data: null,
                error: true
            }
        },
        updateSortOrder: (state, action: PayloadAction<ProductPackageQuestions>) => {
            state.details.data = action.payload
        }
    }
})


export const productPackageSelector = (state: RootState) => state.productPackages.packages;
export const packageDetailsSelector = (state: RootState) => state.productPackages.details
export const {
    requestProductPackages,
    receivedProductPackages,
    errorOnProductPackage,
    requestPackageDetails,
    receivedPackageDetails,
    errorOnPackageDetails,
    updateSortOrder
} = productPackageSlice.actions


export default productPackageSlice.reducer