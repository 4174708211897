import React, { useEffect, useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { Menu, Layout, Dropdown, Avatar, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import LogoutOutlined from '@ant-design/icons/LogoutOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import store from 'store'
import styles from './style.module.scss'
import { changeSetting, selectSettings } from '../../../../redux/settings/settingsSlice'
import getMenuData, { MenuDataImpl } from '../../../../api/menu'
import { selectUser } from '../../../../redux/user/userSlice'
import { logoutUser } from '../../../../redux/user/actions'

import { CollapseType } from 'antd/lib/layout/Sider'
import _ from 'lodash'
import useLongPress from '../../../../hooks/useLongPress'
import ChangeDevChannelModal from '../../ChangeDevChannelModal'

import { checkUserHasViewPermission } from '../../../../helper/checkUserPermission'
import { selectAccessControl } from '../../../../redux/accessControls/slice'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const MenuLeft = () => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    store.get('app.menu.selectedKeys') || [],
  )
  const [openedKeys, setOpenedKeys] = useState<string[]>(store.get('app.menu.openedKeys') || [])
  const [isDevChannelModalOpen, setDevChannelModalOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const settings = useSelector(selectSettings)
  const user = useSelector(selectUser)
  const accessControls = useSelector(selectAccessControl)

  const isStagingEnv = process.env?.REACT_APP_CUSTOM_NODE_ENV == 'staging'

  const onLongPress = () => {
    setDevChannelModalOpen(true)
  }

  const defaultOptions = {
    isPreventDefault: true,
    delay: 2000,
  }

  const longPressEvent = useLongPress(onLongPress, defaultOptions)

  const cancelModal = () => {
    setDevChannelModalOpen(false)
  }

  useEffect(() => {
    if (!history) return
    const findSelectedKey = () => {
      const flattenItems = (items: MenuDataImpl[]): MenuDataImpl[] => {
        return items.reduce((flattenedItems: MenuDataImpl[], item) => {
          flattenedItems.push(item)
          if (Array.isArray(item.children)) {
            return flattenedItems.concat(flattenItems(item.children))
          }
          return flattenedItems
        }, [])
      }
      const selectedItem = _.find(flattenItems(getMenuData()), ['url', history.location.pathname])
      setSelectedKeys(selectedItem ? [selectedItem.key as string] : [])
    }
    findSelectedKey()
  }, [history])

  useEffect(() => {
    if (settings.isMenuCollapsed && !settings.isMobileView) {
      setOpenedKeys([])
    }
  }, [settings])

  const onCollapse = (value: any, type: string) => {
    if (type === 'responsive' && settings.isMenuCollapsed) {
      return
    }
    dispatch(
      changeSetting({
        settings: 'isMenuCollapsed',
        value: !settings.isMenuCollapsed,
      }),
    )
    setOpenedKeys([])
  }

  const onOpenChange = (keys: React.Key[]) => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys as string[])
  }

  const handleClick = (e: { key: React.Key }) => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key as string])
  }

  /**
   * Check if any one of the child has permission
   * @param items MenuData
   * @returns
   */
  const checkSubMenuAccessControl = (items: MenuDataImpl[]) => {
    const childWithAccess = items.some(item => {
      if (item.children) {
        return checkSubMenuAccessControl(item.children)
      }
      const { permissionLevel } = item
      const userHasPermission = checkUserHasViewPermission(
        permissionLevel,
        accessControls?.data?.viewPermissions,
      )
      return userHasPermission
    })
    return childWithAccess
  }

  const generateMenuItems = () => {
    const menuData = getMenuData()

    const generateItem = (item: MenuDataImpl) => {
      const { key, title, url, icon, disabled, permissionLevel } = item
      const userHasPermission = checkUserHasViewPermission(
        permissionLevel,
        accessControls?.data?.viewPermissions,
      )

      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (!userHasPermission) return undefined
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <Link to={url || ''}>
                {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
                <span className={styles.title}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${styles.icon} icon-collapsed-hidden`} />}
          <span className={styles.title}>{title}</span>
        </Menu.Item>
      )
    }

    const generateSubmenu = (items: MenuDataImpl[]) =>
      items.map((menuItem: MenuDataImpl) => {
        if (menuItem.children) {
          const isAnyChildrenHasPermission = checkSubMenuAccessControl(menuItem.children)
          if (!isAnyChildrenHasPermission) return undefined
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      const { key, title, icon, children } = menuItem
      if (children) {
        /**
         * If has children, parent menu act as collapse for submenu.
         * Check if any submenu has a permission to determine whether to show the parent menu.
         */
        const isAnyChildrenHasPermission = checkSubMenuAccessControl(children)
        if (!isAnyChildrenHasPermission) return undefined

        return (
          <Menu.ItemGroup key={key} title={title}>
            {generateSubmenu(children)}
          </Menu.ItemGroup>
        )
      }
      return generateItem(menuItem)
    })
  }
  const menuSettings: {
    width: number
    collapsible: boolean
    collapsed: boolean
    onCollapse: (collapsed: boolean, type: CollapseType) => void
    breakpoint?: 'lg' | 'xs' | 'sm' | 'md' | 'xl' | 'xxl' | undefined
  } = settings.isMobileView
      ? {
        width: 210,
        collapsible: false,
        collapsed: false,
        onCollapse: onCollapse,
      }
      : {
        width: 210,
        collapsible: true,
        collapsed: settings.isMenuCollapsed,
        onCollapse: onCollapse,
        breakpoint: 'lg',
      }

  const menu = generateMenuItems()
  const profileMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => dispatch(logoutUser(true))}
        className="d-flex justify-content-between align-items-center"
      >
        Log out <LogoutOutlined />
      </Menu.Item>
    </Menu>
  )

  return (
    <Sider
      {...menuSettings}
      className={settings.isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
    >
      <div className={styles.logo}>
        <div {...longPressEvent} className={styles.logoContainer}>
          <img
            src={`resources/images/logo-inverse${menuSettings.collapsed ? '-mobile' : ''}.svg`}
            alt="Entri"
          />
          {!settings.isMenuCollapsed && <small> v{process.env.REACT_APP_VERSION}</small>}
        </div>
      </div>
      <Scrollbars
        className={settings.isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide
      >
        <Menu
          theme={settings.isLightTheme ? 'light' : 'dark'}
          onClick={handleClick}
          onOpenChange={onOpenChange}
          mode="inline"
          className={styles.navigation}
          openKeys={openedKeys}
          selectedKeys={selectedKeys}
          style={{ minHeight: '100%' }}
        >
          {menu}
        </Menu>
      </Scrollbars>
      <div style={{ position: 'fixed', bottom: '48px', width: 'inherit' }}>
        <Dropdown overlay={profileMenu} placement="top">
          <div className="d-flex justify-content-center align-items-center py-3 border-top font-weight-bold bg-white text-primary pointer">
            <Avatar shape="square" size="small" icon={<UserOutlined />} />
            {!settings.isMenuCollapsed && <Typography.Text className='ml-3'>{user.username}</Typography.Text>}
          </div>
        </Dropdown>
      </div>

      {isStagingEnv && isDevChannelModalOpen && (
        <ChangeDevChannelModal isModalOpen={isDevChannelModalOpen} cancelModal={cancelModal} />
      )}
    </Sider>
  )
}
export default withRouter(MenuLeft)
