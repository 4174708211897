import { initializeMixPanel } from "./mixPanel";

export const EVENT_PREFIX = "stln"

export function initializeTrackers() {
    //initialize MixPanel
    initializeMixPanel(
        process.env.REACT_APP_MIXPANEL_TOKEN,
        process.env.REACT_APP_CUSTOM_NODE_ENV !== "production"
    );
}
