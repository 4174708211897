const actions = {
  GET_LIST: 'platform/GET_LIST',
  SET_LIST: 'platform/SET_LIST',
  GET_GROUP_LIST: 'platformGroup/GET_LIST',
  SET_GROUP_LIST: 'platformGroup/SET_LIST',
}

export default actions

export const getPlatforms = (filters = {}) => ({
  type: actions.GET_LIST,
  payload: filters,
})

export const getPlatformGroups = languageId => ({
  type: actions.GET_GROUP_LIST,
  payload: {
    languageId,
  },
})
