import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { ModulesListFilter, ModuleListState, ModuleListPayload } from '../../types/Modules'
import { AppThunk, RootState } from '../..'
import { getSubjectModules, listModules, listModulesWithSubject } from '../../api/modules'

export const initialModulesList: ModuleListState = {
    data: [],
    meta: {
        page: 1,
        pageCount: 1,
        limit: 20,
    },
    filter: {
        pageSize: 20,
        page: 1,
    },
    isLoading: false,
    hasError: false,
}

const ModulesListSlice = createSlice({
    name: 'moduleListing',
    initialState: initialModulesList,
    reducers: {
        requestModules(state) {
            return { ...state, isLoading: true, hasError: false }
        },
        requestedModulesFailed(state) {
            return { ...state, isLoading: false, hasError: true }
        },
        receivedModules(state, action: PayloadAction<ModuleListPayload>) {
            const { data, filter, meta } = action.payload
            const newFilter: ModulesListFilter = {
                ...state.filter,
                ...filter,
            }
            return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
        },
    },
})

export const { receivedModules, requestModules, requestedModulesFailed } = ModulesListSlice.actions

// v4 API without pagination
export const fetchAllModules =
    (filter: any): AppThunk =>
        async dispatch => {
            dispatch(requestModules())
            try {
                const response = await listModules(filter)
                if (response) {
                    const data = response.data
                    dispatch(
                        receivedModules({
                            data,
                            filter,
                        }),
                    )
                }
            } catch (error) {
                dispatch(requestedModulesFailed())
            }
        }

// v4 API without pagination
export const fetchModulesWithSubject =
    (filter: any): AppThunk =>
        async dispatch => {
            dispatch(requestModules())
            try {
                const response = await listModulesWithSubject({ ...filter })
                if (response) {
                    const data = response.data
                    dispatch(
                        receivedModules({
                            data,
                            filter,
                        }),
                    )
                }
            } catch (error) {
                dispatch(requestedModulesFailed())
            }
        }

export const fetchSubjectModules =
    (subjectId: number, platformId: number, filter: ModulesListFilter): AppThunk =>
        async dispatch => {
            dispatch(requestModules())
            try {
                const response = await getSubjectModules(subjectId, platformId, filter)
                if (response) {
                    const { results: data, ...meta } = response.data
                    dispatch(
                        receivedModules({
                            data,
                            filter,
                            meta,
                        }),
                    )
                }
            } catch (error) {
                dispatch(requestedModulesFailed())
            }
        }

export const selectModules = (state: RootState) => state.modules.list

export default combineReducers({
    list: ModulesListSlice.reducer,
})
