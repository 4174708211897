export const REQUESTED_COMMENTS = 'comments/REQUESTED'
export const REQUESTED_COMMENTS_FAILED = 'comments/FAILED'
export const REQUESTED_COMMENTS_SUCCEEDED = 'comments/SUCCEEDED'
export const RECEIVED_COMMENTS = 'comments/RECEIVED'
export const FETCH_COMMENTS = 'comments/FETCH'
export const ADD_NEW_COMMENT = 'comment/ADD'
export const CREATE_COMMENT = 'comment/CREATE'

export interface CommentActionImpl {
  type: string
  payload?: CommentsImpl | CommentImpl | Number
}

export interface CommentImpl {
  id: Number
  user: Number
  username: string
  task: Number
  comment: string
  createdAt: string
}
export interface MetaImpl {
  page: number
  pageCount: number
  limit: number
  totalObjectCount?: number
  totalRemuneration: any
}

export interface CommentsImpl {
  data: CommentImpl[]
  meta: MetaImpl
  isLoading?: Boolean
  hasError?: Boolean
}
