import store from 'store'
import {
  RECEIVED_USER,
  RECEIVED_USERS,
  REQUESTED_USER,
  REQUESTED_USERS,
  REQUESTED_USERS_FAILED,
  REQUESTED_USERS_SUCCEEDED,
  REQUESTED_USER_FAILED,
  REQUESTED_USER_SUCCEEDED,
  RESET_USER,
  UserActionImpl,
  UserImpl,
  UsersImpl,
  UserPartialImpl,
} from './types'

const initialState: UserImpl = {
  id: '',
  name: '',
  isVerified: false,
  role: null,
  email: '',
  avatar: '',
  authorized: !!store.get('token'),
  isLoading: false,
  hasError: false,
  permissions: null,
  requestedPath: '',
}

export function user(state = initialState, action: UserActionImpl): UserImpl {
  switch (action.type) {
    case REQUESTED_USER:
      return { ...state, isLoading: true, hasError: false }
    case REQUESTED_USER_SUCCEEDED:
      return { ...state, isLoading: false, hasError: false }
    case REQUESTED_USER_FAILED:
      return { ...state, isLoading: false, hasError: true }
    case RECEIVED_USER: {
      return { ...state, ...(<UserImpl>action.payload) }
    }
    case RESET_USER:
      return initialState
    default:
      return state
  }
}

export const initialUsersState: { data: UsersImpl; isLoading: Boolean } = {
  data: {
    entities: {},
    allIds: [],
    adminIds: [],
    reviewerIds: [],
    specialistIds: [],
    users: [],
  },
  isLoading: false,
}

export function userList(state = initialUsersState, action: UserActionImpl) {
  switch (action.type) {
    case REQUESTED_USERS:
      return { ...state, isLoading: true, hasError: false }
    case REQUESTED_USERS_SUCCEEDED:
      return { ...state, isLoading: false, hasError: false }
    case REQUESTED_USERS_FAILED:
      return { ...state, isLoading: false, hasError: true }
    case RECEIVED_USERS: {
      return { ...state, data: <UsersImpl>action.payload }
    }
    default:
      return state
  }
}

// selectors

/**
 * Checks if user is admin
 * @param {object} state user state object
 * @returns {boolean}
 */
export const amIAdmin = (state: UserImpl): Boolean => state.role === 'admin'

/**
 * Checks if user is academic head
 * @param {object} state user state object
 * @returns {boolean}
 */
export const amIAcademicHead = (state: UserImpl): Boolean => state.role === 'academic-head'

/**
 * returns concerned user object
 * @param state user list state
 * @param id user id
 */
export const selectUser = (state: UsersImpl, id: string): UserPartialImpl => state.entities[id]

/**
 * Checks if user is verified user
 * @param {object} state user state object
 * @returns {boolean}
 */
export const isVerifiedUser = (state: UserImpl): Boolean => state.isVerified
