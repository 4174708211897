import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../..'
import { PackPartial } from '../../types/Packs'
import { Language } from '../languages/types'
import {
  Batch,
  Faculties,
  FacultiesState,
  LanguageState,
  LicensedUser,
  LicensedUserState,
  LiveClasses,
  LiveClassesState,
  PacksState,
  StudyPlan,
  StudyPlanBatchState,
  StudyPlanState,
} from './types'

//Fetching the study plans
const initialState: StudyPlanState = {
  isLoading: false,
  studyplans: [],
  hasError: false,
}
const liveClassesStudyPlanSlice = createSlice({
  name: 'liveClassesStudyplans',
  initialState,
  reducers: {
    requestFetchAllStudyPlan(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedAllStudyPlan(state, action: PayloadAction<StudyPlan[]>) {
      return { ...state, isLoading: false, studyplans: action.payload }
    },
    receiveErrorOnAllStudyPlan(state) {
      return { ...state, isLoading: false, hasError: false }
    },
  },
})

export const { requestFetchAllStudyPlan, receivedAllStudyPlan, receiveErrorOnAllStudyPlan } =
  liveClassesStudyPlanSlice.actions

export const liveClassesStudyPlan = combineReducers({
  studyPlans: liveClassesStudyPlanSlice.reducer,
})

export const allStudyPlansSelector = (state: RootState) => state.liveClassesStudyPlan.studyPlans

//Fetching the batches for live class
const initialBatchState: StudyPlanBatchState = {
  isLoading: false,
  batches: [],
  hasError: false,
}
const liveClassesBatchesSlice = createSlice({
  name: 'liveClassesStudyplansBatches',
  initialState: initialBatchState,
  reducers: {
    requestFetchStudyPlanBatches(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedStudyPlanBatches(state, action: PayloadAction<Batch[]>) {
      return { ...state, isLoading: false, batches: action.payload }
    },
    receiveErrorOnStudyPlanBatches(state) {
      return { ...state, isLoading: false, hasError: false }
    },
    resetBatches(state) {
      return { ...state, batches: [] }
    },
  },
})

export const {
  requestFetchStudyPlanBatches,
  receivedStudyPlanBatches,
  receiveErrorOnStudyPlanBatches,
  resetBatches,
} = liveClassesBatchesSlice.actions

export const liveClassesStudyPlanBatches = combineReducers({
  batches: liveClassesBatchesSlice.reducer,
})

export const studyPlanBatchesSelector = (state: RootState) =>
  state.liveClassesStudyPlanBatches.batches

//Live classes
const initialLiveClassesState: LiveClassesState = {
  isLoading: false,
  liveClasses: null,
  hasError: false,
}
const liveClassesSlice = createSlice({
  name: 'liveClasses',
  initialState: initialLiveClassesState,
  reducers: {
    requestAllLiveClasses(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedAllLiveClasses(state, action: PayloadAction<LiveClasses.RootObject>) {
      return { ...state, isLoading: false, liveClasses: action.payload }
    },
    receiveErrorAllLiveClasses(state) {
      return { ...state, isLoading: false, hasError: false }
    },
  },
})

export const { requestAllLiveClasses, receivedAllLiveClasses, receiveErrorAllLiveClasses } =
  liveClassesSlice.actions

export const liveClasses = combineReducers({
  data: liveClassesSlice.reducer,
})

export const liveClassesSelector = (state: RootState) => state.liveClasses.data

//Licenced Users
const initialLicensedUserState: LicensedUserState = {
  isLoading: false,
  licensedUsers: [],
  hasError: false,
}
const licensedUserSlice = createSlice({
  name: 'licensedUsers',
  initialState: initialLicensedUserState,
  reducers: {
    requestLicensedUsers(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedLicensedUsers(state, action: PayloadAction<LicensedUser[]>) {
      return { ...state, isLoading: false, licensedUsers: action.payload }
    },
    receiveErrorLicensedUsers(state) {
      return { ...state, isLoading: false, hasError: false }
    },
  },
})

export const { requestLicensedUsers, receivedLicensedUsers, receiveErrorLicensedUsers } =
  licensedUserSlice.actions

export const licensedUsers = combineReducers({
  data: licensedUserSlice.reducer,
})

export const licensedUsersSelector = (state: RootState) => state.licensedUsers.data

const initialPackState: PacksState = {
  isLoading: false,
  packs: [],
  hasError: false
}

const allPacksSlice = createSlice({
  name: 'studyplanPacks',
  initialState: initialPackState,
  reducers: {
    requestPacks(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    receivedPacks(state, action: PayloadAction<PackPartial[]>) {
      return { ...state, isLoading: false, packs: action.payload, hasError: false }
    },
    receiveErrorPacks(state) {
      return { ...state, isLoading: false, hasError: true }
    }
  }
})

export const { requestPacks, receivedPacks, receiveErrorPacks } = allPacksSlice.actions;


export const elevatePacksSelector = (state: RootState) => state.elevatePacks.elevatePack

const initialFaculiesSlice: FacultiesState = {
  isLoading: false,
  faculties: null,
  hasError: false
}

const facultiesSlice = createSlice({
  name: 'facultiesSlice',
  initialState: initialFaculiesSlice,
  reducers: {
    requestFaculties: (state) => {
      return { ...state, isLoading: true }
    },
    receivedFaculties: (state, action: PayloadAction<Faculties>) => {
      return {
        ...state,
        isLoading: false,
        faculties: action.payload,
        hasError: false
      }
    },
    receivedErrorFaculties: (state) => {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        faculties: null
      }
    }
  }
})

export const { requestFaculties, receivedFaculties, receivedErrorFaculties } = facultiesSlice.actions;

export const facultiesSelector = (state: RootState) => state.elevatePacks.faculties;

const initalLanguageState: LanguageState = {
  isLoading: false,
  languages: null,
  hasError: false
};

const languageSlice = createSlice({
  name: 'languageSlice',
  initialState: initalLanguageState,
  reducers: {
    requestLanguages: (state) => {
      return {
        ...state,
        isLoading: true,
        languages: null,
        hasError: false
      }
    },
    receivedLanguages: (state, action: PayloadAction<Language[]>) => {
      return {
        ...state,
        isLoading: false,
        languages: action.payload,
        hasError: false
      }
    },
    receivedErrorLanguages: (state) => {
      return {
        ...state,
        isLoading: false,
        languages: null,
        hasError: true
      }
    }
  }
})

export const { requestLanguages, receivedLanguages, receivedErrorLanguages } = languageSlice.actions;
export const languageSelector = (state: RootState) => state.elevatePacks.languages;


export const elevatePacks = combineReducers({
  elevatePack: allPacksSlice.reducer,
  faculties: facultiesSlice.reducer,
  languages: languageSlice.reducer,
})