import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  Filter,
  PlatformListState,
  PlatformListPayload,
  Platform,
  PlatformsResponse,
} from '../../types/Platforms'
import { AppThunk, RootState } from '../..'
import { getPlatforms } from '../../api/platform'

export const initialPlatformsList: PlatformListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const PlatformsListSlice = createSlice({
  name: 'platformListing',
  initialState: initialPlatformsList,
  reducers: {
    requestPlatforms(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedPlatformsFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedPlatforms(state, action: PayloadAction<PlatformListPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: Filter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
    resetPlatforms() {
      return initialPlatformsList
    },
  },
})

export const { receivedPlatforms, requestPlatforms, requestedPlatformsFailed, resetPlatforms } =
  PlatformsListSlice.actions

function instanceOfPlatformListResponse(object: any): object is PlatformsResponse {
  return 'results' in object
}

export const fetchPlatforms =
  (filter: Filter): AppThunk =>
  async dispatch => {
    dispatch(requestPlatforms())
    try {
      const response = await getPlatforms(filter)
      if (response) {
        if (instanceOfPlatformListResponse(response.data)) {
          const { results: data, ...meta } = response.data
          dispatch(
            receivedPlatforms({
              data,
              filter,
              meta,
            }),
          )
        } else {
          const data = response.data
          dispatch(
            receivedPlatforms({
              data,
              filter,
            }),
          )
        }
      }
    } catch (error) {
      dispatch(requestedPlatformsFailed())
    }
  }

export const selectPlatforms = (state: RootState) => state.platforms.list

export default combineReducers({
  list: PlatformsListSlice.reducer,
})
