import { ThemeConfig } from 'antd'

const defaultTheme: ThemeConfig = {
  token: {
    colorPrimary: '#59a1c9',
    borderRadius: 2,
    fontSize: 13,
    fontFamily: `"Rubik", sans-serif`,
  },
  components: {
    Button: {
      controlOutlineWidth: 1,
    },
    FloatButton: {
      colorBgElevated: '#d2d9e5',
      colorText: '#fff',
      borderRadiusLG: 5,
      controlHeightLG: 40,
      fontSizeIcon: 16,
    },
    Tag: {
      fontSizeSM: 12,
    },
    Menu: {
      itemMarginInline: 0,
      activeBarBorderWidth: 3,
      activeBarWidth: 3,
    },
    Table: {
      rowHoverBg: 'none !important'
    },
  },
}

export default defaultTheme
