import marked from 'marked'
import showdown from 'showdown'

/**
 * when markdown string with latex is directly converted to html
 * the latex syntax `\(\)` gets escaped and breaks the rendering
 * so this function replaces the `\` with `\\` and then replaces the
 * latex portion with a placeholder which is then replaced back after converting
 * to html
 *
 * @param {string} mdContent markdown string
 * @returns {HTML}
 */
export default function renderLatexHTML(mdContent) {
  if (!mdContent) return ''
  // replacing \ to \\ in latex string to properly escape characters
  let stringContent = mdContent.replace(/\\/g, '\\\\')
  // regex for matching latex
  const latexRegex = /\\\(.+?\\\)/g
  // generate matches array
  const latexMatches = stringContent.match(latexRegex)

  if (latexMatches) {
    // replace matched latex with unique placeholders
    for (let i = 0; i < latexMatches.length; i += 1) {
      latexMatches[i] = `\\${latexMatches[i]}`
      stringContent = stringContent.replace(latexMatches[i], `%%${i}%%`)
      latexMatches[i] = latexMatches[i].replace(/\\\\/g, '\\')
    }
  }
  // convert markdown to html
  let content = marked(stringContent).replace(/&quot;/g, '')
  if (latexMatches) {
    // replace back latex
    for (let i = 0; i < latexMatches.length; i += 1) {
      content = content.replace(`%%${i}%%`, latexMatches[i])
    }
  }
  return content
}

/**
 * Converts passed markdown to html
 * @param {string} mdString md string to be converted to markdown
 */
export function md2HTML(mdString) {
  if (!mdString) return undefined
  const converter = new showdown.Converter({ tables: true, strikethrough: true })
  return converter.makeHtml(mdString)
}

/**
 * Converts passed html to markdown
 * @param {string} htmlString html string to be converted to markdown
 */
export function HTML2md(htmlString) {
  if (!htmlString) return undefined
  const converter = new showdown.Converter()
  return converter.makeMarkdown(htmlString)
}
