import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { AppThunk, RootState } from '../../..'
import { listProductPackages } from '../../../api/pricingProductPackages'
import {
  Filter,
  ProductPackage,
  ProductPackageListPayload,
  ProductPackageListState,
  ProductPackagesResponse,
} from '../../../types/ProductPackage'

export const initialProductPackageList: ProductPackageListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const ProductPackageListSlice = createSlice({
  name: 'productPackageListing',
  initialState: initialProductPackageList,
  reducers: {
    requestProductPackages(state: ProductPackageListState) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedProductPackagesFailed(state: ProductPackageListState) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedProductPackages(
      state: ProductPackageListState,
      action: PayloadAction<ProductPackageListPayload>,
    ) {
      const { data, filter, meta } = action.payload
      const newFilter: Filter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
    resetProductPackages() {
      return initialProductPackageList
    },
  },
})

export const {
  receivedProductPackages,
  requestProductPackages,
  requestedProductPackagesFailed,
  resetProductPackages,
} = ProductPackageListSlice.actions

function instanceOfProductPackageListResponse(
  object: ProductPackagesResponse | ProductPackage[],
): object is ProductPackagesResponse {
  return 'results' in object
}

export const fetchProductPackages =
  (filter: Filter): AppThunk =>
  async dispatch => {
    dispatch(requestProductPackages())
    try {
      const response = await listProductPackages(filter)
      if (response) {
        if (instanceOfProductPackageListResponse(response.data)) {
          const { results: data, ...meta } = response.data
          dispatch(
            receivedProductPackages({
              data,
              filter,
              meta,
            }),
          )
        } else {
          const data = response.data
          dispatch(
            receivedProductPackages({
              data,
              filter,
            }),
          )
        }
      }
    } catch (error) {
      dispatch(requestedProductPackagesFailed())
    }
  }

export const selectProductPackages = (state: RootState) => state.pricingProductPackages.list

export default combineReducers({
  list: ProductPackageListSlice.reducer,
})
