import { Search } from 'history'
import qs from 'qs'
import { history } from '../index'
import { initialValueConverter } from './initialValueConverter'

/**
 * deserializes params from url
 * @param {string} urlParams url encoded params
 */
export function getParamsFromUrl(urlParams: Search, disableConvertion?: boolean) {
  const params = qs.parse(urlParams, { ignoreQueryPrefix: true, strictNullHandling: true })
  const convertedParams: { [key: string]: any } = {}
  Object.keys(params).forEach(key => {
    convertedParams[key] = !disableConvertion ? initialValueConverter(params[key]) : params[key]
  })
  return convertedParams
}

/**
 * Pushes data to url
 * @param {object} newParams data to be pushed to url
 */
export function pushParamsToUrl(newParams: object) {
  history.replace(`?${qs.stringify(newParams)}`)
}

/**
 * go back to previous page or root page
 * if there isn't a previous page
 */
export function historyBack() {
  if (window.history && window.history.length === 1) {
    history.push('/')
  } else {
    history.goBack()
  }
}

// convert %20 as space from URL query param
export function decodeQueryParam(query: string) {
  return decodeURIComponent(query.replace(/%20/g, ' '))
}

export function convertObjectToParams(queryObject: { [key: string]: any }) {
  return new URLSearchParams(queryObject).toString()
}
