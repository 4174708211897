import { push, goBack } from 'connected-react-router'
import { message } from 'antd'
import { all, takeEvery, put, call, takeLatest, select } from 'redux-saga/effects'
import qs from 'qs'
import { history } from 'index'
import {
  createQuestionCollection,
  getQuestionCollections,
  editQuestionCollection,
  getQuestionCollection,
} from 'api/questionCollection'
import { showSuccessNotification, showErrorNotification } from 'redux/notifications/actions'
import actions, {
  FETCH_COLLECTION,
  receivedCollection,
  requestCollection,
  requestCollectionSuccess,
  requestCollectionError,
  AH_COLLECTION_APPROVE,
  AH_COLLECTION_REJECT,
  getCollection,
} from './actions'
import { changeQuestionCollectionStatus } from '../../api/questionCollection'
import { showSuccessMessage, showErrorMessage } from '../notifications/actions'
import { stringToBool } from '../../helper/misc'

/**
 * Invoked when Create Question collection is dispatched
 * @param {object} payload  containing action and payload <Question collection object>
 */
export function* CREATE_QUESTION_COLLECTION({ payload }) {
  try {
    const response = yield call(createQuestionCollection, { ...payload })
    if (response) {
      yield put(showSuccessNotification('Successfully created question collection'))
      yield put(push(`/questioncollections/`))
    }
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while creating question collection'))
  }
}

/**
 * Invoked when Update Question collection is dispatched
 * @param {object} payload  containing action and payload <Question collection object>
 */
export function* UPDATE_QUESTION_COLLECTION({ payload }) {
  try {
    const response = yield call(editQuestionCollection, payload.id, { ...payload.data })
    if (response) {
      yield put(showSuccessNotification('Successfully updated question collection'))
      yield put(goBack())
    }
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while updating question collection'))
  }
}

/**
 * Invoked when Get Question Collection is dispatched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* GET_QUESTION_COLLECTION_LIST({ payload }) {
  try {
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: true,
      },
    })
    const response = yield call(getQuestionCollections, { ...payload })
    yield put({
      type: actions.SET_LIST,
      payload: {
        data: { ...response.data },
        isLoading: false,
      },
    })
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching Question collections'))
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: false,
      },
    })
  }
}

/**
 * Invoked when new filter is dispatched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* SAVE_FILTER({ payload }) {
  const newFilter =
    payload || qs.parse(history.location.search, { ignoreQueryPrefix: true, skipNulls: true })

  // takes care of converting string boolean to boolean
  if (typeof newFilter.mockPull !== 'boolean') {
    newFilter.mockPull = stringToBool(newFilter.mockPull)
  }
  yield put({
    type: actions.SET_FILTER,
    payload: newFilter,
  })
  const filter = yield select(state => state.collection.list.filter)
  history.replace(`?${qs.stringify(filter)}`)
  yield put({
    type: actions.GET_LIST,
    payload: {
      ...filter,
    },
  })
}

/**
 * Invoked when a single question needs to be fetched
 * @param {Object} action action object with payload
 */
export function* GET_COLLECTION({ payload }) {
  try {
    yield put(requestCollection())
    const response = yield call(getQuestionCollection, payload.id)
    yield put(receivedCollection(response.data))
    yield put(requestCollectionSuccess())
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching collection'))
    yield put(requestCollectionError())
  }
}

export function* watchCollectionStatusChange({ type, payload }) {
  const { jobId, qcId, isApproved } = payload
  const hideMessage = message.loading('Updating...', 0)
  try {
    if (type === AH_COLLECTION_APPROVE) {
      yield call(changeQuestionCollectionStatus, jobId, qcId, isApproved)
    } else if (type === AH_COLLECTION_REJECT) {
      yield call(changeQuestionCollectionStatus, jobId, qcId, isApproved)
    }
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
    yield put(getCollection(payload.qcId))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_ITEM, CREATE_QUESTION_COLLECTION),
    takeEvery(actions.UPDATE_ITEM, UPDATE_QUESTION_COLLECTION),
    takeLatest(actions.GET_LIST, GET_QUESTION_COLLECTION_LIST),
    takeLatest(actions.SAVE_FILTER, SAVE_FILTER),
    takeEvery(FETCH_COLLECTION, GET_COLLECTION),
    takeEvery(AH_COLLECTION_APPROVE, watchCollectionStatusChange),
    takeEvery(AH_COLLECTION_REJECT, watchCollectionStatusChange),
  ])
}
