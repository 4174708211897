import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  TestSeriesPacksListingFilter,
  TestSeriesListingFilter,
  SelectedTestSeriesPack,
  SelectedTestSeries,
} from '../../types/exams'

// test series pack related slice start from here
export const initialTestSeriesPackFilter: TestSeriesPacksListingFilter = {
  pageSize: 20,
  page: 1,
}

const testSeriesPacksFilterSlice = createSlice({
  name: 'testSeriesPackFilter',
  initialState: initialTestSeriesPackFilter,
  reducers: {
    updateTestSeriesPackFilter(state, action: PayloadAction<TestSeriesPacksListingFilter>) {
      const selectedFilters = action.payload
      return { ...state, ...selectedFilters }
    },
  },
})

export const { updateTestSeriesPackFilter } = testSeriesPacksFilterSlice.actions

export const initialSelectedTestSeriesPack: SelectedTestSeriesPack = {
  name: '',
  category: '',
  platforms: [],
}

const selectedTestSeriesPackSlice = createSlice({
  name: 'selectedTestSeriesPack',
  initialState: initialSelectedTestSeriesPack,
  reducers: {
    updateSelectedTestSeriesPack(state, action: PayloadAction<SelectedTestSeriesPack>) {
      const selectedPack = action.payload
      return { ...state, ...selectedPack }
    },
  },
})

export const { updateSelectedTestSeriesPack } = selectedTestSeriesPackSlice.actions

export const testSeriesPacks = combineReducers({
  selectedFilters: testSeriesPacksFilterSlice.reducer,
  selectedTestSeriesPack: selectedTestSeriesPackSlice.reducer,
})

// test series pack related slice start from here
export const initialTestSeriesFilter: TestSeriesListingFilter = {
  pageSize: 20,
  page: 1,
}

const testSeriesFilterSlice = createSlice({
  name: 'testSeriesFilter',
  initialState: initialTestSeriesFilter,
  reducers: {
    updateTestSeriesFilter(state, action: PayloadAction<TestSeriesListingFilter>) {
      const selectedFilters = action.payload
      return { ...state, ...selectedFilters }
    },
  },
})

export const { updateTestSeriesFilter } = testSeriesFilterSlice.actions

export const initialSelectedTestSeries: SelectedTestSeries = {
  name: '',
}

const selectedTestSeriesSlice = createSlice({
  name: 'selectedTestSeries',
  initialState: initialSelectedTestSeries,
  reducers: {
    updateSelectedTestSeries(state, action: PayloadAction<SelectedTestSeries>) {
      const selectedSeries = action.payload
      return { ...state, ...selectedSeries }
    },
  },
})

export const { updateSelectedTestSeries } = selectedTestSeriesSlice.actions

export const testSeries = combineReducers({
  selectedFilters: testSeriesFilterSlice.reducer,
  selectedTestSeries: selectedTestSeriesSlice.reducer,
})
