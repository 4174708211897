import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { getSlackUsers } from '../../api/slackUsers'
import { SlackUser, SlackUserFilter, SlackUsersResponse, SlackUserListState, SlackUserListPayload } from './types'
import { act } from 'react-dom/test-utils'
import { AppThunk, RootState } from '../..'
import { pushParamsToUrl } from '../../helper/urlParamSerializer'

export const initialState: SlackUserListState = {
    data: [],
    meta: {
        page: 1,
        pageCount: 1,
        limit: 20,
    },
    filter: {
        pageSize: 20,
        page: 1,
    },
    isLoading: false,
    hasError: false,
}

const slackUsersSlice = createSlice({
    name: 'slackUsersNew',
    initialState,
    reducers: {
        requestUsers(state) {
            return { ...state, isLoading: true, hasError: false }
        },
        requestUsersFailed(state) {
            return { ...state, isLoading: false, hasError: true }
        },
        receivedUsers(state, action: PayloadAction<SlackUserListPayload>) {
            const { data, filter, meta } = action.payload

            const newFilter: SlackUserFilter = {
                ...state.filter,
                ...filter,
            }
            return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
        }
    }
})

export const { receivedUsers, requestUsersFailed, requestUsers } = slackUsersSlice.actions

export const fetchUsers = (filter?: SlackUserFilter): AppThunk => async dispatch => {
    dispatch(requestUsers())
    try {
        const response = await getSlackUsers(filter)
        if (response) {
            filter && pushParamsToUrl(filter)
            const { users: data, ...meta } = response.data
            dispatch(receivedUsers({
                data,
                filter,
            }))
        }
    } catch (error) {
        dispatch(requestUsersFailed())
    }
}
export const selectUsers = (state: RootState) => state.slackUsers.list
export default combineReducers({
    list: slackUsersSlice.reducer
})