import { all, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { listModules, listModulesWithSubject } from 'api/modules'
import actions from './actions'

/**
 * Invoked when Get Subjects is dispatched
 */
export function* GET_MODULES({ payload }) {
  try {
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: true,
      },
    })
    const response = yield call(listModules, { ...payload })
    yield put({
      type: actions.SET_LIST,
      payload: {
        data: [...response.data],
        isLoading: false,
      },
    })
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong while fetching modules',
    })
  }
}

/**
 * Invoked when Get Subjects is dispatched
 */
export function* GET_MODULES_WITH_SUBJECT({ payload }) {
  try {
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: true,
      },
    })
    const response = yield call(listModulesWithSubject, { ...payload })
    yield put({
      type: actions.SET_LIST,
      payload: {
        data: [...response.data],
        isLoading: false,
      },
    })
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong while fetching modules',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LIST, GET_MODULES),
    takeLatest(actions.GET_LIST_WITH_SUBJECT, GET_MODULES_WITH_SUBJECT),
  ])
}
