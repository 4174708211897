import { all, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import getTopics from 'api/topics'
import actions from './actions'

/**
 * Invoked when Get Subjects is dispatched
 */
export function* GET_TOPICS({ payload }) {
  try {
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: true,
      },
    })
    const response = yield call(getTopics, { ...payload })
    yield put({
      type: actions.SET_LIST,
      payload: {
        data: [...response.data],
        isLoading: false,
      },
    })
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong while fetching topics',
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_LIST, GET_TOPICS)])
}
