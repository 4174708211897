import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { AppThunk, RootState } from '../..'
import getLanguages from '../../api/languages'
import { Language, Languages } from './types'

const initialState: Languages = {
  data: [],
  codeByObject: {},
  isLoading: false,
}

const languagesSlice = createSlice({
  name: 'languagesNew',
  initialState,
  reducers: {
    requestLanguage(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestLanguageFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedLanguages(state, action: PayloadAction<Language[]>) {
      const languages = action.payload
      const codeByObject = keyBy(languages, 'code')

      return { ...state, data: languages, codeByObject, isLoading: false, hasError: false }
    },
  },
})

export const { requestLanguage, receivedLanguages, requestLanguageFailed } = languagesSlice.actions

export const fetchLanguages = (): AppThunk => async dispatch => {
  dispatch(requestLanguage())
  try {
    const response = await getLanguages()
    if (response) {
      const data = response.data
      dispatch(receivedLanguages(data))
    }
  } catch (error) {
    dispatch(requestLanguageFailed())
  }
}

export const selectLanguages = (state: RootState) => state.reportedQuestions

export const selectLanguagesV2 = (state: RootState) => state.languagesV2

export default languagesSlice.reducer
