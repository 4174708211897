import { AvailableDSPPCategory, PackTabs as PackTabsType } from '../types/Common'
import { QuestionType } from '../redux/goldQuestions/types'
import { ContentCategory, SkillingPack } from '../types/Skilling'

/**
 * Rating metric
 */
export const RATING_LEVELS = ['Very poor', 'Poor', 'Satisfactory', 'Good', 'Excellent']
/**
 * Different states of in a job cycle
 */
export const JOB_STATUS = {
  OPEN: 'OPEN',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  PAID: 'PAID',
}
/**
 * Hard-coded language codes
 */
export const LANGUAGE_CODES = ['en', 'ml', 'ta', 'te', 'kn', 'hi'] // TODO remove all uses of this in the project

/**
 * Base Interface modes
 */
export const BASE_MODES = {
  create: 'create',
  edit: 'edit',
}

/**
 * Question interface modes
 */
export const QUESTION_MODES = {
  ...BASE_MODES,
  translate: 'translate',
}

export const QUESTION_TYPES = {
  compounded: 'compounded',
  normal: 'normal',
}

export const QUESTION_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  IN_APPEAL: 'IN_APPEAL',
  PENDING: 'PENDING',
  REVIEW_APPROVED: 'REVIEW_APPROVED',
}

/**
 * Base editor config
 */
export const EDITOR_CONFIG = {
  options: ['inline', 'blockType', 'list'],
  inline: {
    className: 'e-inline-buttons',
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H3', 'H4', 'H5', 'H6'],
  },
  list: {
    className: 'e-list-buttons',
    options: ['unordered', 'ordered'],
  },
}

export const MDE_EDITOR_OPTIONS = {
  autofocus: false,
  spellChecker: false,
  status: false,
  toolbar: ['bold', 'italic', 'heading', 'strikethrough', '|', 'unordered-list', 'ordered-list'],
}

export const DISCUSS_REPLY_STATUS = [
  {
    name: 'Replied',
    value: 'REPLIED',
  },
  {
    name: 'Unanswered',
    value: 'NOT_REPLIED',
  },
  {
    name: 'Reply not required',
    value: 'REPLY_NOT_REQUIRED',
  },
]

export const COMMENTS_ORDER_OPTIONS = [
  {
    name: 'Ascending',
    value: 'ASC',
  },
  {
    name: 'Descending',
    value: 'DESC',
  },
]

/**
 * Status of video QA Job
 */
export const VIDEO_QA_JOB_STATUS = {
  STARTED: 'STARTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  RE_SUBMITED: 'RE-SUBMITED',
}

/**
 * Status of video QA Question collection
 */
export const VIDEO_QA_QC_STATUS = {
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
}

/**
 * Video pack types
 */
export const videoPackTypes = ['NORMAL_VIDEOS', 'TOPIC_VIDEOS']

/**
 * Video pack title name
 */
export const videoPackTitleName = {
  NORMAL_VIDEOS: 'Normal Video Pack',
  TOPIC_VIDEOS: 'Topic Wise Video Pack',
}
/**
 * Video pack image dimensions
 */
export const videoPackImageDimensions = {
  landingPageBannerImage: {
    width: 640,
    height: 360,
  },
  portraitThumbnail: {
    width: 500,
    height: 750,
  },
}
/**
 * Marking Schemes for Question
 */
export const markingSchemes = [
  {
    id: 1,
    name: 'Default MCQ',
    value: {
      correctAnswerScore: 1,
      wrongAnswerScore: 0.33,
    },
  },
  {
    id: 2,
    name: 'MCQ',
    value: {
      correctAnswerScore: 2,
      wrongAnswerScore: 0.66,
    },
  },
  {
    id: 3,
    name: 'MCQ',
    value: {
      correctAnswerScore: 1,
      wrongAnswerScore: 0.25,
    },
  },
  {
    id: 4,
    name: 'MCQ',
    value: {
      correctAnswerScore: 2,
      wrongAnswerScore: 0.5,
    },
  },
  {
    id: 5,
    name: 'MCQ',
    value: {
      correctAnswerScore: 3,
      wrongAnswerScore: 0.75,
    },
  },
  {
    id: 6,
    name: 'MCQ',
    value: {
      correctAnswerScore: 3,
      wrongAnswerScore: 1,
    },
  },
  {
    id: 7,
    name: 'NAT/MCA',
    value: {
      correctAnswerScore: 1,
      wrongAnswerScore: 0,
    },
  },
  {
    id: 8,
    name: 'NAT/MCA',
    value: {
      correctAnswerScore: 2,
      wrongAnswerScore: 0,
    },
  },
]

export const paginationPageSizeOptions = ['10', '20', '30', '40', '50']

export const QUESTION_STATEMENT_MAX_LENGTH = 280

export const PackTabs: { [key: string]: PackTabsType } = {
  lessons: 'LESSONS',
  extras: 'EXTRAS',
  more: 'MORE',
}

export const platformQualification = [
  { label: 'Below 10th', value: 'BELOW-10TH' },
  { label: '10th', value: '10TH' },
  { label: '12th', value: '12TH' },
  { label: 'Graduate', value: 'GRADUATE' },
  { label: 'Post-Graduate', value: 'POST-GRADUATE' },
]

export const skillingPackCourseOfferings = [
  { label: 'Video class', value: 'VIDEO-CLASS', imageName: 'icon-pack-video.svg' },
  { label: 'Study cards', value: 'REVISION-NOTE', imageName: 'icon-pack-studycards.svg' },
  { label: 'PDF', value: 'PDF', imageName: 'icon-pack-pdf.svg' },
  { label: 'Quiz', value: 'QUIZ', imageName: 'icon-pack-quiz.svg' },
  { label: 'Certificate', value: 'CERTIFICATE', imageName: 'icon-pack-certificate.svg' },
]

export const skillingPackCourseOfferingsIcons: { [key: string]: string } = {
  'VIDEO-CLASS': 'icon-pack-video.svg',
  'REVISION-NOTE': 'icon-pack-studycards.svg',
  PDF: 'icon-pack-pdf.svg',
  QUIZ: 'icon-pack-quiz.svg',
  CERTIFICATE: 'icon-pack-certificate.svg',
}

export const contentCategories: { label: string; value: ContentCategory }[] = [
  {
    label: 'Video',
    value: 'VIDEO',
  },
  {
    label: 'Quiz',
    value: 'QUIZ',
  },
  {
    label: 'Studycards',
    value: 'CARDS_STACK',
  },
  {
    label: 'Notes',
    value: 'PDF',
  },
]
export const contentCategoriesMap = {
  VIDEO: 'Video',
  QUIZ: 'Quiz',
  CARDS_STACK: 'Studycards',
  PDF: 'Notes',
  OTHER: 'Other',
  LIVE_CLASS: 'Live Class'
}
export const skillingContentNameMap: { [key: string]: string } = {
  VIDEO: 'Video',
  QUIZ: 'Quiz',
  CARDS_STACK: 'Studycard',
}

export const skillingSectionsNameMap: { [key: string]: string } = {
  VIDEO: 'Videos',
  QUIZ: 'Quizzes',
  CARDS_STACK: 'Studycards',
  PDF: 'Notes',
}

export const devChannelsAvailable = [
  { label: 'https://channel1.dev.entri.app', value: 'CHL-1' },
  { label: 'https://channel2.dev.entri.app', value: 'CH-2' },
  { label: 'https://channel3.dev.entri.app', value: 'CH-3' },
  { label: 'https://channel4.dev.entri.app', value: 'CH-4' },
  { label: 'https://channel5.dev.entri.app', value: 'CH-5' },
  { label: 'https://channel6.dev.entri.app', value: 'CH-6' },
  { label: 'https://channel7.dev.entri.app', value: 'CH-7' },
  { label: 'https://channel8.dev.entri.app', value: 'CH-8' },
  { label: 'https://channel9.dev.entri.app', value: 'CH-9' },
  { label: 'https://channel10.dev.entri.app', value: 'CH-10' },
  { label: 'https://channel11.dev.entri.app', value: 'CH-11' },
  { label: 'https://channel12.dev.entri.app', value: 'CH-12' },
  { label: 'https://entri.app', value: 'PRODUCTION' },
]
export const CONTENT_CATEGORIES = ['VIDEO', 'CARDS_STACK', 'QUIZ', 'PDF']

export const questionTypes: {
  value: QuestionType
  title: string
}[] = [
    {
      value: 'PHONE_NUMBER',
      title: 'Phone Number',
    },
    {
      value: 'LONG_ANSWER',
      title: 'Long Answer',
    },
    {
      value: 'SINGLE_CHOICE',
      title: 'Single Choice',
    },
    {
      value: 'MULTI_CHOICE',
      title: 'Multiple Choice',
    },
  ]

export const QuestionModes = {
  create: 'create',
  edit: 'edit',
}

export const verticals = [
  { label: 'TestPrep', value: 'TestPrep' },
  { label: 'Skilling', value: 'Skilling' },
]

export const productPackageVerticals = [
  { label: 'TESTPREP', value: 'TESTPREP', class: 'SINGLE' },
  { label: 'SKILLING', value: 'SKILLING', class: 'SINGLE' },
  { label: 'TESTPREP', value: 'TESTPREP', class: 'COMBO' },
  { label: 'SKILLING', value: 'SKILLING', class: 'COMBO' },
]

export const productType = [
  { label: 'REGULAR', value: 'REGULAR', class: 'SINGLE' },
  { label: 'GOLD', value: 'GOLD', class: 'SINGLE' },
  { label: 'SUPER GOLD', value: 'SUPER_GOLD', class: 'SINGLE' },
  { label: 'REGULAR', value: 'REGULAR', class: 'COMBO' },
]
export const productCategoryType = [
  { label: 'REGULAR', value: 'REGULAR' },
  { label: 'GOLD', value: 'GOLD' },
  { label: 'SUPER GOLD', value: 'SUPER_GOLD' },
  { label: 'ELEVATE', value: 'ELEVATE' },
]

export const productClass = [
  { label: 'SINGLE', value: 'SINGLE' },
  { label: 'COMBO', value: 'COMBO' },
]

export const productCategory = [
  { label: 'TRACK', value: 'TRACK', vertical: 'TESTPREP', class: 'REGULAR' },
  { label: 'PLATFORM', value: 'PLATFORM', vertical: 'TESTPREP', class: 'GOLD' },
  { label: 'PLATFORM', value: 'PLATFORM', vertical: 'TESTPREP', class: 'SUPER_GOLD' },
  { label: 'PLATFORM', value: 'PLATFORM', vertical: 'TESTPREP', class: 'REGULAR' },
  { label: 'PACK', value: 'PACK', vertical: 'SKILLING', class: 'REGULAR' },
  { label: 'TRACK', value: 'TRACK', vertical: 'BOTH', class: 'REGULAR' },
  { label: 'PLATFORM', value: 'PLATFORM', vertical: 'BOTH', class: 'REGULAR' },
  { label: 'PACK', value: 'PACK', vertical: 'BOTH', class: 'REGULAR' },
  { label: 'TRACK', value: 'TRACK', vertical: 'TESTPREP', class: 'GOLD' },
  { label: 'TRACK', value: 'TRACK', vertical: 'TESTPREP', class: 'SUPER_GOLD' },
  { label: 'PACK', value: 'PACK', vertical: 'SKILLING', class: 'GOLD' },
  { label: 'PACK', value: 'PACK', vertical: 'SKILLING', class: 'SUPER_GOLD' },
]

export const premiumFeatures = [
  { label: 'Video class', value: 'VIDEO-CLASS', imageName: 'icon-pack-video.svg' },
  { label: 'Study cards', value: 'REVISION-NOTE', imageName: 'icon-pack-studycards.svg' },
  { label: 'PDF', value: 'PDF', imageName: 'icon-pack-pdf.svg' },
  { label: 'Quiz', value: 'QUIZ', imageName: 'icon-pack-quiz.svg' },
  { label: 'Certificate', value: 'CERTIFICATE', imageName: 'icon-pack-certificate.svg' },
]

export const elevateCourseOfferings = [
  ...skillingPackCourseOfferings,
  { label: 'Live Class', value: 'LIVE_CLASS', imageName: 'icon-live-class.svg' },

]


export enum DSPPCategoryKey {
  LIVE_ONLY = 'LIVE_ONLY',
  NORMAL = 'NORMAL',
}

export const availableDSPPCategories: Array<AvailableDSPPCategory> = [
  {
    id: 1,
    key: DSPPCategoryKey.NORMAL,
    name: 'NORMAL',
  },
  {
    id: 2,
    key: DSPPCategoryKey.LIVE_ONLY,
    name: 'LIVE ONLY',
  },
]
