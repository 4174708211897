import { VideoPlaylistsFilters } from './types'

export const FETCH_TEACHERS = 'lookups/FETCH_TEACHERS'
export const FETCH_EDITORS = 'lookups/FETCH_EDITORS'
export const FETCH_CAMERA_OPERATORS = 'lookups/FETCH_CAMERA_OPERATORS'
export const FETCH_VIDEO_PLAYLISTS = 'lookups/FETCH_VIDEO_PLAYLISTS'
export const FETCH_SCREENLETS = 'screenlets/FETCH_SCREENLETS'

// These actions are declared for sagas
export const getTeachers = () => {
  return {
    type: FETCH_TEACHERS,
    name: 'teachers',
  }
}

export const getEditors = () => {
  return {
    type: FETCH_EDITORS,
    name: 'editors',
  }
}

export const getCameraOperators = () => {
  return {
    type: FETCH_CAMERA_OPERATORS,
    name: 'cameraOperators',
  }
}

export const getVideoPlaylists = (filters: VideoPlaylistsFilters) => {
  return {
    type: FETCH_VIDEO_PLAYLISTS,
    payload: { filters },
  }
}
