import { message } from 'antd'
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import { showErrorNotification } from '../../redux/notifications/actions'
import { getQuestionCollectionQuestions } from '../../api/questionCollection'
import {
  getQuestion,
  approveQuestion,
  getQAListing,
  getQAReviewQuestions,
  getAppealQuestions,
  getExpiredQuestions,
} from '../../api/questions'

import {
  FETCH_QUESTIONS_FOR_ADMIN,
  FETCH_QUESTIONS_FOR_CE,
  FETCH_QUESTIONS_FOR_QA_LISTING,
  FETCH_QUESTIONS_FOR_APPEAL_LISTING,
  SET_QUESTIONS,
  requestQuestions,
  requestQuestionsSuccess,
  requestQuestionsError,
  receivedQuestions,
  setReceivedQuestions,
  FETCH_QUESTION,
  requestQuestion,
  requestQuestionSuccess,
  requestQuestionError,
  receivedQuestion,
  PATCH_QUESTION,
  approveLocalQuestion,
  PATCH_QUESTIONS,
  approveLocalQuestions,
  FETCH_QUESTIONS_FOR_EXPIRED_LISTING,
} from './actions'
import { showSuccessMessage, showErrorMessage } from '../notifications/actions'
import qaSagas from './qa/sagas'
import { sortQuestions } from '../../helper/questionSerializer'

/**
 * Invoked when Questions are to be fetched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* watchGetQuestionsForAdmin({ payload }) {
  try {
    yield put(requestQuestions())
    const response = yield call(getQuestionCollectionQuestions, payload.id, payload.filter)
    yield put(setReceivedQuestions(response.data))
  } catch (error) {
    yield put(requestQuestionsError())
    yield put(showErrorNotification('Something went wrong while fetching questions'))
  }
}

/**
 * Invoked when Questions are to be fetched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* watchGetQuestionsForQA({ payload }) {
  try {
    const { languageCode, jobId, qcId, filter } = payload
    yield put(requestQuestions())
    const response = yield call(getQAListing, jobId, qcId, filter)
    const { results } = response.data
    const questions = Object.create({})
    questions[languageCode] = results
    if (results) response.data.results = questions
    yield put(setReceivedQuestions(response.data))
  } catch (error) {
    yield put(requestQuestionsError())
    yield put(showErrorNotification('Something went wrong while fetching questions'))
  }
}

/**
 * Invoked when Questions are to be fetched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* watchGetQuestionsForCE({ payload }) {
  try {
    const { jobId, qcId, filter } = payload
    yield put(requestQuestions())
    const response = yield call(getQAReviewQuestions, jobId, qcId, filter)
    yield put(setReceivedQuestions(response.data))
  } catch (error) {
    yield put(requestQuestionsError())
    yield put(showErrorNotification('Something went wrong while fetching questions'))
  }
}

/**
 * Invoked when Questions are to be fetched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* watchGetQuestionsForAppeals({ payload }) {
  try {
    const { language } = payload
    yield put(requestQuestions())
    const response = yield call(getAppealQuestions, language)
    const { data } = response
    const newResponse = {
      results: {
        [language]: data.appealedQuestions,
      },
    }
    yield put(setReceivedQuestions(newResponse))
  } catch (error) {
    yield put(requestQuestionsError())
    yield put(showErrorNotification('Something went wrong while fetching appealed questions'))
  }
}

export function* watchGetExpiredQuestions({ payload }) {
  try {
    yield put(requestQuestions())
    const response = yield call(getExpiredQuestions, payload)
    const { data } = response
    yield put(setReceivedQuestions(data))
  } catch (error) {
    yield put(requestQuestionsError())
    yield put(showErrorNotification('Something went wrong while fetching expired questions'))
  }
}

/**
 * Invoked when Questions are to be fetched
 * @param {Object} object containing action and payload <Data object>
 */
export function* watchGetQuestions({ payload }) {
  yield put(receivedQuestions(payload))
  yield put(requestQuestionsSuccess())
}

/**
 * Invoked wheen a single question needs to be patched from a list
 */
export function* watchPatchQuestions({ payload }) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { id, updatedQuestionProp } = payload
    const response = yield call(approveQuestion, id, { ...updatedQuestionProp })
    yield put(approveLocalQuestions(id, updatedQuestionProp.langCode, response.data.isApproved))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when a single question needs to be fetched
 * @param {Object} action action object with payload
 */
export function* watchGetQuestion({ payload }) {
  try {
    yield put(requestQuestion())
    const response = yield call(getQuestion, payload.id)
    const sortedQuestions = sortQuestions(response.data)
    yield put(receivedQuestion(sortedQuestions))
    yield put(requestQuestionSuccess())
  } catch (error) {
    yield put(requestQuestionError())
    yield put(showErrorNotification('Something went wrong while fetching question'))
  }
}

/**
 * Invoked wheen a single question needs to be patched
 */
export function* watchPatchQuestion({ payload }) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { id, updatedQuestionProp } = payload
    const response = yield call(approveQuestion, id, { ...updatedQuestionProp })
    yield put(approveLocalQuestion(updatedQuestionProp.langCode, response.data.isApproved))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(FETCH_QUESTIONS_FOR_ADMIN, watchGetQuestionsForAdmin),
    takeEvery(FETCH_QUESTIONS_FOR_CE, watchGetQuestionsForCE),
    takeEvery(FETCH_QUESTIONS_FOR_QA_LISTING, watchGetQuestionsForQA),
    takeEvery(FETCH_QUESTIONS_FOR_APPEAL_LISTING, watchGetQuestionsForAppeals),
    takeEvery(FETCH_QUESTIONS_FOR_EXPIRED_LISTING, watchGetExpiredQuestions),
    takeEvery(SET_QUESTIONS, watchGetQuestions),
    takeEvery(FETCH_QUESTION, watchGetQuestion),
    takeLatest(PATCH_QUESTION, watchPatchQuestion),
    takeEvery(PATCH_QUESTIONS, watchPatchQuestions),
    qaSagas(),
  ])
}
