import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { AppThunk, RootState } from '../../..'
import { listPlans } from '../../../api/plans'
import {
  Filter,
  Plans,
  PlansListPayload,
  PlansListState,
  PlansResponse,
} from '../../../types/Plans'

export const initialPlansList: PlansListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const PlansListSlice = createSlice({
  name: 'plansListing',
  initialState: initialPlansList,
  reducers: {
    requestPlans(state: PlansListState) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedPlansFailed(state: PlansListState) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedPlans(state: PlansListState, action: PayloadAction<PlansListPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: Filter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
    resetPlans() {
      return initialPlansList
    },
  },
})

export const { receivedPlans, requestPlans, requestedPlansFailed, resetPlans } =
  PlansListSlice.actions

function instanceOfPlansListResponse(object: PlansResponse | Plans[]): object is PlansResponse {
  return 'results' in object
}

export const fetchPlans =
  (filter: Filter, productPackageId: number): AppThunk =>
  async dispatch => {
    dispatch(requestPlans())
    try {
      const response = await listPlans(filter, productPackageId)
      if (response) {
        if (instanceOfPlansListResponse(response.data)) {
          const { results: data, ...meta } = response.data
          dispatch(
            receivedPlans({
              data,
              filter,
              meta,
            }),
          )
        } else {
          const data = response.data
          dispatch(
            receivedPlans({
              data,
              filter,
            }),
          )
        }
      }
    } catch (error) {
      dispatch(requestedPlansFailed())
    }
  }

export const selectPlans = (state: RootState) => state.plans.list

export default combineReducers({
  list: PlansListSlice.reducer,
})
