import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../..'
import { CourseStudyTask, CourseStudyTaskState, ModuleId, PartId } from '../types'

// For course page assignments engineered like this way
// loading: <boolean>
// error : <string>
// tasks : {
//   <moduleId>:{
//       parts:{
//           partId:{
//               assignment:[
//                   <TASK>
//               ]
//           }
//       }
//       assignment:[
//           <TASK>
//       ]
//   }
// }
export type PartAssignments = Record<
  PartId,
  { tasks: Array<CourseStudyTask & CourseStudyTaskState> }
>
export type CourseStudyTasks = Record<
  ModuleId,
  {
    parts?: PartAssignments
    tasks?: Array<CourseStudyTask & CourseStudyTaskState>
  }
>

type ElevateCourseStudyTasks = {
  tasks?: CourseStudyTasks
}

const initialCourseTasks: ElevateCourseStudyTasks = {}

export const elevateCourseStudyTasks = createSlice({
  name: 'elevateCourseStudyTask',
  initialState: initialCourseTasks,
  reducers: {
    updateCourseStudyTask: (state, action: PayloadAction<CourseStudyTasks>) => {
      return (state = {
        ...state,
        tasks: { ...action.payload },
      })
    },
  },
})

export const { updateCourseStudyTask } = elevateCourseStudyTasks.actions

export const courseStudyTasks = (state: RootState) => state.elevateCourses.courseStudyTasks.tasks
