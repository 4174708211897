import { all, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import listSubjects from 'api/subjects'
import actions from './actions'

/**
 * Invoked when Get Subjects is dispatched
 */
export function* GET_SUBJECTS({ payload }) {
  try {
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: true,
      },
    })
    const response = yield call(listSubjects, payload)
    yield put({
      type: actions.SET_LIST,
      payload: {
        data: [...response.data],
        isLoading: false,
      },
    })
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong while fetching subjects',
    })
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: true,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_LIST, GET_SUBJECTS)])
}
