import httpService from '../httpService';
import { BatchProgress, StudentIndividualReport, StudentList } from '../redux/batchReport/types';


export function studentsProgress(packId: string, batchId: string) {
    return httpService.get<BatchProgress.RootObject>(`v7/gold/dailystudyplanpacks/${packId}/batches/${batchId}/students/progress/`)
}

export function studentsListing(packId: string, batchId: string, params?: { page?: string, lessonId?: string }) {
    return httpService.get<StudentList.RootObject>(`v7/gold/dailystudyplanpacks/${packId}/batches/${batchId}/students/`, {
        params: { ...params }
    })
}

export function studentReport(packId: string, batchId: string, studentId: string, params?: { frequency: string }) {
    return httpService.get<StudentIndividualReport.RootObject>(`v7/gold/dailystudyplanpacks/${packId}/batches/${batchId}/students/${studentId}/progress/`, {
        params: { ...params }
    })
}

export function cumulativeBatchReport(packId: string, batchId: string, params?: { frequency: string, range: string }) {
    return httpService.get(`v7/gold/dailystudyplanpacks/${packId}/batches/${batchId}/cumulative-report/`, {
        params: { ...params }
    })
}