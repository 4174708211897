import { PayloadAction, combineReducers, createSlice } from "@reduxjs/toolkit"
import { BatchDetails, BatchDetailsState, BatchProgresState, BatchStudentProgress, StudentReport, StudentReportState } from "./types"
import { RootState } from "../.."

const initialStudentReport: StudentReportState = {
    loading: false,
    data: null
}

const studentReport = createSlice({
    name: 'studentReport',
    initialState: initialStudentReport,
    reducers: {
        fetchStudentReport: (state) => {
            return state = {
                loading: true,
                data: null,
            }
        },
        receivedStudentReport: (state, action: PayloadAction<StudentReport.Root>) => {
            return state = {
                loading: false,
                data: action.payload,
                error: ""
            }
        },
        fetchStudenterport: (state) => {
            return state = {
                loading: false,
                data: null,
                error: "Something went wrong"
            }
        }
    }
})
export const { fetchStudentReport, receivedStudentReport, fetchStudenterport } = studentReport.actions;

const initialBatchContentProgress: BatchProgresState = {
    loading: false,
    data: null
}

const batchContentProgress = createSlice({
    name: 'batchContentProgress',
    initialState: initialBatchContentProgress,
    reducers: {
        fetchBatchContentProgress: (state) => {
            return state = {
                loading: true,
                data: null,
            }
        },
        receivedBatchContentProgress: (state, action: PayloadAction<BatchStudentProgress.Root>) => {
            return state = {
                loading: false,
                data: action.payload
            }
        },
        fetchBatchContentProgressError: (state) => {
            return state = {
                loading: false,
                data: null,
                error: "Something went wrong"
            }
        }
    }
})

export const { fetchBatchContentProgress, receivedBatchContentProgress, fetchBatchContentProgressError } = batchContentProgress.actions;

const initialBatchDetails: BatchDetailsState = {
    loading: false,
    data: null
}

const batchDetails = createSlice({
    name: 'batchDetails',
    initialState: initialBatchDetails,
    reducers: {
        fetchElevateBatchDetails: (state) => {
            return state = {
                loading: true,
                data: null
            }
        },
        receivedBatchDetails: (state, action: PayloadAction<BatchDetails>) => {
            return state = {
                loading: false,
                data: action.payload
            }
        },
        fetchBatchDetailsError: (state) => {
            return state = {
                loading: false,
                data: null,
                erorr: "Something went wrong"
            }
        }
    }
})

export const { fetchElevateBatchDetails, receivedBatchDetails, fetchBatchDetailsError } = batchDetails.actions

export const batchContentProgressSelector = (state: RootState) => state.newElevateBatchReport.batchContentProgress;
export const studentReportSelector = (state: RootState) => state.newElevateBatchReport.studentReport;
export const batchDetailsSelector = (state: RootState) => state.newElevateBatchReport.batchDetails

export default combineReducers({
    studentReport: studentReport.reducer,
    batchContentProgress: batchContentProgress.reducer,
    batchDetails: batchDetails.reducer
})