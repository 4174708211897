import httpService from 'httpService'

export function getQuestionCollections(payload) {
  return httpService.get('/v4/content/sources/question-collection/', {
    params: { ...payload },
  })
}
export function getQuestionCollection(id) {
  return httpService.get(`/v4/content/sources/question-collection/${id}/`)
}

export function getQuestionCollectionQuestions(id, filter) {
  return httpService.get(`/v4/content/sources/questions/list/${id}/`, {
    params: filter,
  })
}

export function createQuestionCollection(payload) {
  return httpService.post('/v4/content/sources/question-collection/', { ...payload })
}

export function editQuestionCollection(id, payload) {
  return httpService.put(`/v4/content/sources/question-collection/${id}/`, { ...payload })
}

export function changeQuestionCollectionStatus(jobId, qcId, isApproved) {
  return httpService.post(`/v4/content/review/jobs/${jobId}/question-collections/${qcId}/status/`, {
    isApproved,
  })
}

export function getQuestionCollectionToPrint(id, payload) {
  return httpService.get(`/v6/content/sources/question_collections/${id}/`, {
    params: payload
  })
}

export function getDifficulty() {
  return [
    { id: 1, text: 'Lowest' },
    { id: 2, text: 'Low' },
    { id: 3, text: 'Normal' },
    { id: 4, text: 'High' },
    { id: 5, text: 'Highest' },
  ]
}
