import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { Filter, ProductPackage, ProductPackagesResponse } from '../types/ProductPackage'

export const listProductPackages = (
  filter: Filter,
): Promise<AxiosResponse<ProductPackagesResponse | ProductPackage[]>> => {
  return httpService.get(`/v7/content/sources/product_packages/`, {
    params: {
      ...filter,
    },
  })
}

export const getProductPackage = (
  productPackageId: number,
): Promise<AxiosResponse<ProductPackage>> => {
  return httpService.get(`/v7/content/sources/product_packages/${productPackageId}/`)
}

export function createProductPackage(
  productPackageDetails: FormData,
): Promise<AxiosResponse<ProductPackage>> {
  return httpService.post(`/v7/content/sources/product_packages/`, productPackageDetails)
}

export function updateProductPackage(
  productPackageId: number,
  productPackageDetails: FormData,
): Promise<AxiosResponse<ProductPackage>> {
  return httpService.put(
    `/v7/content/sources/product_packages/${productPackageId}/`,
    productPackageDetails,
  )
}

export function updateProductPackageStatus(
  productPackageId: number,
  isActive: boolean,
): Promise<AxiosResponse<ProductPackage>> {
  return httpService.patch(`/v7/content/sources/product_packages/${productPackageId}/`, {
    isActive,
  })
}
