import { PermissionLevels, ViewPermissions } from '../../types/AccessControls'

export const FETCH_VIEW_ACCESS = 'accessControl/FETCH_VIEW_ACCESS'
export const SAVE_VIEW_ACCESS = 'accessControl/SAVE_VIEW_ACCESS'
export const FETCH_PAGE_ACCESS = 'accessControl/FETCH_ACCESS'
export const GET_ACCESS_CONTROLS = 'accessControl/GET_ACCESS'

export const getViewAccess = () => ({
  type: FETCH_VIEW_ACCESS,
})
export const saveViewAccess = (payload: ViewPermissions) => ({
  type: SAVE_VIEW_ACCESS,
  payload,
})

export const getAccess = (category: PermissionLevels) => ({
  type: FETCH_PAGE_ACCESS,
  payload: category,
})

export const fetchAccess = (category: PermissionLevels) => ({
  type: FETCH_PAGE_ACCESS,
  payload: category,
})
