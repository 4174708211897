import { all, takeLatest, takeEvery, call, put } from 'redux-saga/effects'
import { getViewAccess } from '../../api/access'
import { getSessionStorageData, saveSessionStorageData } from '../../helper/sessionStorage'
import { AccessControls, ViewPermissions } from '../../types/AccessControls'
import { showErrorNotification } from '../notifications/actions'
import { fetchAccess, FETCH_VIEW_ACCESS, GET_ACCESS_CONTROLS, SAVE_VIEW_ACCESS } from './actions'
import { requestedAccessSucceeded, requestAccess, saveAccess, saveViewAccess } from './slice'
import { AccessControlsAction, ViewAccessAction } from './types'

/**
 * Load view access
 *
 */
export function* STORE_VIEW_ACCESS({ payload }: ViewAccessAction) {
  try {
    yield put(requestAccess())
    const storedData = getSessionStorageData('access')
    if (storedData) {
      const storedAccessControls: AccessControls = JSON.parse(storedData)
      if (storedAccessControls.viewPermissions) yield put(saveAccess(storedAccessControls))
      else yield put(saveViewAccess(payload))
      yield put(requestedAccessSucceeded())
    } else {
      yield put(saveViewAccess(payload))
      saveSessionStorageData(
        'access',
        JSON.stringify({
          viewPermissions: payload,
        }),
      )
      yield put(requestedAccessSucceeded())
    }
  } catch (error) {
    console.log(error)
    yield put(showErrorNotification('Something went wrong while fetching access'))
  }
}

/**
 * Load view access
 *
 */
export function* LOAD_VIEW_ACCESS() {
  try {
    yield put(requestAccess())
    const storedData = getSessionStorageData('access')
    if (storedData) {
      const storedAccessControls: AccessControls = JSON.parse(storedData)
      yield put(saveAccess(storedAccessControls))
      yield put(requestedAccessSucceeded())
    } else {
      const response: ViewPermissions = yield call(getViewAccess)
      yield put(saveViewAccess(response))
      yield put(requestedAccessSucceeded())
    }
  } catch (error) {
    console.log(error)
    yield put(showErrorNotification('Something went wrong while fetching access'))
  }
}

/**
 * Load access
 * @param {Object} object containing action and payload <Job data object>
 */
export function* GET_ACCESS({ payload }: AccessControlsAction) {
  try {
    yield put(requestAccess())
    const storedData = getSessionStorageData('access')
    if (storedData) {
      const storedAccessData: AccessControls = JSON.parse(storedData)
      const isPermissionExist = !!storedAccessData.permissionLevels?.[payload]
      if (isPermissionExist) {
        yield put(
          saveAccess({
            ...storedAccessData,
          }),
        )
        yield put(requestedAccessSucceeded())
      } else {
        yield put(fetchAccess(payload))
      }
    } else {
      yield put(fetchAccess(payload))
    }
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching access'))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_ACCESS_CONTROLS, GET_ACCESS),
    takeEvery(FETCH_VIEW_ACCESS, LOAD_VIEW_ACCESS),
    takeEvery(SAVE_VIEW_ACCESS, STORE_VIEW_ACCESS),
  ])
}
