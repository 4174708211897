import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoriesListPayload, CategoriesState } from "../../types/Categories";
import { Filter } from "../../types/Platforms";
import { AppThunk, RootState } from "../..";
import { getCategories } from "../../api/landingPageGlobalComponents";

export const initialCategoriesList: CategoriesState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const CategoriesList = createSlice({
  name: 'categoryListing',
  initialState: initialCategoriesList,
  reducers: {
    requestCategories(state) {
        return { ...state, isLoading: true, hasError: false }
    },
    requestedCategoriesFailed(state) {
        return { ...state, isLoading: false, hasError: true }
    },
    receivedCategories(state, action: PayloadAction<CategoriesListPayload>) {
        const { data, filter, meta } = action.payload
        const newFilter = {
            ...state.filter,
            ...filter,
        }
        return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    }
}
})

export const { requestCategories, requestedCategoriesFailed, receivedCategories } = CategoriesList.actions

export const fetchCategories =
 (filter: Filter = {}): AppThunk =>  
 async dispatch => {
        dispatch(requestCategories());
        try {
            const response = await getCategories(filter);
            if (response) {
                const { results: data, ...meta } = response.data;
                dispatch(receivedCategories({ data, filter, meta }));
            }
        } catch (error) {
            dispatch(requestedCategoriesFailed());
        }
    }

export const selectCategories = (state: RootState) => state.categoriesList.list

export default combineReducers({
  list: CategoriesList.reducer,
})
