import 'helper/logger'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { routerMiddleware } from 'connected-react-router'
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createHashHistory } from 'history'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'
import Localization from './components/LayoutComponents/Localization'
import * as serviceWorker from './serviceWorker'
// global app styles
import './global.scss'
import { ConfigProvider } from 'antd'
import defaultTheme from './styles/theme'
import { initializeTrackers } from './tracker/mixpanel/tracker'

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)

export const middleWares = [sagaMiddleware, routeMiddleware, thunkMiddleware]

export const rootReducer = reducers(history)

initializeTrackers();
const store = configureStore({
  reducer: rootReducer,
  middleware: middleWares,
})

sagaMiddleware.run(sagas)

ReactDOM.render(
  <ConfigProvider direction="ltr" theme={defaultTheme}>
    <Provider store={store}>
      <Localization>
        <Router history={history} />
      </Localization>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root'),
)

serviceWorker.unregister()

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export { store, history }
