import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import {
  Content,
  ContentOrderPayload,
  ContentPartial,
  Lesson,
  LessonOrderPayload,
  LessonRequestData,
  Section,
  SectionRequestData,
  SkillingPack,
} from '../types/Skilling'

export const getSkillingPacks = (
  filter: SkillingPack.Filter,
): Promise<AxiosResponse<SkillingPack.PacksResponse>> => {
  return httpService.get(`/v7/content/sources/studyplans/packs/`, {
    params: {
      ...filter,
    },
  })
}

export const getSkillingPack = (packId: number): Promise<AxiosResponse<SkillingPack.Pack>> => {
  return httpService.get(`/v7/content/sources/studyplans/packs/${packId}/`)
}

/**
 * Create pack
 * @param packDetails
 * @returns
 */
export function createSkillingPack(
  packDetails: SkillingPack.Pack,
): Promise<AxiosResponse<SkillingPack.Pack>> {
  return httpService.post(`/v7/content/sources/studyplans/packs/`, packDetails)
}

/**
 * Update pack
 * @param packId
 * @param packDetails
 * @returns
 */
export function updateSkillingPack(
  packId: number,
  packDetails: Partial<SkillingPack.Pack>,
): Promise<AxiosResponse<SkillingPack.Pack>> {
  return httpService.put(`/v7/content/sources/studyplans/packs/${packId}/`, packDetails)
}

/**
 * Update pack status
 * @param packId
 * @param packDetails
 * @returns
 */
export function updateSkillingPackStatus(
  packId: number,
  isActive: boolean,
): Promise<AxiosResponse<SkillingPack.Pack>> {
  return httpService.patch(`/v7/content/sources/studyplans/packs/${packId}/`, { isActive })
}

/**
 * Update pack status
 * @param packId
 * @param quizId
 * @returns
 */
export function createSkillingPackAssignment(
  packId: number,
  formData: FormData,
): Promise<AxiosResponse<SkillingPack.Pack>> {
  return httpService.put(`/v7/content/sources/studyplans/packs/${packId}/assignment/`, formData)
}

// Sections
export const getSkillingSections = (
  packId: string,
  category: 'EXTRAS' | 'NORMAL',
): Promise<AxiosResponse<Section[]>> => {
  return httpService.get(`/v7/content/sources/studyplans/sections/`, {
    params: {
      packId,
      category,
    },
  })
}

export const createSkillingSection = (
  sectionDetails: SectionRequestData,
  packId: string,
): Promise<AxiosResponse<Section>> => {
  return httpService.post(`/v7/content/sources/studyplans/sections/`, sectionDetails, {
    params: {
      packId,
    },
  })
}

export const updateSkillingSection = (
  sectionId: number,
  sectionDetails: Partial<Section>,
  packId: string,
): Promise<AxiosResponse<Section>> => {
  return httpService.put(`/v7/content/sources/studyplans/sections/${sectionId}/`, sectionDetails, {
    params: {
      packId,
    },
  })
}

// Lessons
export const getSkillingLessons = (
  sectionId: number,
  packId: number,
): Promise<AxiosResponse<Lesson[]>> => {
  return httpService.get(`/v7/content/sources/studyplans/lessons/`, {
    params: {
      sectionId,
      packId,
    },
  })
}

export const updateSkillingLesson = (
  lessonId: number,
  lessonDetails: Partial<Lesson>,
  packId: string,
): Promise<AxiosResponse<Lesson>> => {
  return httpService.put(`/v7/content/sources/studyplans/lessons/${lessonId}/`, lessonDetails, {
    params: {
      packId,
    },
  })
}

export const createSkillingLesson = (
  lessonDetails: LessonRequestData,
  packId: string,
): Promise<AxiosResponse<Lesson>> => {
  return httpService.post(`/v7/content/sources/studyplans/lessons/`, lessonDetails, {
    params: { packId },
  })
}

export const reorderLessons = (
  sectionId: number,
  lessonOrderPayload: LessonOrderPayload,
  packId: number,
): Promise<AxiosResponse<Content>> => {
  return httpService.patch(
    `/v7/content/sources/studyplans/sections/${sectionId}/lessons/order/`,
    lessonOrderPayload,
    {
      params: {
        packId,
      },
    },
  )
}

export const createSkillingContent = (
  contentDetails: any,
  packId: number,
): Promise<AxiosResponse<Content>> => {
  return httpService.post(`/v7/content/sources/studyplans/contents/`, contentDetails, {
    params: {
      packId,
    },
  })
}

export const updateSkillingContent = (
  contentId: number,
  contentDetails: ContentPartial,
  packId: string,
): Promise<AxiosResponse<Content>> => {
  return httpService.put(`/v7/content/sources/studyplans/contents/${contentId}/`, contentDetails, {
    params: { packId },
  })
}

export const reorderContents = (
  lessonId: number,
  contentOrderPayload: ContentOrderPayload,
  packId: number,
): Promise<AxiosResponse<Content>> => {
  return httpService.patch(
    `/v7/content/sources/studyplans/lessons/${lessonId}/contents/order/`,
    contentOrderPayload,
    {
      params: {
        packId,
      },
    },
  )
}
