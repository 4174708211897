import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { InactivePackages, PurchaseListInterface, PurchaseListRootResponse } from "./types";


const initialState: PurchaseListInterface = {
    purchaseListing: {
        data: null,
        loading: false,
        error: false
    },
    inactivePackage: {
        data: null,
        loading: false,
        error: false
    }
}


const purchaseSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
        requestPurchases: (state, _) => {
            state.purchaseListing = {
                loading: true,
                data: null,
                error: false
            }
        },
        receivedPurchses: (state, action: PayloadAction<PurchaseListRootResponse>) => {
            state.purchaseListing = {
                loading: false,
                data: action.payload,
                error: false
            }
        },
        errorOnPurchase: (state) => {
            state.purchaseListing = {
                loading: false,
                data: null,
                error: true
            }
        },
        requestInactivePackages: (state, _) => {
            state.inactivePackage = {
                loading: true,
                data: null,
                error: false
            }
        },
        receivedInactivePackages: (state, action: PayloadAction<InactivePackages.Root>) => {
            state.inactivePackage = {
                loading: false,
                data: action.payload,
                error: false
            }
        },
        errorOnInactivePackages: (state) => {
            state.inactivePackage = {
                loading: false,
                data: null,
                error: true
            }
        }
    }
});

export const purchasesSelector = (state: RootState) => state.purchases.purchaseListing;
export const inactivePackageSelector = (state: RootState) => state.purchases.inactivePackage;


export const {
    requestPurchases,
    receivedPurchses,
    errorOnPurchase,
    requestInactivePackages,
    receivedInactivePackages,
    errorOnInactivePackages
} = purchaseSlice.actions

export default purchaseSlice.reducer