import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadAccessControls, selectAccessControl } from '../redux/accessControls/slice'
import { PermissionLevels, Permissions } from '../types/AccessControls'

const useAccessControl = (
  permissionLevel: PermissionLevels,
): [Permissions[] | [] | undefined, boolean] => {
  // const [allowedPermissions, setAllowedPermissions] = useState<Permissions[] | []>([])
  const allowedPermissions = useRef<Permissions[] | [] | undefined>()
  const [isPermissionsLoading, setIsPermissionLoading] = useState(true)
  const accessControls = useSelector(selectAccessControl)
  const dispatch = useDispatch()

  useEffect(() => {
    // Check if store has any access controls
    // Else dispatch load access control action
    if (accessControls.data?.permissionLevels) {
      setIsPermissionLoading(true)
      const permissions = accessControls.data.permissionLevels?.[permissionLevel]
      if (permissions) {
        setIsPermissionLoading(false)
        allowedPermissions.current = permissions
      } else if (!accessControls.hasError) {
        try {
          dispatch(loadAccessControls(permissionLevel))
          setIsPermissionLoading(false)
        } catch (error) {
          console.log(error)
        }
      }
    } else if (!accessControls.hasError) {
      dispatch(loadAccessControls(permissionLevel))
    }
  }, [permissionLevel, accessControls.data?.permissionLevels])

  return [allowedPermissions.current, isPermissionsLoading]
}

export default useAccessControl
