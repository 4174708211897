import { all, put, call, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { showErrorNotification } from '../notifications/actions';
import { BaseList, Filters } from './types';
import { getInstitutes, getCenters, getTracks } from '../../api/filters';
import {
    requestCenters,
    receivedCenters,
    requestInstitutes,
    receivedInstitutes,
    requestTracks,
    receivedTracks
} from './slice';


interface CenterPayload {
    payload: number,
    type: string
}

export function* GET_CENTERS({ payload }: CenterPayload) {
    try {
        const response: { data: Filters.Center[] } = yield call(getCenters, payload)
        yield put(receivedCenters(response.data))
    } catch (error) {
        console.error((error as Error).message, 'GET_CENTERS()');
        showErrorNotification('Error while fetching Centers')
    }
}

export function* GET_INSTITUTES() {
    try {
        const response: { data: Filters.Center[] } = yield call(getInstitutes)
        yield put(receivedInstitutes(response.data))
    } catch (error) {
        console.error((error as Error).message, 'GET_CENTERS()');
        showErrorNotification('Error while fetching Centers')
    }
}

export function* GET_TRACKS() {
    try {
        const response: { data: BaseList[] } = yield call(getTracks)
        yield put(receivedTracks(response.data))
    } catch (error) {
        console.error((error as Error).message, 'GET_CENTERS()');
        showErrorNotification('Error while fetching Centers')
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(requestCenters.type, GET_CENTERS),
        takeLatest(requestInstitutes.type, GET_INSTITUTES),
        takeLatest(requestTracks.type, GET_TRACKS)
    ])
}