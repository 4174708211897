import actions from './actions'

const initialState = {
  data: null,
  codeByObject: {},
  isLoading: false,
}

export default function languages(state = initialState, action) {
  switch (action.type) {
    case actions.SET_LIST:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

// selectors

/**
 * Find a particular language object from id/code
 * @param {object} state user state object
 * @param {number/string} language language code or language id
 * @returns {object}
 */
export const findLanguage = (state, language) =>
  state?.data?.find((lang) => lang.code === language || lang.id === language)

export const getAllLanguages = (state) => state.languages