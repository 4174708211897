import httpService from '../httpService'
import { BaseList, Filters } from '../redux/filters/types';

export function getInstitutes() {
    return httpService.get<Filters.Institute[]>('/v7/gold/institutes/')
}

export function getCenters(instituteId: number) {
    return httpService.get(`/v7/gold/institutes/${instituteId}/centers/`)
}

export function getTracks() {
    return httpService.get<BaseList[]>(`/v4/content/sources/platform-groups/`)
}
