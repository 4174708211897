import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  SubjectListFilter,
  SubjectListState,
  SubjectListPayload,
  SubjectsResponse,
} from '../../types/Subjects'
import { AppThunk, RootState } from '../..'
import listSubjects, { getPlatformSubjects } from '../../api/subjects'

export const initialSubjectsList: SubjectListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const SubjectsListSlice = createSlice({
  name: 'subjectListing',
  initialState: initialSubjectsList,
  reducers: {
    requestSubjects(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedSubjectsFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedSubjects(state, action: PayloadAction<SubjectListPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: SubjectListFilter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
  },
})

export const { receivedSubjects, requestSubjects, requestedSubjectsFailed } =
  SubjectsListSlice.actions

export const fetchAllSubjects =
  (filter: any): AppThunk =>
  async dispatch => {
    dispatch(requestSubjects())
    try {
      const response = await listSubjects(filter)
      if (response) {
        const data = response.data
        dispatch(
          receivedSubjects({
            data,
            filter,
          }),
        )
      }
    } catch (error) {
      dispatch(requestedSubjectsFailed())
    }
  }

export const fetchPlatformSubjects =
  (platformId: number, filter: SubjectListFilter): AppThunk =>
  async dispatch => {
    dispatch(requestSubjects())
    try {
      const response = await getPlatformSubjects(platformId, filter)
      if (response) {
        const { results: data, ...meta } = response.data
        dispatch(
          receivedSubjects({
            data,
            filter,
            meta,
          }),
        )
      }
    } catch (error) {
      dispatch(requestedSubjectsFailed())
    }
  }

export const selectSubjects = (state: RootState) => state.subjects.list

export default combineReducers({
  list: SubjectsListSlice.reducer,
})
