import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import { message } from 'antd'
import {
  listJobs,
  getJob,
  changeJobStatus,
  getJobTypes,
  getVideoQAJob,
  changeVideoQAJobStatus,
  changeVideoQAParentJobStatus,
} from '../../api/jobs'
import {
  FETCH_JOB,
  FETCH_JOBS,
  PATCH_JOBS,
  PATCH_JOB,
  JobActionImpl,
  FilterImpl,
  JobDetailImpl,
  JobPatchImpl,
  JobTypesImpl,
  FETCH_JOB_TYPES,
  JobTypeItemImpl,
  FETCH_VIDEO_QA_JOB,
  PATCH_VIDEO_QA_STATUS,
  VideoQAJobDetail,
  PATCH_VIDEO_QA_PARENT_STATUS,
  PatchVideoQAStatus,
  PatchVideoQAParentStatus,
} from './types'
import {
  receivedJob,
  receivedJobs,
  requestJob,
  requestJobError,
  requestJobSuccess,
  requestJobs,
  requestJobsError,
  requestJobsSuccess,
  updateJobs,
  updateJob,
  receivedJobsTypes,
  requestJobTypesSuccess,
  requestJobTypesError,
  saveJobsFilter,
  requestJobTypes,
} from './actions'
import {
  showErrorNotification,
  showErrorMessage,
  showSuccessMessage,
} from '../notifications/actions'
import { pushParamsToUrl } from '../../helper/urlParamSerializer'
import { AxiosResponse } from 'axios'

/**
 * Invoked when Fetch Job Types is dispatched
 */
export function* GET_JOB_TYPES() {
  try {
    const isJobTypesLoaded: boolean = yield select(
      ({ jobs }) => Object.keys(jobs.jobTypes.data).length > 0,
    )
    const isJobTypesLoading: boolean = yield select(({ jobs }) => jobs.jobTypes.isLoading)
    if (!isJobTypesLoaded && !isJobTypesLoading) {
      yield put(requestJobTypes())
      const response: { data: JobTypesImpl } = yield call(getJobTypes)
      const formattedData: Array<JobTypeItemImpl> = Object.keys(response.data).map(id => ({
        attachments: response.data[id].attachments,
        text: response.data[id].verbose,
        id,
      }))
      yield put(receivedJobsTypes(formattedData))
      yield put(requestJobTypesSuccess())
    }
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching job types'))
    yield put(requestJobTypesError())
  }
}

/**
 * Invoked when Get Job is dispatched
 */
export function* GET_JOB({ payload }: JobActionImpl) {
  try {
    yield put(requestJob())
    const response: { data: JobDetailImpl } = yield call(getJob, payload as Number)
    yield put(receivedJob(response.data))
    yield put(requestJobSuccess())
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching job details'))
    yield put(requestJobError())
  }
}

/**
 * Invoked when Get Video QA Job is dispatched
 */
export function* GET_VIDEO_QA_JOB({ payload }: JobActionImpl) {
  try {
    yield put(requestJob())
    const response: { data: VideoQAJobDetail } = yield call(getVideoQAJob, payload as Number)
    yield put(receivedJob(response.data))
    yield put(requestJobSuccess())
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching video job details'))
    yield put(requestJobError())
  }
}

/**
 * Invoked when Get Job is dispatched
 * @param {object} object containing action and payload <Job id number>
 */
export function* PATCH_JOB_DETAIL({ payload }: JobActionImpl) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { jobId, jobDetail } = payload as JobPatchImpl
    const response: AxiosResponse = yield call(changeJobStatus, jobId, { ...jobDetail })
    yield put(updateJob(response.data))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when Get patchVideoQAStatus is dispatched
 * @param {object} object containing action and payload parentJobId, childJobId, statusDetails
 */
export function* UPDATE_VIDEO_QA_STATUS({ payload }: JobActionImpl) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { parentJobId, childJobId, statusDetails } = payload as PatchVideoQAStatus
    const response: AxiosResponse = yield call(
      changeVideoQAJobStatus,
      parentJobId,
      childJobId,
      statusDetails,
    )
    yield put(updateJob(response.data))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when Get patchVideoQAStatus is dispatched
 * @param {object} object containing action and payload parentJobId and statusDetails
 */
export function* UPDATE_VIDEO_QA_PARENT_STATUS({ payload }: JobActionImpl) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { parentJobId, statusDetails } = payload as PatchVideoQAParentStatus
    const response: AxiosResponse = yield call(
      changeVideoQAParentJobStatus,
      parentJobId,
      statusDetails,
    )
    yield put(updateJob(response.data))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when Get Job is dispatched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* GET_JOB_LIST({ payload }: JobActionImpl) {
  try {
    yield put(requestJobs())
    const prevFilter: FilterImpl = yield select(state => state.jobs.list.filter)
    const newFilter: FilterImpl = {
      ...prevFilter,
      ...payload,
    }
    yield pushParamsToUrl(newFilter)
    yield put(saveJobsFilter(newFilter))
    const response: AxiosResponse = yield call(listJobs, newFilter as FilterImpl)
    const { results: data, ...meta } = response.data
    yield put(receivedJobs({ data, meta, filter: newFilter }))
    yield put(requestJobsSuccess())
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching jobs'))
    yield put(requestJobsError())
  }
}

/**
 * Invoked when Patch Job List is dispatched
 * @param {object} object containing action and payload jobId, jobDetail
 */
export function* PATCH_JOB_LIST({ payload }: JobActionImpl) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { jobId, jobDetail } = payload as JobPatchImpl
    const response: AxiosResponse = yield call(changeJobStatus, jobId, { ...jobDetail })
    yield put(updateJobs(response.data))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_JOB, GET_JOB),
    takeLatest(PATCH_JOB, PATCH_JOB_DETAIL),
    takeLatest(FETCH_JOBS, GET_JOB_LIST),
    takeLatest(PATCH_JOBS, PATCH_JOB_LIST),
    takeLatest(FETCH_JOB_TYPES, GET_JOB_TYPES),
    takeLatest(FETCH_VIDEO_QA_JOB, GET_VIDEO_QA_JOB),
    takeLatest(PATCH_VIDEO_QA_STATUS, UPDATE_VIDEO_QA_STATUS),
    takeLatest(PATCH_VIDEO_QA_PARENT_STATUS, UPDATE_VIDEO_QA_PARENT_STATUS),
  ])
}
