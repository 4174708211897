import { all } from 'redux-saga/effects'
import user from './user/sagas'
import settings from './settings/sagas'
import questionCollection from './questionCollection/sagas'
import jobs from './jobs/sagas'
import comments from './jobs/comments/sagas'
import platforms from './platform/sagas'
import files from './files/sagas'
import subjects from './subjects/sagas'
import modules from './modules/sagas'
import topics from './topics/sagas'
import languages from './languages/sagas'
import notifications from './notifications/sagas'
import questions from './questions/sagas'
import discusses from './discuss/sagas'
import videos from './videos/sagas'
import lookups from './lookups/sagas'
import accessControls from './accessControls/sagas'
import studyPlans from './studyplan/sagas'
import filters from './filters/saga'
import purchses from './goldPurchase/sagas'
import goldQuestions from './goldQuestions/saga'
import productPackage from './productPackage/saga'
import batchReport from './batchReport/saga'
import liveClasses from './liveclasses/saga'

export default function* rootSaga() {
  yield all([
    user(),
    settings(),
    questionCollection(),
    jobs(),
    comments(),
    platforms(),
    files(),
    subjects(),
    modules(),
    topics(),
    languages(),
    notifications(),
    questions(),
    discusses(),
    videos(),
    lookups(),
    accessControls(),
    studyPlans(),
    filters(),
    purchses(),
    goldQuestions(),
    productPackage(),
    batchReport(),
    liveClasses(),
  ])
}
