import httpService from '../httpService';
import { PurchaseFilter } from '../redux/elevatePurchase/types';
import { GetInactivePackageParams, GetPurchasesParams } from '../redux/goldPurchase/types';


export function listPurchases(payload?: GetPurchasesParams) {
    return httpService.get('/v7/gold/purchases', {
        params: payload
    })
}


export function listInactivePackages(payload?: GetInactivePackageParams) {
    return httpService.get("/v7/gold/inactive-packages", {
        params: {
            courseVertical: payload?.courseVertical,
            page: payload?.inactivePage,
        }
    })
}

export function listElevatePurchases(payload?: PurchaseFilter) {
    return httpService.get("/v7/content/sources/purchases/", {
        params: {
            categoryType: "ELEVATE",
            pageSize: 20,
            ...payload
        }
    })
}

export function getElevatePurchase(id: number) {
    return httpService.get(`/v7/content/sources/purchases/${id}/onboarding/`)
}