import {
  RECEIVED_JOB,
  RECEIVED_JOBS,
  REQUESTED_JOB,
  REQUESTED_JOBS,
  REQUESTED_JOBS_FAILED,
  REQUESTED_JOBS_SUCCEEDED,
  REQUESTED_JOB_FAILED,
  REQUESTED_JOB_SUCCEEDED,
  RESET_JOB,
  FETCH_JOB,
  FETCH_JOBS,
  PATCH_JOB,
  PATCH_JOBS,
  JobImpl,
  JobsImpl,
  JobDetailImpl,
  FilterImpl,
  UPDATE_JOBS,
  UPDATE_JOB,
  PatchPayloadImpl,
  REQUESTED_JOB_TYPES,
  REQUESTED_JOB_TYPES_SUCCEEDED,
  REQUESTED_JOB_TYPES_FAILED,
  RECEIVED_JOB_TYPES,
  FETCH_JOB_TYPES,
  JobTypeItemImpl,
  SAVE_JOBS_FILTER,
  FETCH_VIDEO_QA_JOB,
  VideoQAJobDetail,
  PATCH_VIDEO_QA_STATUS,
  PATCH_VIDEO_QA_PARENT_STATUS,
} from './types'

import { initialJobList } from './reducers'

/**
 *
 * Job Details
 *
 */

/**
 * Notifies store that job is being fetched
 */
export const requestJob = () => ({ type: REQUESTED_JOB })

/**
 * Notifies store that job fetch was a success
 */
export const requestJobSuccess = () => ({ type: REQUESTED_JOB_SUCCEEDED })

/**
 * Notifies store that job fetch failed
 */
export const requestJobError = () => ({ type: REQUESTED_JOB_FAILED })

/**
 * Sets received job to store
 */
export const receivedJob = (data: JobDetailImpl | VideoQAJobDetail) => ({
  type: RECEIVED_JOB,
  payload: data,
})

export const getJob = (jobId: Number) => {
  return {
    type: FETCH_JOB,
    payload: jobId,
  }
}

export const getVideoQAJob = (jobId: Number) => {
  return {
    type: FETCH_VIDEO_QA_JOB,
    payload: jobId,
  }
}

export const patchJob = (jobId: Number, jobDetail: PatchPayloadImpl) => {
  return {
    type: PATCH_JOB,
    payload: {
      jobId,
      jobDetail,
    },
  }
}

export const patchVideoQAStatus = (
  parentJobId: number,
  childJobId: number,
  statusDetails: { isStarted?: boolean; isFinished?: boolean; qcJobId?: number },
) => {
  return {
    type: PATCH_VIDEO_QA_STATUS,
    payload: {
      parentJobId,
      childJobId,
      statusDetails,
    },
  }
}

export const patchVideoQAParentStatus = (
  parentJobId: number,
  statusDetails: { isStarted?: boolean; isPaid?: boolean },
) => {
  return {
    type: PATCH_VIDEO_QA_PARENT_STATUS,
    payload: {
      parentJobId,
      statusDetails,
    },
  }
}

export const updateJob = (job: JobImpl) => {
  return {
    type: UPDATE_JOB,
    payload: job,
  }
}

export const resetJob = () => {
  return {
    type: RESET_JOB,
  }
}

/**
 *
 * Job List
 *
 */
/**
 * Notifies store that jobs is being fetched
 */
export const requestJobs = () => ({ type: REQUESTED_JOBS })

/**
 * Notifies store that jobs fetch was a success
 */
export const requestJobsSuccess = () => ({ type: REQUESTED_JOBS_SUCCEEDED })

/**
 * Notifies store that jobs fetch failed
 */
export const requestJobsError = () => ({ type: REQUESTED_JOBS_FAILED })

/**
 * Sets received jobs to store
 */
export const receivedJobs = (data: JobsImpl) => ({
  type: RECEIVED_JOBS,
  payload: data,
})

export const saveJobsFilter = (filter: FilterImpl) => {
  return {
    type: SAVE_JOBS_FILTER,
    payload: filter,
  }
}

export const getJobs = (filter: FilterImpl = initialJobList.filter) => {
  return {
    type: FETCH_JOBS,
    payload: filter,
  }
}

export const patchJobs = (jobId: Number, jobDetail: PatchPayloadImpl) => {
  return {
    type: PATCH_JOBS,
    payload: {
      jobId,
      jobDetail,
    },
  }
}

export const updateJobs = (job: JobImpl) => {
  return {
    type: UPDATE_JOBS,
    payload: job,
  }
}

/**
 *
 * Job Types
 *
 */
export const requestJobTypes = () => ({ type: REQUESTED_JOB_TYPES })
export const requestJobTypesSuccess = () => ({ type: REQUESTED_JOB_TYPES_SUCCEEDED })
export const requestJobTypesError = () => ({ type: REQUESTED_JOB_TYPES_FAILED })
export const receivedJobsTypes = (data: Array<JobTypeItemImpl>) => ({
  type: RECEIVED_JOB_TYPES,
  payload: data,
})
export const getJobTypes = () => ({ type: FETCH_JOB_TYPES })
