import { all, takeEvery, put, call, takeLatest, select } from 'redux-saga/effects'
import { listComments, createComment } from '../../../api/comments'
import {
  addComment,
  requestComments,
  receivedComments,
  requestCommentsSuccess,
  requestCommentsError,
  getComments,
} from './actions'
import { CREATE_COMMENT, FETCH_COMMENTS, MetaImpl } from './types'
import { showErrorNotification } from '../../notifications/actions'
import { AxiosResponse } from 'axios'

/**
 * Invoked when Create Comment is dispatched
 * @param {Object} object containing action and payload <Comment data object>
 */
export function* HANDLE_CREATE_COMMENT({
  payload,
}: {
  type: typeof CREATE_COMMENT
  payload: {
    task: Number
  }
}) {
  yield put(requestComments())
  try {
    const commentsMeta: MetaImpl = yield select(({ jobs }) => jobs.comments.meta)
    const response: AxiosResponse = yield call(createComment, payload)
    if (response) {
      if (commentsMeta.page === 1) {
        yield put(addComment(response.data))
      } else {
        yield put(getComments(payload.task, { page: 1 }))
      }
      yield put(requestCommentsSuccess())
    }
  } catch (error) {
    yield put(requestCommentsError())
    yield put(showErrorNotification('Something went wrong while adding comment'))
  }
}

/**
 * Invoked when Get Comments is dispatched
 * @param {object} object containing action and payload <Job id number>
 */
export function* GET_COMMENTS({
  payload,
}: {
  type: typeof FETCH_COMMENTS
  payload: {
    jobId: Number
    filter: { page: Number }
  }
}) {
  try {
    yield put(requestComments())
    const response: AxiosResponse = yield call(listComments, payload.jobId, payload.filter)
    const { results, ...meta } = response.data
    yield put(
      receivedComments({
        data: results,
        meta,
      }),
    )
    yield put(requestCommentsSuccess())
  } catch (error) {
    yield put(requestCommentsError())
    yield put(showErrorNotification('Something went wrong while fetching comments'))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(CREATE_COMMENT, HANDLE_CREATE_COMMENT),
    takeLatest(FETCH_COMMENTS, GET_COMMENTS),
  ])
}
