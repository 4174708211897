import { combineReducers } from 'redux'
import {
  RECEIVED_QUESTIONS,
  REQUESTED_QUESTIONS,
  REQUESTED_QUESTIONS_SUCCEEDED,
  REQUESTED_QUESTIONS_FAILED,
  RECEIVED_QUESTION,
  REQUESTED_QUESTION,
  REQUESTED_QUESTION_SUCCEEDED,
  REQUESTED_QUESTION_FAILED,
  APPROVE_QUESTION,
  APPROVE_QUESTIONS,
  APPROVE_QA_STATUS,
  RECEIVED_COMMON_MISTAKES,
  REQUESTED_COMMON_MISTAKES,
  REQUESTED_COMMON_MISTAKES_FAILED,
  REQUESTED_COMMON_MISTAKES_SUCCEEDED,
  RESET_QUESTION,
} from './actions'
import { QUESTION_STATUS } from '../../helper/constants'

const initialListState = {
  filter: null,
  results: null,
  meta: null,
  isLoading: false,
  hasError: false,
}

const initialDetailState = {
  result: null,
  isLoading: false,
  hasError: false,
}

const initialCommonMistakesState = {
  data: null,
  isLoading: false,
  hasError: false,
}

const list = (state = initialListState, action) => {
  switch (action.type) {
    case REQUESTED_QUESTIONS: {
      const newState = Object.assign({}, state)
      newState.isLoading = true
      newState.hasError = false
      return newState
    }
    case RECEIVED_QUESTIONS: {
      const newState = Object.assign({}, state)
      const { results, ...meta } = action.data
      newState.results = results
      newState.meta = meta
      return newState
    }
    case REQUESTED_QUESTIONS_SUCCEEDED: {
      const newState = Object.assign({}, state)
      newState.isLoading = false
      newState.hasError = false
      return newState
    }
    case REQUESTED_QUESTIONS_FAILED: {
      const newState = Object.assign({}, state)
      newState.isLoading = false
      newState.hasError = true
      return newState
    }
    case APPROVE_QUESTIONS: {
      const newState = Object.assign({}, state)
      const { id, langCode, isApproved } = action.payload
      newState.results[langCode][id].isApproved = isApproved
      newState.results[langCode][id].status = isApproved
        ? QUESTION_STATUS.APPROVED
        : QUESTION_STATUS.PENDING
      return newState
    }
    case APPROVE_QA_STATUS: {
      const newState = Object.assign({}, state)
      const { id, langCode, status } = action.payload
      newState.results[langCode][id].status = status
      return newState
    }
    default:
      return state
  }
}

const details = (state = initialDetailState, action) => {
  switch (action.type) {
    case REQUESTED_QUESTION:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }

    case RECEIVED_QUESTION: {
      const { payload } = action
      return {
        ...state,
        result: payload,
      }
    }
    case REQUESTED_QUESTION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      }
    case RESET_QUESTION: {
      return initialDetailState
    }
    case REQUESTED_QUESTION_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case APPROVE_QUESTION: {
      const { payload } = action
      const newApprovedMap = {
        ...state.result.isApprovedLocalized,
        ...payload,
      }
      const langCode = Object.keys(payload)[0]
      const isApproved = Object.keys(newApprovedMap).reduce(
        (acc, curr) => acc && newApprovedMap[curr],
        true,
      )
      return {
        ...state,
        result: {
          ...state.result,
          isApproved,
          isApprovedLocalized: newApprovedMap,
          reviewStatus: {
            ...state.result.reviewStatus,
            [langCode]: {
              status: payload[langCode] ? QUESTION_STATUS.APPROVED : QUESTION_STATUS.PENDING,
            },
          },
        },
      }
    }
    default:
      return state
  }
}

const commonMistakes = (state = initialCommonMistakesState, action) => {
  switch (action.type) {
    case REQUESTED_COMMON_MISTAKES:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }

    case RECEIVED_COMMON_MISTAKES: {
      const { payload } = action
      return {
        ...state,
        data: payload,
      }
    }
    case REQUESTED_COMMON_MISTAKES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      }
    case REQUESTED_COMMON_MISTAKES_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export { list, details, commonMistakes }

export default combineReducers({
  list,
  details,
  commonMistakes,
})
