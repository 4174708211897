import { QuestionCollectionImpl } from '../questionCollection/types'
import { CommentsImpl } from './comments/types'

export const SAVE_JOBS_FILTER = 'jobs/SAVE_FILTER'
export const REQUESTED_JOBS = 'jobs/REQUESTED'
export const REQUESTED_JOBS_FAILED = 'jobs/FAILED'
export const REQUESTED_JOBS_SUCCEEDED = 'jobs/SUCCEEDED'
export const RECEIVED_JOBS = 'jobs/RECEIVED'
export const FETCH_JOBS = 'jobs/FETCH'
export const UPDATE_JOBS = 'jobs/UPDATE'
export const PATCH_JOBS = 'jobs/PATCH'

export const REQUESTED_JOB = 'job/REQUESTED'
export const REQUESTED_JOB_FAILED = 'job/FAILED'
export const REQUESTED_JOB_SUCCEEDED = 'job/SUCCEEDED'
export const RECEIVED_JOB = 'job/RECEIVED'
export const FETCH_JOB = 'job/FETCH'
export const FETCH_VIDEO_QA_JOB = 'job/FETCH_VIDEO_QA_JOB'
export const CREATE_JOB = 'job/CREATE'
export const UPDATE_JOB = 'job/UPDATE'
export const PATCH_JOB = 'job/PATCH'
export const RESET_JOB = 'job/RESET'
export const PATCH_VIDEO_QA_STATUS = 'job/PATCH_VIDEO_QA_STATUS'
export const PATCH_VIDEO_QA_PARENT_STATUS = 'job/PATCH_VIDEO_QA_PARENT_STATUS'

export const REQUESTED_JOB_TYPES = 'jobtypes/REQUESTED'
export const REQUESTED_JOB_TYPES_FAILED = 'jobtypes/FAILED'
export const REQUESTED_JOB_TYPES_SUCCEEDED = 'jobtypes/SUCCEEDED'
export const RECEIVED_JOB_TYPES = 'jobtypes/RECEIVED'
export const FETCH_JOB_TYPES = 'jobtypes/FETCH'

export interface JobActionImpl {
  type: string
  payload?:
  | JobsImpl
  | JobImpl
  | FilterImpl
  | JobPatchImpl
  | JobDetailImpl
  | JobTypesImpl
  | Number
  | PatchVideoQAStatus
  | PatchVideoQAParentStatus
}

export interface JobTypesImpl {
  [key: string]: {
    verbose: string
    attachments: Array<string>
  }
}

export interface JobDetailImpl {
  files: any[]
  job: JobImpl | null | {}
  questionCollections: QuestionCollectionImpl[]
  studycardPacks: any[]
  studycardStacks: any[]
}

export interface VideoQAJobDetail {
  files: any[]
  job: JobImpl | null | {}
}

export interface QAVideos {
  dataEntryJob: DataEntryJob
  id: number
  isApproved: boolean
  isRejected: boolean
  hasResubmitted: boolean
  parentJobId: boolean
  video: QAVideo
  isStarted: boolean
}

export interface DataEntryJob {
  id: number
  questionCollection: {
    id: number
    name: string
    questionCount: number
  }
  isStarted: boolean
  isFinished: boolean
}

export interface QAVideo {
  hdVideo: any
  id: number
  isActive: boolean
  languageCode: string
  name: string
  teacherId: number | null
  weight: number
  duration: number
}

export interface JobImpl {
  id: number
  title: string
  platform: number
  platformName: string
  assigneeUsername: string
  authorUsername: string
  platformGroup: number
  platformGroupName: string
  assignee: number
  author: number
  assigneeName: string
  rating: number
  authorName: string
  workTypeVerbose: string
  workType: string
  description: string
  isFinished: boolean
  isStarted: boolean
  isPaid: boolean
  isApproved: boolean
  remuneration: string
  dueDate: string
  startDate?: string
  finishedDate?: string
  payMonth: string
  languageData: { id: Number; code: string }
  qaVideos?: QAVideos
}
export interface JobStateImpl {
  data: JobDetailImpl
  isLoading: Boolean
  hasError: Boolean
}

export interface PatchPayloadImpl {
  platform?: number
  platformGroup?: number
  assignee?: number
  rating?: number
  workType?: string
  isFinished?: boolean
  isStarted?: boolean
  isPaid?: boolean
  isApproved?: boolean
  dueDate?: string
  startDate?: string
  finishedDate?: string
  payMonth?: string
}

export interface JobPatchImpl {
  jobId: Number
  jobDetail: PatchPayloadImpl
}

// Jobs Types
export interface MetaImpl {
  page: number
  pageCount: number
  limit: number
  totalObjectCount?: number
  totalRemuneration?: string
}

export enum JobStatus {
  all = 'all',
  open = 'open',
  doing = 'doing',
  completed = 'completed',
  approved = 'approved',
}

type JobCategory = 'NORMAL' | 'ONE_TIME_CONTRACT'

export interface FilterImpl {
  page: number
  assignee?: number
  jobType?: string
  author?: number
  jobStatus: JobStatus
  jobCategory: JobCategory
  platformGroup?: number
  payMonth?: string
  pageSize: number
  platform?: number
}

export interface JobsImpl {
  data: JobImpl[]
  meta: MetaImpl
  filter: FilterImpl
}

export interface JobsStateImpl extends JobsImpl {
  isLoading: Boolean
  hasError: Boolean
}

export interface JobTypeItemImpl {
  attachments: Array<string>
  text: string
  id: string
}

export interface JobTypeStateImpl {
  data: Array<JobTypeItemImpl>
  isLoading: Boolean
  hasError: Boolean
}
export interface JobStoreImpl {
  list: JobsStateImpl
  details: JobStateImpl
  comments: CommentsImpl
}

export interface JobFormImpl {
  title: string
  platform?: number
  workType: string
  assignee?: number
  displayName?: string
  dueDate: string
  jobCategory: string
  languageId: number
  remuneration: number
  attachmentIds: number[]
  questionCollectionIds?: number[]
  studycardPackIds?: number[]
  studycardStackIds?: number[]
  description: string
  trackId?: number
  packIds?: number[]
  playlistIds?: number[]
  videoIds?: {
    id: number
    questionCollectionId?: number
  }
  bankAccountNumber?: string
  bankIfsc?: string
  panCardNumber?: string
  alternatePhone?: string
}

export interface PatchVideoQAStatus {
  parentJobId: number
  childJobId: number
  statusDetails: { isStarted: boolean }
}

export interface PatchVideoQAParentStatus {
  parentJobId: number
  statusDetails: {
    isApproved?: boolean
    isPaid?: boolean
  }
}
