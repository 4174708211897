import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
  Track,
  TrackListingFilters,
  TrackListResponse,
  TrackListState,
  TrackPayload,
  TrackState,
} from '../../types/Tracks'
import { AppThunk, RootState } from '../..'
import { getTrack, getTracks } from '../../api/tracks'
import { TracksResponse } from '../videos/types'

// Track List Slice

export const initialTracksList: TrackListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const tracksListSlice = createSlice({
  name: 'trackListing',
  initialState: initialTracksList,
  reducers: {
    requestTracks(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedTracksFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedTracks(state, action: PayloadAction<TrackPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: TrackListingFilters = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
    resetTracks() {
      return initialTracksList
    },
  },
})

export const { receivedTracks, requestTracks, requestedTracksFailed, resetTracks } =
  tracksListSlice.actions

const instanceOfTracksResponse = (object: any): object is TrackListResponse => {
  return 'results' in object
}

export const fetchTracks =
  (filter: TrackListingFilters = {}): AppThunk =>
  async dispatch => {
    dispatch(requestTracks())
    try {
      const response = await getTracks(filter)
      if (response) {
        if (instanceOfTracksResponse(response.data)) {
          const { results: data, ...meta } = response.data
          dispatch(
            receivedTracks({
              data,
              filter,
              meta,
            }),
          )
        } else {
          const data = response.data
          dispatch(
            receivedTracks({
              data,
              filter,
            }),
          )
        }
      }
    } catch (error) {
      dispatch(requestedTracksFailed())
    }
  }

export const selectTracks = (state: RootState) => state.tracks.list

// Track Slice

export const initialTrackState: TrackState = {
  data: null,
  isLoading: false,
  hasError: false,
}

const trackSlice = createSlice({
  name: 'track',
  initialState: initialTrackState,
  reducers: {
    requestTrack(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedTrackFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedTrack(state, action: PayloadAction<Track>) {
      return { ...state, data: action.payload, isLoading: false, hasError: false }
    },
  },
})

const { receivedTrack, requestTrack, requestedTrackFailed } = trackSlice.actions

export const fetchTrack =
  (trackCategoryId: number): AppThunk =>
  async dispatch => {
    dispatch(requestTrack())
    try {
      const response = await getTrack(trackCategoryId)
      if (response) {
        dispatch(receivedTrack(response.data))
      }
    } catch (error) {
      dispatch(requestedTrackFailed())
    }
  }

export const selectTrack = (state: RootState) => state.tracks.details

export default combineReducers({
  list: tracksListSlice.reducer,
  details: trackSlice.reducer,
})
