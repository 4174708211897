import { PayloadAction, combineReducers, createSlice } from '@reduxjs/toolkit';
import { InitialListingState, Purchase, InititalDetailState, PurchaseDetail } from './types';
import { RootState } from '../..';

const initialListinState: InitialListingState = {
    loading: false,
    error: false,
    data: null
}


const purchaseListingSlice = createSlice({
    name: 'elevatePurchases',
    initialState: initialListinState,
    reducers: {
        requestElevatePurchases: (state) => {
            return state = {
                loading: true,
                error: false,
                data: null
            }
        },
        sucessElevatePurchases: (state, action: PayloadAction<Purchase.Root>) => {
            return state = {
                loading: false,
                data: action.payload,
                error: false
            }
        },
        errorElevatePurchases: (state) => {
            return state = {
                data: null,
                loading: false,
                error: true
            }
        }
    }
});

export const {
    requestElevatePurchases,
    sucessElevatePurchases,
    errorElevatePurchases
} = purchaseListingSlice.actions;

export const elevatePurchasesSelector = (state: RootState) => state.elevatePurchases.listing;

const inititalDetailState: InititalDetailState = {
    loading: false,
    data: null,
    error: false
}
const purchaseDetailSlice = createSlice({
    name: "elevatePurchaseUpdate",
    initialState: inititalDetailState,
    reducers: {
        requestPurchaseDetail: (state) => {
            return state = {
                loading: true,
                data: null,
                error: false
            }
        },
        successPurchaseDetails: (state, action: PayloadAction<PurchaseDetail.Root>) => {
            return state = {
                loading: false,
                data: action.payload,
                error: false
            }
        },
        errorPurchaseDetail: (state) => {
            return state = {
                loading: false,
                data: null,
                error: true
            }
        }
    }
})

export const {
    requestPurchaseDetail,
    successPurchaseDetails,
    errorPurchaseDetail
} = purchaseDetailSlice.actions;

export default combineReducers({
    listing: purchaseListingSlice.reducer,
    details: purchaseDetailSlice.reducer
});