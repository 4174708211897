import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { StudyPlanList, InitialState, StudyPlan, ContentOrderPayload, LessonOrderPayload, LiveDetails } from "./types";
import _ from 'lodash'

const initialState: InitialState = {
    list: {
        loading: false,
        data: null,
        error: null
    },
    details: {
        loading: false,
        data: null,
        error: null
    },
    liveDetails: {
        loading: false,
        data: null,
        error: null
    }
}

const studyPlanSlice = createSlice({
    name: "studyPlans",
    initialState: initialState,
    reducers: {
        requestStudyPlans: (state, _) => {
            state.list = {
                loading: true,
                data: null,
                error: null
            }
        },
        requestFreePlans: (state, _) => {
            state.list = {
                loading: true,
                data: null,
                error: null
            }
        },
        receivedStudyPlans: (state, action: PayloadAction<StudyPlanList.Root>) => {
            state.list = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        errorStudyPlans: (state, action) => {
            state.list = {
                loading: false,
                data: null,
                error: action.payload
            }
        },
        requestPlanDetails: (state, _) => {
            state.details = {
                loading: true,
                data: null,
                error: null
            }
        },
        receivedPlanDetails: (state, action: PayloadAction<StudyPlan.Root>) => {
            state.details = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        errorPlanDetails: (state, action: PayloadAction<string>) => {
            state.details = {
                loading: false,
                data: null,
                error: action.payload
            }
        },
        removeDailyLesson: (state, action: PayloadAction<number>) => {
            state.details.data!.dailyLessons = state.details
                .data!.dailyLessons.filter(lesson => Number(lesson.id) !== Number(action.payload))
                .map((lesson, index) => ({ ...lesson, orderingNumber: index + 1 }))
        },
        addDailyLesson: (state, action: PayloadAction<StudyPlan.Lesson>) => {
            state.details.data!.dailyLessons.push(action.payload)
        },
        addContentToLesson: (state, action: PayloadAction<{ lessonId: number; content: StudyPlan.Content }>) => {
            const idx = state.details.data!.dailyLessons.findIndex(
                lesson => lesson.id === action.payload.lessonId,
            )
            state.details.data!.dailyLessons[idx].contents = [
                ...state.details.data!.dailyLessons[idx].contents,
                action.payload.content,
            ]
        },
        reorderDailyLesson: (state, action: PayloadAction<LessonOrderPayload>) => {
            state.details.data!.dailyLessons = action.payload.lessons
                .map(lesson => {
                    let existingLesson = _.find(
                        state.details.data!.dailyLessons,
                        dailyLesson => dailyLesson.id === lesson.id,
                    )
                    return {
                        ...existingLesson!,
                        orderingNumber: lesson.orderingNumber,
                    }
                })
                .sort((a, b) => a.orderingNumber - b.orderingNumber)
        },
        removeContentFromLesson: (state, action: PayloadAction<{ lessonId: number, contentId: number }>) => {
            const idx = state.details.data!.dailyLessons.findIndex(
                lesson => lesson.id === action.payload.lessonId,
            )
            state.details.data!.dailyLessons[idx].contents = state.details.data!.dailyLessons[idx].contents.filter(
                content => content.id !== action.payload.contentId,
            )
        },
        reorderContentInLesson: (
            state,
            action: PayloadAction<{ lessonId: number; newOrder: ContentOrderPayload }>,
        ) => {
            const idx = state.details.data!.dailyLessons.findIndex(
                lesson => lesson.id === action.payload.lessonId,
            )
            state.details.data!.dailyLessons[idx].contents = action.payload.newOrder.contents
                .map(newContent => {
                    let existingContent = _.find(
                        state.details.data!.dailyLessons[idx].contents,
                        content => content.id === newContent.id,
                    )
                    return {
                        ...existingContent,
                        orderingNumber: newContent.orderingNumber,
                    } as StudyPlan.Content
                })
                .sort((a, b) => a.orderingNumber - b.orderingNumber)
        },
        addNewBatch: (state, action: PayloadAction<StudyPlan.Batch>) => {
            state.details.data!.batches.push(action.payload)
        },
        removeBatch: (state, action: PayloadAction<{ batchId: number }>) => {
            state.details.data!.batches = state.details.data!.batches.filter(
                batch => batch!.batch!.id !== action.payload.batchId,
            )
        },
        addLiveClass: (state, action: PayloadAction<StudyPlan.LiveClass>) => {
            state.details.data!.liveClasses.push(action.payload)
        },
        removeLiveClass: (state, action: PayloadAction<{ liveClassId: number }>) => {
            state.details.data!.liveClasses = state.details.data!.liveClasses.filter(
                liveClass => liveClass.id !== action.payload.liveClassId,
            )
        },
        udpateLiveClass: (state, action: PayloadAction<{ liveId: number, liveDetails: StudyPlan.LiveClass }>) => {
            state.details.data!.liveClasses = state.details.data!.liveClasses.filter(
                liveClass => liveClass.id !== action.payload.liveId,
            );
            state.details.data!.liveClasses.push(action.payload.liveDetails)
        },
        requestLiveDetails: (state, _) => {
            state.liveDetails = {
                loading: true,
                data: null,
                error: null
            }
        },
        successLiveDetails: (state, action: PayloadAction<LiveDetails>) => {
            state.liveDetails = {
                loading: false,
                data: action.payload,
                error: null
            }
        },
        errorLiveDetails: (state, action: PayloadAction<string>) => {
            state.liveDetails = {
                loading: false,
                data: null,
                error: action.payload
            }
        }

    }
})

export const studyPlansSelector = ((state: RootState) => state.studyPlans.list);
export const planDetailsSelector = ((state: RootState) => state.studyPlans.details)
export const liveDetailsSelector = ((state: RootState) => state.studyPlans.liveDetails)
export const {
    requestStudyPlans,
    requestFreePlans,
    receivedStudyPlans,
    errorStudyPlans,
    receivedPlanDetails,
    requestPlanDetails,
    errorPlanDetails,
    removeDailyLesson,
    addDailyLesson,
    addContentToLesson,
    removeContentFromLesson,
    addNewBatch,
    removeBatch,
    addLiveClass,
    removeLiveClass,
    reorderDailyLesson,
    reorderContentInLesson,
    requestLiveDetails,
    successLiveDetails,
    errorLiveDetails,
    udpateLiveClass
} = studyPlanSlice.actions

export default studyPlanSlice.reducer;