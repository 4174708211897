import { AxiosResponse } from 'axios'

import httpService from '../httpService'
import {
  Filter,
  TrackCategoriesResponse,
  TrackCategory,
  TrackCategorySortingRequest,
} from '../types/TrackCategory'
/**
 * Fetch track categories
 */
export function getTrackCategories(
  filter?: Filter,
): Promise<AxiosResponse<TrackCategoriesResponse | TrackCategory[]>> {
  return httpService.get(`/v7/content/sources/track-categories/`, {
    params: {
      ...filter,
    },
  })
}

/**
 * Fetch track category
 */
export function getTrackCategory(trackCategoryId: number): Promise<AxiosResponse<TrackCategory>> {
  return httpService.get(`/v7/content/sources/track-categories/${trackCategoryId}`)
}

/**
 * Create track category
 * @param trackCategoryDetails
 * @returns
 */
export function createTrackCategory(
  trackCategoryDetails: FormData,
): Promise<AxiosResponse<TrackCategory>> {
  return httpService.post(`/v7/content/sources/track-categories/`, trackCategoryDetails)
}

/**
 * Update track category
 * @param trackCategoryId
 * @param trackCategoryDetails
 * @returns
 */
export function updateTrackCategory(
  trackCategoryId: number,
  trackCategoryDetails: FormData,
): Promise<AxiosResponse<TrackCategory>> {
  return httpService.put(
    `/v7/content/sources/track-categories/${trackCategoryId}/`,
    trackCategoryDetails,
  )
}

/**
 * Fetch track categories sorting list
 */
export function getTrackCategoriesSortingList(
  filter: Filter,
): Promise<AxiosResponse<TrackCategoriesResponse>> {
  return httpService.get(`/v7/content/sources/track-categories/sorting_list/`, {
    params: {
      ...filter,
    },
  })
}

/**
 * Update track category sort order
 * @param trackCategoryUpdatePayload
 * @returns
 */

export function updateTrackCategorySortingList(
  trackCategoryUpdatePayload: TrackCategorySortingRequest,
): Promise<AxiosResponse<TrackCategory>> {
  return httpService.put(
    `/v7/content/sources/track-categories/sorting/`,
    trackCategoryUpdatePayload,
  )
}
