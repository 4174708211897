import actions from './actions'

const initialState = {
  data: null,
  isLoading: false,
}

export function platformReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_LIST:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export function platformGroupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_GROUP_LIST:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
