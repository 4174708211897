export const saveSessionStorageData = (key: string, data: any) => {
  sessionStorage.setItem(key, data)
}

export const getSessionStorageData = (key: string) => {
  const storedData = sessionStorage.getItem(key)
  return storedData
}

export const removeSessionStorageData = (key: string) => {
  sessionStorage.removeItem(key)
}
