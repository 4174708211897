import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { TrackListingFilters, TrackListResponse, Track, TrackSortingRequest } from '../types/Tracks'

export const getTracks = (
  filter: TrackListingFilters,
): Promise<AxiosResponse<TrackListResponse | Track[]>> => {
  return httpService.get(`/v7/content/sources/tracks/`, {
    params: {
      ...filter
    },
  })
}
export const getTrack = (trackId: number): Promise<AxiosResponse<Track>> => {
  return httpService.get(`/v7/content/sources/tracks/${trackId}/`)
}

/**
 * Create track
 * @param trackDetails
 * @returns
 */
export function createTrack(trackDetails: FormData): Promise<AxiosResponse<Track>> {
  return httpService.post(`/v7/content/sources/tracks/`, trackDetails)
}

/**
 * Update track
 * @param trackId
 * @param trackDetails
 * @returns
 */
export function updateTrack(
  trackId: number,
  trackDetails: FormData,
): Promise<AxiosResponse<Track>> {
  return httpService.put(`/v7/content/sources/tracks/${trackId}/`, trackDetails)
}

/**
 * Fetch track sorting list
 */
export function getTrackSortingList(
  filter: TrackListingFilters,
): Promise<AxiosResponse<TrackListResponse>> {
  return httpService.get(`/v7/content/sources/tracks/sorting_list/`, {
    params: {
      ...filter,
    },
  })
}

/**
 * Update track order
 * @param trackUpdatePayload
 * @returns
 */

export function updateTrackSortingList(
  trackUpdatePayload: TrackSortingRequest,
): Promise<AxiosResponse<Track>> {
  return httpService.put(`/v7/content/sources/tracks/sorting/`, trackUpdatePayload)
}
