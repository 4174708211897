import { all, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { listPlatforms, listPlatformGroups } from 'api/platform'
import actions from './actions'

/**
 * Invoked when Get Platform is dispatched
 */
export function* GET_PLATFORM({ payload }) {
  try {
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: true,
      },
    })
    const response = yield call(listPlatforms, payload)
    yield put({
      type: actions.SET_LIST,
      payload: {
        data: [...response.data],
        isLoading: false,
      },
    })
  } catch (error) {
    console.log(error)
    notification.error({
      message: 'Error',
      description: 'Something went wrong while fetching platforms',
    })
  }
}

/**
 * Invoked when Get Platform groups is dispatched
 */
export function* GET_PLATFORM_GROUPS({ payload }) {
  try {
    yield put({
      type: actions.SET_GROUP_LIST,
      payload: {
        isLoading: true,
      },
    })
    const response = yield call(listPlatformGroups, payload?.languageId)
    yield put({
      type: actions.SET_GROUP_LIST,
      payload: {
        data: [...response.data],
        isLoading: false,
      },
    })
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong while fetching platform groups',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LIST, GET_PLATFORM),
    takeLatest(actions.GET_GROUP_LIST, GET_PLATFORM_GROUPS),
  ])
}
