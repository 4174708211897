import httpService from '../httpService'
import {
  ContentOrderPayload,
  LessonOrderPayload,
  StudyPlan,
  StudyPlanInfoPayload,
  StudyPlanList,
} from '../redux/studyplan/types'
import { Vertical } from '../types/TrackCategory'

export function listStudyPlans(payload?: StudyPlanList.Filters) {
  return httpService.get('/v7/gold/dailystudyplanpacks/', {
    params: {
      ...payload,
    },
  })
}

export function detailsStudyPlan(planId: string) {
  return httpService.get(`v7/gold/dailystudyplanpacks/${planId}/`)
}

export function createStudyPlan(payload: StudyPlan.Root) {
  return httpService.post(`/v7/gold/dailystudyplanpacks/`, payload)
}

export function updateStudyPlanInfo(planId: number, payload: StudyPlanInfoPayload) {
  return httpService.patch<StudyPlan.Root>(`/v7/gold/dailystudyplanpacks/${planId}/`, payload)
}

export function mapBatchToPlan(planId: string, newBatch: StudyPlan.Batch) {
  return httpService.post<StudyPlan.Batch>(`/v7/gold/dailystudyplanpacks/${planId}/map_batches/`, {
    ...newBatch,
    batchId: newBatch.id,
  })
}

export function deleteBatch(planId: string, batchId: number) {
  return httpService.delete(`/v7/gold/dailystudyplanpacks/${planId}/batches/${batchId}/`)
}

export function addLiveClassToStudyPlan(planId: string, payload: StudyPlan.LiveClass) {
  return httpService.post<StudyPlan.LiveClass>(
    `/v7/gold/dailystudyplanpacks/${planId}/live_classes/`,
    payload,
  )
}

export function updateLiveClass(planId: string, payload: StudyPlan.LiveClass) {
  return httpService.patch<StudyPlan.LiveClass>(
    `/v7/gold/dailystudyplanpacks/${planId}/live_classes/${payload!.id}/edit/`,
    payload,
  )
}

export function deleteLiveClass(planId: string, liveClassId: number) {
  return httpService.delete(`/v7/gold/dailystudyplanpacks/${planId}/live_classes/${liveClassId}/`)
}

export function endLiveClass(planId: string, liveClassId: number) {
  return httpService.post(`/v7/gold/dailystudyplanpacks/${planId}/live_classes/${liveClassId}/end/`)
}

export function addNewLesson(planId: string, payload: StudyPlan.Lesson) {
  return httpService.post<StudyPlan.Lesson>(
    `/v7/gold/dailystudyplanpacks/${planId}/lesson/`,
    payload,
  )
}

export function deleteLesson(planId: string, lessonId: number) {
  return httpService.delete(`/v7/gold/dailystudyplanpacks/${planId}/lessons/${lessonId}/`)
}

export function addNewContent(
  planId: string,
  lessonId: number,
  newContent: { content: StudyPlan.Content },
) {
  return httpService.post<StudyPlan.Content>(
    `/v7/gold/dailystudyplanpacks/${planId}/lessons/${lessonId}/contents/`,
    newContent,
  )
}

export function deleteContent(planId: string, lessonId: number, contentId: number) {
  return httpService.delete(
    `/v7/gold/dailystudyplanpacks/${planId}/lessons/${lessonId}/contents/${contentId}`,
  )
}

export function editContent(
  planId: number,
  lessonId: number,
  contentId: number,
  newContent: { content: StudyPlan.Content },
) {
  return httpService.post<StudyPlan.Content>(
    `/v7/gold/dailystudyplanpacks/${planId}/lessons/${lessonId}/contents/${contentId}`,
    newContent,
  )
}

export function updateLessonOrder(planId: string, payload: LessonOrderPayload) {
  return httpService.patch<LessonOrderPayload>(
    `/v7/gold/dailystudyplanpacks/${planId}/lessons/order/`,
    payload,
  )
}

export function updateContentOrder(planId: string, lessonId: number, payload: ContentOrderPayload) {
  return httpService.patch<ContentOrderPayload>(
    `/v7/gold/dailystudyplanpacks/${planId}/lessons/${lessonId}/contents/order/`,
    payload,
  )
}

export function listFreeStudyPlans(payload?: StudyPlanList.Filters) {
  return httpService.get('/v7/gold/dailystudyplanpacks/free_trial/', {
    params: {
      ...payload,
    },
  })
}

export function getLiveDetails(studyPlanId: string, liveId: number) {
  if (!studyPlanId) {
    return httpService.get(`/v7/live/${liveId}/generate_token/`)
  }
  return httpService.get(
    `/v7/gold/dailystudyplanpacks/${studyPlanId}/live_classes/${liveId}/generate_token/`,
  )
}

export function getAllStudyPlan(vertical: Vertical) {
  return httpService.get(`/v7/gold/studyplans/`, {
    params: {
      course_vertical: vertical,
    },
  })
}
