import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../..'
import { getCertificateList } from 'api/certificates'
import { CertificateListPayload, CertificateListState, Filter } from './models'

export const initialCertificatesList: CertificateListState = {
    data: [],
    meta: {
        page: 1,
        pageCount: 1,
        limit: 20,
    },
    filter: {
        pageSize: 20,
        page: 1,
    },
    isLoading: false,
    hasError: false,
}


const certificateListSlice = createSlice({
    name: 'profilesSlice',
    initialState: initialCertificatesList,
    reducers: {
        requestCertificates(state) {
            return { ...state, isLoading: true, hasError: false }
        },
        requestedCertificatesFailed(state) {
            return { ...state, isLoading: false, hasError: true }
        },
        receivedCertificates(state, action: PayloadAction<CertificateListPayload>) {
            const { data, filter, meta } = action.payload
            const newFilter: Filter = {
                ...state.filter,
                ...filter,
            }
            return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
        },
    },
})


export const { requestCertificates, requestedCertificatesFailed, receivedCertificates } =
    certificateListSlice.actions

export const fetchCertificates =
    (filter: Filter): AppThunk =>
        async dispatch => {
            dispatch(requestCertificates())
            try {
                const response = await getCertificateList(filter)
                if (response) {
                    const { results: data, ...meta } = response.data

                    dispatch(
                        receivedCertificates({
                            data,
                            filter,
                            meta
                        }),
                    )
                }
            } catch (error) {
                dispatch(requestedCertificatesFailed())
            }
        }

export const selectCertificates = (state: RootState) => state.certificateList

export default certificateListSlice.reducer