import { PayloadAction, combineReducers, createSlice } from '@reduxjs/toolkit'
import {
  ElevateCourse,
  ElevateCourseBatchListingState,
  ElevateCourseDetailModuleState,
  ElevateCourseDetailState,
  ElevateCourses,
  ElevateCoursesListingState,
  ElevateModule,
} from './types'
import { AppThunk, RootState } from '../..'
import { elevateTaskInterface } from '../../pages/elevate/studytasks/redux/modal/slice'
import { cloneDeep } from 'lodash'
import { elevateCourseStudyTasks } from '../../pages/elevate/studytasks/redux/courseStudyTask/slice'

const initalElevateCourses: ElevateCoursesListingState = {
  loading: false,
  data: null,
}

const elevateCourses = createSlice({
  name: 'elevateCoursesListing',
  initialState: initalElevateCourses,
  reducers: {
    fetchElevateCourses: state => {
      state.loading = true
    },
    receivedElevateCourses: (state, action: PayloadAction<ElevateCourses.Root>) => {
      return (state = {
        loading: false,
        data: action.payload,
      })
    },
    fetchElevateCoursesError: state => {
      return (state = {
        loading: false,
        data: null,
        error: 'Something went wrong',
      })
    },
  },
})

export const { fetchElevateCoursesError, receivedElevateCourses, fetchElevateCourses } =
  elevateCourses.actions

const courseDetailInitialState: ElevateCourseDetailState = {
  loading: false,
  data: null,
  batches: null,
}

export const elevateCoursesSelector = (state: RootState) => state.elevateCourses.listing

const courseDetail = createSlice({
  name: 'elevateCourseDetail',
  initialState: courseDetailInitialState,
  reducers: {
    fetchElevateCourseDetail: state => {
      state.loading = true
    },
    receiveElevateCourseDetail: (state, action: PayloadAction<ElevateCourse.Root>) => {
      return (state = {
        loading: false,
        data: action.payload,
        batches: action.payload?.batches
          ?.filter(x => !!x.isActive)
          ?.map(x => ({ name: x.batch.name, id: x.batch.id })),
      })
    },
    fetchElevateCourseDetailError: state => {
      return (state = {
        loading: false,
        data: null,
        batches: null,
        error: 'Something went wrong',
      })
    },
    resetCourseDetails: (state) => {
      return state = {
        loading: false,
        data: null,
        error: ""
      }
    }
  },
})

export const {
  fetchElevateCourseDetail,
  receiveElevateCourseDetail,
  fetchElevateCourseDetailError,
  resetCourseDetails
} = courseDetail.actions

export const elevateCourseDetailSelector = (state: RootState) => state.elevateCourses.courseDetail
export const elevateCourseBatchesSelector = (state: RootState) =>
  state.elevateCourses.courseDetail.batches

const elevateCourseBatchesInitialState: ElevateCourseBatchListingState = {
  loading: false,
  data: null
}

const courseBatches = createSlice({
  name: 'elevateCourseBatches',
  initialState: elevateCourseBatchesInitialState,
  reducers: {
    fetchElevateCourseBatches: state => {
      state.loading = true
    },
    receivedElevateCourseBatches: (state, action: PayloadAction<ElevateCourse.ElevateBatchResults>) => {
      return (state = {
        loading: false,
        data: action.payload,
      })
    },
    fetchElevateCourseBatchesError: state => {
      return (state = {
        loading: false,
        data: null,
        error: 'Something went wrong',
      })
    },
  }
})

export const {
  fetchElevateCourseBatches,
  receivedElevateCourseBatches,
  fetchElevateCourseBatchesError,
} = courseBatches.actions

const courseModuleDetailInitialState: ElevateCourseDetailModuleState = {
  loading: false,
  data: null,
}

const courseModules = createSlice({
  name: 'elevateModuleDetails',
  initialState: courseModuleDetailInitialState,
  reducers: {
    fetchCourseModules: state => {
      return (state = {
        loading: true,
        data: null,
      })
    },
    receiveCourseModules: (state, action: PayloadAction<ElevateModule.Root>) => {
      return (state = {
        loading: false,
        data: action.payload,
      })
    },
    fetchCourseModulesError: state => {
      return (state = {
        loading: false,
        data: null,
        error: 'Something Went Wrong',
      })
    },
    resetCourseModules: state => {
      return (state = {
        loading: false,
        data: null,
      })
    },
    openLiveClassModal: (
      state,
      action: PayloadAction<{
        partId: number | string
        packId: number | string
        moduleId: number | string
        extraLiveFormFields?: JSX.Element
      }>,
    ) => {
      return (state = {
        ...state,
        liveClassModal: {
          mode: 'CREATE',
          ...action.payload,
        },
      })
    },
    editLiveClassModal: (
      state,
      action: PayloadAction<{
        packId: number | string
        moduleId: number | string
        liveId: number
        partId: number
        editingLive: ElevateModule.LiveClass | undefined
        extraLiveFormFields?: JSX.Element
      }>,
    ) => {
      return (state = {
        ...state,
        liveClassModal: {
          mode: 'EDIT',
          ...action.payload,
        },
      })
    },
    closeLiveClassModal: state => {
      return (state = {
        ...state,
        liveClassModal: undefined,
      })
    },
    createNewPartModal: (
      state,
      action: PayloadAction<{
        weekNumber: number
        moduleId: number
        packId: number | string
      }>,
    ) => {
      return {
        ...state,
        partModal: {
          mode: 'CREATE',
          ...action.payload,
        },
      }
    },
    closePartModal: state => {
      return {
        ...state,
        partModal: undefined,
      }
    },
    editPartModal: (
      state,
      action: PayloadAction<{
        partId: number | string
        name: string
        isActive: boolean
        moduleId: number
      }>,
    ) => {
      return {
        ...state,
        partModal: {
          mode: 'EDIT',
          ...action.payload,
        },
      }
    },
    createHomeworkModal: (
      state,
      action: PayloadAction<{
        weekNumber: number
        moduleId: number
        packId: number | string
      }>,
    ) => {
      return {
        ...state,
        modal: {
          mode: 'CREATE',
          ...action.payload,
        },
      }
    },
    closeHomeworkModal: state => {
      return {
        ...state,
        modal: undefined,
      }
    },
    editHomeworkModal: (
      state,
      action: PayloadAction<{
        partId: number | string
        name: string
        isActive: boolean
        moduleId: number
      }>,
    ) => {
      return {
        ...state,
        modal: {
          mode: 'EDIT',
          ...action.payload,
        },
      }
    },
  },
})

export const {
  fetchCourseModules,
  receiveCourseModules,
  resetCourseModules,
  fetchCourseModulesError,
  openLiveClassModal,
  closeLiveClassModal,
  createNewPartModal,
  closePartModal,
  editPartModal,
  editLiveClassModal,
  createHomeworkModal,
  closeHomeworkModal,
  editHomeworkModal,
} = courseModules.actions

export const elevateCourseModulesSelector = (state: RootState) => state.elevateCourses.modules
export const contentModalSelector = (state: RootState) => state.elevateCourses.modules.modalData
export const liveCLassModalSelector = (state: RootState) =>
  state.elevateCourses.modules.liveClassModal
export const partModalSelector = (state: RootState) => state.elevateCourses.modules.partModal
export const elevateBatchesSelector = (state: RootState) => state.elevateCourses.batches

export default combineReducers({
  listing: elevateCourses.reducer,
  courseDetail: courseDetail.reducer,
  modules: courseModules.reducer,
  modals: elevateTaskInterface.reducer,
  courseStudyTasks: elevateCourseStudyTasks.reducer,
  batches: courseBatches.reducer
})
