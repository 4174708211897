import dayjs from 'dayjs'

/**
 * computes if a given date is past expiry
 * @param {string | object} targetDate date to compute
 */
export const isDateExpired = targetDate => {
  let diff
  if (targetDate instanceof dayjs) {
    diff = targetDate.diff(dayjs())
  }
  diff = dayjs(targetDate).diff(dayjs())
  return diff <= 0 // returns true if today is the expired date or if expired date has passed
}

/**
 * converts string boolean to boolean
 * @param {string} strBool boolean value as string
 */
export const stringToBool = strBool => strBool === 'true'

/**
 * opens given url in a new tab
 * @param {string} url url string
 */
export const openInNewTab = url => {
  const win = window.open(url, '_blank')
  if (win != null) {
    win.focus()
  }
}

/**
 * Formats the input value to the preferred currency
 * @param {number} value value to be displayed
 * @param {string} currency currency unit to format the value with
 */
export function currencyFormatter(value, currency = 'INR') {
  if (typeof value === 'string') value = parseFloat(value)
  return new window.Intl.NumberFormat('en-IN', { style: 'currency', currency }).format(value)
}

/**
 * When injecting html into react, we need to sanitize it to prevent
 * XSRF attacks. This function returns a default list as well as
 * specialized list for the module concerned
 *
 * @param {string} module name of module to fetch allowed tags for
 */
export function getAllowedTags(module) {
  const defaultList = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'p',
    'a',
    'ul',
    'ol',
    'nl',
    'li',
    'b',
    'i',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'ins',
    'del',
  ]
  switch (module) {
    default:
      return defaultList
  }
}
