import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { PacksResponse } from '../types/Packs'
import { Filter, Platform, PlatformsResponse } from '../types/Platforms'

/**
 * Returns list of platforms
 */
export function listPlatforms(filters: any) {
  return httpService.get('/v4/content/sources/platforms/', {
    params: filters,
  })
}

/**
 * Returns list of platform groups
 */
export function listPlatformGroups(languageId: number) {
  return httpService.get('/v4/content/sources/platform-groups/', {
    params: {
      languageId,
    },
  })
}

export const getPlatforms = (
  filter: Filter,
): Promise<AxiosResponse<PlatformsResponse | Platform[]>> => {
  return httpService.get(`/v7/content/sources/platforms/`, {
    params: {
      ...filter,
    },
  })
}

export const getPlatform = (platformId: number): Promise<AxiosResponse<Platform>> => {
  return httpService.get(`/v7/content/sources/platforms/${platformId}/`)
}

/**
 * Create platform
 * @param platformDetails
 * @returns
 */
export function createPlatform(platformDetails: FormData): Promise<AxiosResponse<Platform>> {
  return httpService.post(`/v7/content/sources/platforms/`, platformDetails)
}

/**
 * Update platform
 * @param platformId
 * @param platformDetails
 * @returns
 */
export function updatePlatform(
  platformId: number,
  platformDetails: FormData,
): Promise<AxiosResponse<Platform>> {
  return httpService.put(`/v7/content/sources/platforms/${platformId}/`, platformDetails)
}

export const getPlatformPacks = (
  platformId: number,
  filter: Filter,
): Promise<AxiosResponse<PacksResponse>> => {
  return httpService.get(`/v7/content/sources/platforms/${platformId}/packs`, {
    params: {
      ...filter,
    },
  })
}
