import { AxiosResponse } from 'axios';
import httpService from '../httpService';
import { QuestionRequest, QuestionResponse, Questions } from '../redux/goldQuestions/types';


export function listQuestions(): Promise<AxiosResponse<Questions>> {
    return httpService.get(`/v6/gold/questions/`)
}


export function getQuestionDetail(questionId: string): Promise<AxiosResponse<QuestionResponse>> {
    return httpService.get(`/v6/gold/questions/${questionId}`)
}

export function createQuestion(
    questionDetails: QuestionRequest,
): Promise<AxiosResponse<QuestionResponse>> {
    return httpService.post(`/v6/gold/questions/`, questionDetails)
}

export function updateQuestion(
    questionId: string,
    questionDetails: QuestionRequest,
): Promise<AxiosResponse<QuestionResponse>> {
    return httpService.post(`/v6/gold/questions/${questionId}`, questionDetails)
}

export function deleteQuestion(questionId: number): Promise<AxiosResponse> {
    return httpService.delete(`/v6/gold/questions/${questionId}`)
}
