import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import { isEmpty, omit } from 'lodash'
import {
  FETCH_HD_VIDEOS,
  FETCH_PACKS,
  FETCH_TRACKS,
  FETCH_PLAYLISTS,
  FETCH_VIDEOS,
  SET_SELECTED_PACK,
  SET_SELECTED_PLAYLIST,
  REVOKE_REMOVE_VIDEO_DATA,
  SET_DEFAULT_VIDEOS_FILTERS,
  SET_DEFAULT_PLAYLISTS_FILTERS,
  FETCH_SORTED_VIDEO_PACKS,
  FETCH_QA_VIDEOS,
} from './actions'
import { pushParamsToUrl } from '../../helper/urlParamSerializer'
import {
  getHDVideos,
  getVideoPacks,
  getTracks,
  getPlaylists,
  getVideos,
  getSortedVideoPacks,
  getQAVideos,
} from '../../api/videos'
import { showErrorNotification } from '../notifications/actions'
import {
  Filter,
  HDVideosAction,
  PlaylistsAction,
  VideosAction,
  SelectedPackAction,
  SelectedPlaylistAction,
  SetDefaultFilterAction,
  VideoPackAction,
  VideoPack,
  Meta,
  QaVideosListingAction,
  QaVideoListingFilter,
  TracksResponse,
} from './types'
import {
  requestHDVideos,
  receivedHDVideos,
  requestedHDVideosFailed,
  requestPacks,
  receivedPacks,
  requestedPacksFailed,
  requestedTracks,
  receivedTracks,
  requestedTracksFailed,
  requestPlaylists,
  receivedPlaylists,
  requestedPlaylistsFailed,
  requestVideos,
  receivedVideos,
  removeVideoData,
  requestedVideosFailed,
  receivedSelectedPack,
  receivedSelectedPlaylist,
  setDefaultVideosFilter,
  setDefaultPlaylistsFilter,
  receivedSortedVideoPacks,
  requestSortedVideoPacks,
  requestedSortedVideoPacksFailed,
  requestQaVideoListing,
  receivedQaVideoListing,
  requestedVideoListingFailed,
} from './slice'
import { AxiosResponse } from 'axios'

/**
 * Invoked when getHDVideos is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* GET_VIDEO_PACKS({ payload }: HDVideosAction) {
  try {
    yield put(requestPacks())
    const filter = payload as Filter
    const prevFilter: Filter = yield select(state => state.videoPacks.list.filter)
    let newFilter: Filter = {
      ...prevFilter,
      ...filter,
    }
    if (!newFilter.lookup) yield pushParamsToUrl(newFilter)
    newFilter = omit(newFilter, ['lookup'])
    const response: AxiosResponse = yield call(getVideoPacks, newFilter as Filter)
    const { results: data, ...meta } = response.data
    yield put(receivedPacks({ data, meta, filter: newFilter }))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching video packs'))
    yield put(requestedPacksFailed())
  }
}

/**
 * Invoked when getSortedVideoPacks is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */

export function* GET_SORTED_VIDEO_PACKS({ payload }: VideoPackAction) {
  try {
    let response: AxiosResponse | null = null
    const filter = payload as Filter
    const prevFilter: Filter = yield select(state => state.sortedVideoPacks.list.filter)
    const newFilter: Filter = {
      ...prevFilter,
      ...filter,
    }
    const { trackId, languageId } = newFilter
    yield pushParamsToUrl(newFilter)
    if (trackId && languageId) {
      yield put(requestSortedVideoPacks())
      response = yield call(getSortedVideoPacks, newFilter as Filter)
    }

    if (response) {
      const { results: data, ...meta } = response.data
      yield put(receivedSortedVideoPacks({ data, meta, filter: newFilter }))
    } else {
      const data: VideoPack[] = []
      const meta: Meta = { page: 1, pageCount: 1, limit: 20 }
      yield put(receivedSortedVideoPacks({ data, meta, filter: newFilter }))
    }
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching sorted video packs'))
    yield put(requestedSortedVideoPacksFailed())
  }
}

/**
 * Invoked when getPlaylists is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* GET_PLAYLISTS({ payload: { packId, filter } }: PlaylistsAction) {
  try {
    yield put(requestPlaylists())
    const filterData = filter as Filter
    const prevFilter: Filter = yield select(state => state.videoPlaylists.list.filter)
    const newFilter: Filter = {
      ...prevFilter,
      ...filterData,
    }
    yield pushParamsToUrl(newFilter)
    const response: AxiosResponse = yield call(getPlaylists, packId, newFilter)
    const { results: data, ...meta } = response.data
    yield put(receivedPlaylists({ data, meta, filter: newFilter }))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching video playlist'))
    yield put(requestedPlaylistsFailed())
  }
}

/**
 * Invoked when getPlaylists is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* GET_VIDEOS({ payload: { playlistId, filter } }: VideosAction) {
  try {
    yield put(requestVideos())
    const filterData = filter as Filter
    const prevFilter: Filter = yield select(state => state.videoPlaylists.list.filter)
    const newFilter: Filter = {
      ...prevFilter,
      ...filterData,
    }
    yield pushParamsToUrl(newFilter)
    const response: AxiosResponse = yield call(getVideos, playlistId, newFilter)
    const { results: data, ...meta } = response.data
    yield put(receivedVideos({ data, meta, filter: newFilter }))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching videos'))
    yield put(requestedVideosFailed())
  }
}

/**
 * Invoked when getTracks is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* GET_TRACKS() {
  try {
    // if tracks data is already present in the store
    const isTracksLoaded: boolean = yield select(state => !isEmpty(state.videoPacks.tracks.data))
    if (isTracksLoaded) return

    yield put(requestedTracks())

    const response: AxiosResponse = yield call(getTracks)

    const { data }: TracksResponse = response

    yield put(receivedTracks({ data }))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching tracks'))
    yield put(requestedTracksFailed())
  }
}

/**
 * Invoked when getHDVideos is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* GET_HD_VIDEOS_LIST({ payload }: HDVideosAction) {
  try {
    yield put(requestHDVideos())
    const filter = payload as Filter
    const prevFilter: Filter = yield select(state => state.hDVideos.list.filter)
    const newFilter: Filter = {
      ...prevFilter,
      ...filter,
    }
    yield pushParamsToUrl(newFilter)
    const response: AxiosResponse = yield call(getHDVideos, newFilter as Filter)
    const { results: data, ...meta } = response.data
    yield put(receivedHDVideos({ data, meta, filter: newFilter }))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching converted videos'))
    yield put(requestedHDVideosFailed())
  }
}

/**
 * Invoked when setSelectedPack is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* SELECTED_PACK({ payload }: SelectedPackAction) {
  try {
    yield put(receivedSelectedPack(payload))
  } catch (error) {
    yield put(
      showErrorNotification('Something went wrong while setting selected pack to redux store'),
    )
  }
}

/**
 * Invoked when setSelectedPlaylist is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* SELECTED_PLAYLIST({ payload }: SelectedPlaylistAction) {
  try {
    yield put(receivedSelectedPlaylist(payload))
  } catch (error) {
    yield put(
      showErrorNotification('Something went wrong while setting selected pack to redux store'),
    )
  }
}

export function* REMOVE_VIDEO_DATA() {
  try {
    yield put(removeVideoData())
  } catch (error) {
    yield put(
      showErrorNotification('Something went wrong while removing video data from redux store'),
    )
  }
}

export function* SET_DEFAULT_FILTER({ name }: SetDefaultFilterAction) {
  try {
    switch (name) {
      case 'videos':
        yield put(setDefaultVideosFilter())
        break
      case 'playlists':
        yield put(setDefaultPlaylistsFilter())
        break
      default:
        yield put(showErrorNotification(`Something went wrong while removing ${name} filters`))
    }
  } catch (error) {
    yield put(showErrorNotification(`Something went wrong while removing ${name} filters`))
  }
}

/**
 * Invoked when getQAVideos is dispatched
 * @param {Object} object containing type and payload <Filter object>
 */
export function* GET_QA_VIDEOS({ payload }: QaVideosListingAction) {
  try {
    yield put(requestQaVideoListing())
    const filter = payload as QaVideoListingFilter
    const prevFilter: Filter = yield select(state => state.qaVideos.list.filter)
    let newFilter: QaVideoListingFilter = {
      ...prevFilter,
      ...filter,
    }
    yield pushParamsToUrl(newFilter)
    const response: AxiosResponse = yield call(getQAVideos, newFilter as QaVideoListingFilter)
    const { results: data, ...meta } = response.data
    yield put(receivedQaVideoListing({ data, meta, filter: newFilter }))
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching QA videos'))
    yield put(requestedVideoListingFailed())
  }
}

export default function* rootSaga() {
  yield all([takeLatest(FETCH_PACKS, GET_VIDEO_PACKS)])
  yield all([takeLatest(FETCH_HD_VIDEOS, GET_HD_VIDEOS_LIST)])
  yield all([takeLatest(FETCH_TRACKS, GET_TRACKS)])
  yield all([takeLatest(FETCH_PLAYLISTS, GET_PLAYLISTS)])
  yield all([takeLatest(FETCH_VIDEOS, GET_VIDEOS)])
  yield all([takeLatest(SET_SELECTED_PACK, SELECTED_PACK)])
  yield all([takeLatest(SET_SELECTED_PLAYLIST, SELECTED_PLAYLIST)])
  yield all([takeLatest(REVOKE_REMOVE_VIDEO_DATA, REMOVE_VIDEO_DATA)])
  yield all([takeLatest(SET_DEFAULT_PLAYLISTS_FILTERS, SET_DEFAULT_FILTER)])
  yield all([takeLatest(SET_DEFAULT_VIDEOS_FILTERS, SET_DEFAULT_FILTER)])
  yield all([takeLatest(FETCH_SORTED_VIDEO_PACKS, GET_SORTED_VIDEO_PACKS)])
  yield all([takeLatest(FETCH_QA_VIDEOS, GET_QA_VIDEOS)])
}
