import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../..";
import { BatchListPayload, BatchListState, BatchResponse, IFilter } from "./types";
import { listBatches } from "../../api/batches";

const initialState: BatchListState = {
  data: [],
  filter: {
    page: 1,
    pageSize: 1,
  },
  hasError: false,
  isLoading: false,
  meta: {
    limit: 20,
    page: 1,
    pageCount: 1,
    totalObjectCount: 1,
  },
};

const BatchListSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {
    requestBatches: state => {
      return { ...state, isLoading: true, hasError: false };
    },
    requestedBatchesFailed(state) {
      return { ...state, isLoading: false, hasError: true };
    },
    recievedBatches(state, action: PayloadAction<BatchListPayload>) {
      const { data, filter, meta } = action.payload;
      return { ...state, data, meta, filter, isLoading: false, hasError: false };
    },
  },
});

export const { recievedBatches, requestBatches, requestedBatchesFailed } = BatchListSlice.actions;

function instanceOfBatchListResponse(object: any): object is BatchResponse {
  return "results" in object;
}

export const fetchBatches =
  (filter: IFilter): AppThunk =>
  async dispatch => {
    dispatch(requestBatches());
    try {
      const response = await listBatches(filter);
      if (response) {
        if (instanceOfBatchListResponse(response.data)) {
          const { results: data, ...meta } = response.data;
          dispatch(
            recievedBatches({
              data,
              filter,
              meta,
            }),
          );
        } else {
          const data = response.data;
          dispatch(
            recievedBatches({
              data,
              filter,
            }),
          );
        }
      }
    } catch (error) {
      dispatch(requestedBatchesFailed());
    }
  };

export const selectBatches = (state: RootState) => state.batchListing.list;

export default combineReducers({
  list: BatchListSlice.reducer,
});
