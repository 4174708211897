import { all, put, call, takeLatest } from 'redux-saga/effects'
import { getProductPackages, getQuestions } from '../../api/productPackages';
import { showErrorNotification } from '../notifications/actions';
import {
    requestProductPackages,
    receivedProductPackages,
    errorOnProductPackage,
    requestPackageDetails,
    receivedPackageDetails,
    errorOnPackageDetails
} from './slice';
import { PayloadType, ProductPackageQuestions, ProductPackages, ListPayloadType } from './types';



export function* LIST_PRODUCT_PACKAGES({ payload }: ListPayloadType) {
    try {
        const response: { data: ProductPackages } = yield call(getProductPackages, payload);
        yield put(receivedProductPackages((response.data)))
    } catch (error) {
        yield put(showErrorNotification('Something went wrong while fetching Product Packages'))
        yield put(errorOnProductPackage())
    }
}

export function* DETAIL_PRODUCT_PACKAGES({ payload }: PayloadType) {
    try {
        const response: { data: ProductPackageQuestions } = yield call(getQuestions, payload);
        yield put(receivedPackageDetails((response.data)))
    } catch (error) {
        yield put(showErrorNotification('Something went wrong while fetching Questions'))
        yield put(errorOnPackageDetails())
    }
}


export default function* rootSaga() {
    yield all([
        takeLatest(requestProductPackages.type, LIST_PRODUCT_PACKAGES),
        takeLatest(requestPackageDetails.type, DETAIL_PRODUCT_PACKAGES),
    ])
}