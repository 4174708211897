import httpService from '../httpService'
import { ThumbnailFile } from '../types/Files'

/**
 * Uploads new file
 * @param {Object} payload file object
 */
export function uploadFile(file: any) {
  const formData = new FormData()
  formData.append('file', file, file.name)
  return httpService.post('/v4/content/sources/media/', formData)
}

/**
 * Uploads new file
 * @param {Object} payload file object
 */
export function uploadImage(file: Blob) {
  const formData = new FormData()
  formData.append('image_file', file)
  return httpService.post('/v4/content/file-upload/', formData)
}
/**
 * Uploads new file
 * @param {Object} payload file object
 */
export function questionsImageUpload(file: Blob) {
  const formData = new FormData()
  formData.append('image_file', file)
  return httpService.post('/v4/content/image-upload/', formData)
}

/**
 * Get files related to a job
 * @param {Number} jobId Job id
 */
export function getJobFiles(jobId: Number) {
  return httpService.get(`/v4/content/sources/media/job/${jobId}/`)
}

/**
 * Deletes a file
 * @param {Number} fileId File id
 */
export function deleteFile(fileId: Number) {
  return httpService.delete(`/v4/content/sources/media/${fileId}/`)
}

/**
 * Uploads new thumbnail file
 * @param {Object} payload file object
 */
export function uploadThumbnailFile(
  thumbnailFormData: FormData,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
) {
  return httpService.post('/v7/content/sources/media/packs/thumbnails/', thumbnailFormData, {
    onUploadProgress,
  })
}
