const actions = {
  GET_LIST: 'modules/GET_LIST',
  GET_LIST_WITH_SUBJECT: 'modules/GET_LIST_WITH_SUBJECT',
  SET_LIST: 'modules/SET_LIST',
}

export default actions

export const getModules = filter => ({
  type: actions.GET_LIST,
  payload: {
    ...filter,
  },
})

export const getModulesWithSubject = (subjects, platform, language) => ({
  type: actions.GET_LIST_WITH_SUBJECT,
  payload: {
    subjects,
    platform,
    language,
  },
})
