import { all, put, call, takeLatest } from 'redux-saga/effects'
import { getQuestionDetail, listQuestions } from '../../api/goldQuestions';
import { QuestionResponse, Questions } from './types';
import {
    requestQuestions,
    receivedQuestions,
    errorOnQuestions,
    requestQuestionDetail,
    receivedQuestionDetail,
    errorOnQuestionDetail
} from './slice';
import { showErrorNotification } from '../notifications/actions';

export function* LIST_QUESTIONS() {
    try {
        const response: { data: Questions } = yield call(listQuestions);
        yield put(receivedQuestions((response.data)))
    } catch (error) {
        yield put(showErrorNotification('Something went wrong while fetching Questions'))
        yield put(errorOnQuestions())

    }
}

interface QuestionDetailPayload {
    type: string,
    payload: string
}

export function* QUESTION_DETAIL({ payload }: QuestionDetailPayload) {
    try {
        const response: { data: QuestionResponse } = yield call(getQuestionDetail, payload);
        yield put(receivedQuestionDetail((response.data)))
    } catch (error) {
        yield put(showErrorNotification('Something went wrong while fetching Question'))
        yield put(errorOnQuestionDetail())

    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(requestQuestions.type, LIST_QUESTIONS),
        takeLatest(requestQuestionDetail.type, QUESTION_DETAIL)
    ])

}