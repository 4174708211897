import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import { message } from 'antd'
import {
  getPosts,
  getPost,
  getComments,
  getCommentReplies,
  deleteDiscuss,
  markPostReplyRequired,
  postReply,
  postComment,
  patchReply,
  markCommentReplyNotRequired,
  patchComment,
} from '../../api/discuss'
import {
  FETCH_COMMENTS,
  FETCH_REPLIES,
  FETCH_DISCUSS_POST,
  FETCH_DISCUSS_POSTS,
  REMOVE_DISCUSS,
  UPDATE_DISCUSS_POSTS,
  CREATE_COMMENT,
  CREATE_REPLY,
  UPDATE_REPLY,
  UPDATE_COMMENT,
  MARK_COMMENT_REPLY_NOT_REQUIRED,
} from './actions'
import {
  receivedDiscusses,
  requestDiscusses,
  requestedDiscussesFailed,
  requestedDiscussesSucceeded,
  updatePosts,
  receivedDiscuss,
  requestDiscuss,
  requestedDiscussFailed,
  requestedDiscussSucceeded,
  requestComments,
  receivedComments,
  requestedCommentsFailed,
  requestedCommentsSucceeded,
  requestReplies,
  updateComment,
  updateComments,
  updateReplies,
  updatePost,
  updateReply,
  markReplyRequired,
} from './slice'

import {
  showErrorNotification,
  showSuccessMessage,
  showErrorMessage,
} from '../notifications/actions'
import {
  DiscussActionImpl,
  FilterImpl,
  CommentGetImpl,
  RepliesGetImpl,
  DiscussPostPatchImpl,
  DiscusDeleteImpl,
  PostCommentImpl,
  PostCommentReplyImpl,
  PatchReplyImpl,
  PatchCommentImpl,
  CommentUpdateImpl,
  DiscussPostImpl,
} from './types'
import { AxiosError, AxiosResponse } from 'axios'
/**
 * Invoked when fetch Discuss posts is dispatched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* GET_DISCUSS_LIST({ payload }: DiscussActionImpl) {
  try {
    yield put(requestDiscusses())
    const filter = payload as FilterImpl
    const prevFilter: FilterImpl = yield select(state => state.discusses.list.filter)
    const newFilter: FilterImpl = {
      ...prevFilter,
      ...filter,
    }
    const response: AxiosResponse = yield call(getPosts, newFilter as FilterImpl)
    const { results: data, ...meta } = response.data
    yield put(receivedDiscusses({ data, meta, filter: newFilter }))
    yield put(requestedDiscussesSucceeded())
  } catch (error) {
    yield put(showErrorNotification('Something went wrong while fetching discusses'))
    yield put(requestedDiscussesFailed())
  }
}

/**
 * Invoked when FETCH discuss post is dispatched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* GET_DISCUSS_POST({ payload }: DiscussActionImpl) {
  try {
    const postId = payload as number
    yield put(requestDiscuss())
    const response: AxiosResponse = yield call(getPost, postId)
    const { data } = response
    yield put(receivedDiscuss(data))
    yield put(requestedDiscussSucceeded())
  } catch (error: any) {
    yield put(showErrorNotification('Something went wrong while fetching post'))
    yield put(requestedDiscussFailed(error.response?.status))
  }
}

/**
 * Invoked when PATCH discuss post is dispatched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* PATCH_DISCUSS_POSTS({ payload }: DiscussActionImpl) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { postId, postDetails } = payload as DiscussPostPatchImpl
    const { replyRequired = false } = postDetails
    const response: AxiosResponse = yield call(markPostReplyRequired, postId, replyRequired)
    yield put(markReplyRequired({ postId, postDetails: response.data }))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when FETCH post comments is dispatched
 * @param {Object} object containing action and payload <Filter object> and post id
 */
export function* GET_POST_COMMENT({ payload }: DiscussActionImpl) {
  try {
    const { postId, filter } = payload as CommentGetImpl
    yield put(requestComments(filter))
    const response: AxiosResponse = yield call(getComments, postId, filter)
    const { results: data, ...meta } = response.data
    yield put(receivedComments({ data, meta, filter }))
    yield put(requestedCommentsSucceeded())
  } catch (error) {
    console.log(error)
    yield put(showErrorNotification('Something went wrong while fetching comments'))
    yield put(requestedCommentsFailed())
  }
}

/**
 * Invoked when FETCH comment replies is dispatched
 * @param {Object} object containing action and payload <Filter object> and post id
 */
export function* GET_COMMENT_REPLIES({ payload }: DiscussActionImpl) {
  try {
    const { postId, commentId, filter } = payload as RepliesGetImpl
    yield put(requestReplies())
    const response: AxiosResponse = yield call(getCommentReplies, postId, commentId, filter)
    const { results: data, ...meta } = response.data
    yield put(updateReplies({ replies: { data, meta, filter }, commentId }))
  } catch (error) {
    console.log(error)
    yield put(showErrorNotification('Something went wrong while fetching replies'))
    yield put(requestedCommentsFailed())
  }
}

/**
 * Invoked when POST comment is dispatched
 * @param {Object} object containing action and payload <Filter object> and post id
 */
export function* POST_COMMENT({ payload }: DiscussActionImpl) {
  const hideMessage = message.loading('Posting...', 0)
  try {
    const { commentDetails, postId } = payload as PostCommentImpl
    const response: AxiosResponse = yield call(postComment, postId, commentDetails)
    const { data } = response
    const post: DiscussPostImpl = yield select(state => state.discusses.details.data)
    const { noOfComments = 0 } = post
    yield put(updatePost({ ...post, noOfComments: noOfComments + 1 }))
    yield put(updateComments({ comment: data }))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    console.log(error)
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when POST reply is dispatched
 * @param {Object} object containing action and payload <Filter object> and post id
 */
export function* POST_REPLY({ payload }: DiscussActionImpl) {
  const hideMessage = message.loading('Posting...', 0)
  try {
    const { postId, commentId, replyDetails } = payload as PostCommentReplyImpl
    const response: AxiosResponse = yield call(postReply, postId, commentId, replyDetails)
    const { data } = response
    yield put(updateReplies({ replies: { data }, commentId }))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    console.log(error)
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when patch comment is dispatched
 * @param {Object} object containing action and payload <React.Fragment> and post id
 */
export function* PATCH_COMMENT({ payload }: DiscussActionImpl) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { postId, commentId, commentDetails } = payload as PatchCommentImpl
    const response: AxiosResponse = yield call(patchComment, postId, commentId, commentDetails)
    const { data } = response
    yield put(updateComment({ commentId, comment: data }))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    console.log(error)
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when PATCH reply is dispatched
 * @param {Object} object containing action and payload <React.Fragment> and post id
 */
export function* PATCH_REPLY({ payload }: DiscussActionImpl) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { postId, replyId, commentId, replyDetails } = payload as PatchReplyImpl
    const response: AxiosResponse = yield call(patchReply, postId, replyId, replyId, replyDetails)
    const { data } = response
    yield put(updateReply({ replyId, commentId, reply: data }))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    console.log(error)
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when comment reply not required is dispatched
 * @param {Object} object containing action and payload <Filter object>
 */
export function* COMMENT_REPLY_NOT_REQUIRED({ payload }: DiscussActionImpl) {
  const hideMessage = message.loading('Updating...', 0)
  try {
    const { commentId, replyRequired = false } = payload as CommentUpdateImpl
    if (commentId) {
      const response: AxiosResponse = yield call(
        markCommentReplyNotRequired,
        commentId,
        replyRequired,
      )
      const { data } = response
      yield put(updateComment({ commentId, comment: data }))
      yield hideMessage()
      yield put(showSuccessMessage('Success!', 1))
    }
  } catch (error) {
    console.log(error)
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

/**
 * Invoked when DELETE post/comment/reply is dispatched
 * @param {Object} object containing action and payload <React.Fragment> and post id
 */
export function* DELETE_DISCUSS({ payload }: DiscussActionImpl) {
  const hideMessage = message.loading('Deleting...', 0)
  try {
    const { type, id, commentId } = payload as DiscusDeleteImpl
    const post: DiscussPostImpl = yield select(state => state.discusses.details.data)
    const { noOfComments = 1 } = post
    const response: AxiosResponse = yield call(deleteDiscuss, id)
    if (type === 'comment') {
      yield put(updatePost({ ...post, noOfComments: noOfComments - 1 }))
      yield put(updateComment({ isDelete: true, commentId: id }))
    } else if (type === 'reply' && commentId)
      yield put(updateReplies({ replyId: id, commentId, isDelete: true }))
    else if (type === 'post') yield put(updatePosts(id))
    yield hideMessage()
    yield put(showSuccessMessage('Success!', 1))
  } catch (error) {
    console.log(error)
    yield hideMessage()
    yield put(showErrorMessage('Failed', 1))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(FETCH_DISCUSS_POSTS, GET_DISCUSS_LIST)])
  yield all([takeLatest(FETCH_DISCUSS_POST, GET_DISCUSS_POST)])
  yield all([takeLatest(UPDATE_DISCUSS_POSTS, PATCH_DISCUSS_POSTS)])
  yield all([takeLatest(FETCH_COMMENTS, GET_POST_COMMENT)])
  yield all([takeLatest(FETCH_REPLIES, GET_COMMENT_REPLIES)])
  yield all([takeLatest(CREATE_COMMENT, POST_COMMENT)])
  yield all([takeLatest(CREATE_REPLY, POST_REPLY)])
  yield all([takeLatest(UPDATE_COMMENT, PATCH_COMMENT)])
  yield all([takeLatest(MARK_COMMENT_REPLY_NOT_REQUIRED, COMMENT_REPLY_NOT_REQUIRED)])
  yield all([takeLatest(UPDATE_REPLY, PATCH_REPLY)])
  yield all([takeLatest(REMOVE_DISCUSS, DELETE_DISCUSS)])
}
