import { PermissionLevels, Permissions, ViewPermissions } from '../types/AccessControls'

export const checkUserHasPermission = (
  requiredPermission: Permissions,
  userAllowedPermission?: Permissions[],
) => {
  if (!userAllowedPermission) return false
  const permissionIndex = userAllowedPermission.findIndex(
    permissionItem => permissionItem === requiredPermission,
  )
  const userHasRequiredPermission = permissionIndex >= 0
  return userHasRequiredPermission
}

export const checkUserHasViewPermission = (
  permissionLevel?: PermissionLevels,
  viewPermissions?: ViewPermissions,
) => {
  return permissionLevel ? viewPermissions?.[permissionLevel] : false
}
