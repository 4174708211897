const actions = {
  GET_LIST: 'language/GET_LIST',
  SET_LIST: 'language/SET_LIST',
}

export default actions

export const getLanguages = () => ({
  type: actions.GET_LIST,
})
