import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { AppThunk, RootState } from '../..'
import { getTrackCategories, getTrackCategory } from '../../api/trackCategory'
import {
  Filter,
  TrackCategoriesPayload,
  TrackCategoriesResponse,
  TrackCategoriesState,
  TrackCategory,
  TrackCategoryState,
} from '../../types/TrackCategory'

// Track Category Listing Slice

export const initialTrackCategoriesList: TrackCategoriesState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const trackCategoriesListSlice = createSlice({
  name: 'trackCategoryListing',
  initialState: initialTrackCategoriesList,
  reducers: {
    requestTrackCategories(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedTrackCategoriesFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedTrackCategories(state, action: PayloadAction<TrackCategoriesPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: Filter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
  },
})

export const { receivedTrackCategories, requestTrackCategories, requestedTrackCategoriesFailed } =
  trackCategoriesListSlice.actions

const instanceOfTrackCategoriesResponse = (object: any): object is TrackCategoriesResponse => {
  return 'results' in object
}

export const fetchTrackCategories =
  (filter?: Filter): AppThunk =>
  async dispatch => {
    dispatch(requestTrackCategories())
    try {
      const response = await getTrackCategories(filter)
      if (response) {
        if (instanceOfTrackCategoriesResponse(response.data)) {
          const { results: data, ...meta } = response.data
          dispatch(
            receivedTrackCategories({
              data,
              filter,
              meta,
            }),
          )
        } else {
          const data = response.data
          dispatch(
            receivedTrackCategories({
              data,
              filter,
            }),
          )
        }
      }
    } catch (error) {
      dispatch(requestedTrackCategoriesFailed())
    }
  }

export const selectTrackCategoryList = (state: RootState) => state.trackCategory.list

// Track Category Slice

export const initialTrackCategory: TrackCategoryState = {
  data: null,
  isLoading: false,
  hasError: false,
}

const trackCategorySlice = createSlice({
  name: 'trackCategory',
  initialState: initialTrackCategory,
  reducers: {
    requestTrackCategory(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedTrackCategoryFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedTrackCategory(state, action: PayloadAction<TrackCategory>) {
      return { ...state, data: action.payload, isLoading: false, hasError: false }
    },
  },
})

const { requestTrackCategory, receivedTrackCategory, requestedTrackCategoryFailed } =
  trackCategorySlice.actions

export const fetchTrackCategory =
  (trackCategoryId: number): AppThunk =>
  async dispatch => {
    dispatch(requestTrackCategory())
    try {
      const response = await getTrackCategory(trackCategoryId)
      if (response) {
        dispatch(receivedTrackCategory(response.data))
      }
    } catch (error) {
      dispatch(requestedTrackCategoryFailed())
    }
  }

export const selectTrackCategory = (state: RootState) => state.trackCategory.details

export default combineReducers({
  list: trackCategoriesListSlice.reducer,
  details: trackCategorySlice.reducer,
})
