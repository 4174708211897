import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { AppThunk, RootState } from '../..'
import { getSkillingLessons, getSkillingPacks, getSkillingSections } from '../../api/skillingPack'
import {
  Content,
  Lesson,
  LessonState,
  Section,
  SectionState,
  SkillingPack,
} from '../../types/Skilling'

export const initialPacksList: SkillingPack.PackListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const SkillingPacksListSlice = createSlice({
  name: 'skillingPackList',
  initialState: initialPacksList,
  reducers: {
    requestPacks(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedPacksFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedPacks(state, action: PayloadAction<SkillingPack.PackListPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: SkillingPack.Filter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
  },
})

export const { receivedPacks, requestPacks, requestedPacksFailed } = SkillingPacksListSlice.actions

export const fetchSkillingPack =
  (filter: SkillingPack.Filter): AppThunk =>
  async dispatch => {
    dispatch(requestPacks())
    try {
      const response = await getSkillingPacks(filter)
      if (response) {
        const { results: data, ...meta } = response.data
        dispatch(
          receivedPacks({
            data,
            filter,
            meta,
          }),
        )
      }
    } catch (error) {
      dispatch(requestedPacksFailed())
    }
  }

export const selectSkillingPacks = (state: RootState) => state.skilling.packList

/**
 * Sections
 */

const initialSectionState: SectionState = {
  data: {
    lessons: null,
    extras: null,
  },
  isLoading: false,
  hasError: false,
}

const SkillingSectionsSlice = createSlice({
  name: 'skillingSections',
  initialState: initialSectionState,
  reducers: {
    requestSections(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedSectionsFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedSections(state, action: PayloadAction<Section[]>) {
      const data = [...action.payload]
      state.data.lessons = data
      state.isLoading = false
      state.hasError = false
    },
    receivedExtras(state, action: PayloadAction<Section[]>) {
      const data = [...action.payload]
      state.data.extras = data
      state.isLoading = false
      state.hasError = false
    },
    updateSection(state, action: PayloadAction<Section>) {
      const section = action.payload
      if (section.category === 'NORMAL') {
        const sectionIndex = state.data.lessons!.findIndex(
          sectionItem => sectionItem.id === section.id,
        )
        if (sectionIndex >= 0) {
          state.data.lessons![sectionIndex] = section
        }
      } else {
        const sectionIndex = state.data.extras!.findIndex(
          sectionItem => sectionItem.id === section.id,
        )
        if (sectionIndex >= 0) {
          state.data.extras![sectionIndex] = section
        }
      }
    },
    resetSections() {
      return initialSectionState
    },
  },
})
export const {
  receivedSections,
  receivedExtras,
  requestSections,
  requestedSectionsFailed,
  resetSections,
  updateSection,
} = SkillingSectionsSlice.actions

export const selectSkillingSections = (state: RootState) => state.skilling.sections

export const fetchSkillingSections =
  (packId: string): AppThunk =>
  async dispatch => {
    dispatch(requestSections())
    try {
      const response = await getSkillingSections(packId, 'NORMAL')
      if (response) {
        dispatch(receivedSections(response.data))
      }
    } catch (error) {
      dispatch(requestedPacksFailed())
    }
  }

export const fetchSkillingExtras =
  (packId: string): AppThunk =>
  async dispatch => {
    dispatch(requestSections())
    try {
      const response = await getSkillingSections(packId, 'EXTRAS')
      if (response) {
        dispatch(receivedExtras(response.data))
      }
    } catch (error) {
      dispatch(requestedPacksFailed())
    }
  }

/**
 * Lessons
 */

const initialLessonState: LessonState = {
  data: null,
  isLoading: false,
  hasError: false,
}

const SkillingLessonsSlice = createSlice({
  name: 'skillingLesson',
  initialState: initialLessonState,
  reducers: {
    requestLessons(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedLessonsFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedLessons(state, action: PayloadAction<Lesson[]>) {
      const data = action.payload
      return { ...state, data: data, isLoading: false, hasError: false }
    },
    updateLesson(state, action: PayloadAction<Lesson>) {
      const lessonToUpdate = action.payload
      state.data = state.data!.map(lesson =>
        lesson.id === lessonToUpdate.id ? lessonToUpdate : lesson,
      )
    },
    updateLessonContents(state, action: PayloadAction<{ lessonId: number; contents: Content[] }>) {
      const { lessonId, contents } = action.payload
      // Find the lesson to update
      const lessons = state.data
      const lessonIndex = lessons!.findIndex(lesson => lesson.id === lessonId)

      state.data![lessonIndex].contents = contents
    },
    updateLessonContent(state, action: PayloadAction<{ lessonId: number; content: Content }>) {
      const { lessonId, content } = action.payload
      // Find the lesson to update
      const lessons = state.data
      const lessonIndex = lessons!.findIndex(lesson => lesson.id === lessonId)

      state.data![lessonIndex].contents = lessons![lessonIndex].contents.map(contentItem => {
        if (contentItem.id === content.id) return content
        return contentItem
      })
    },
    addLessonContent(state, action: PayloadAction<{ lessonId: number; content: Content }>) {
      const { lessonId, content } = action.payload
      // Find the lesson to add content
      const lessons = state.data
      const lessonIndex = lessons!.findIndex(lesson => lesson.id === lessonId)
      const lessonContents = Array.from(state.data![lessonIndex].contents)

      state.data![lessonIndex].contents = [...lessonContents, content]
    },
    resetLessons() {
      return initialLessonState
    },
  },
})

export const {
  receivedLessons,
  requestLessons,
  requestedLessonsFailed,
  resetLessons,
  updateLessonContent,
  updateLessonContents,
  updateLesson,
  addLessonContent,
} = SkillingLessonsSlice.actions

export const selectSkillingLessons = (state: RootState) => state.skilling.lessons

export const fetchSkillingLessons =
  (sectionId: number, packId: number): AppThunk =>
  async dispatch => {
    dispatch(requestLessons())
    try {
      const response = await getSkillingLessons(sectionId, packId)
      if (response) {
        dispatch(receivedLessons(response.data))
      }
    } catch (error) {
      dispatch(requestedLessonsFailed())
    }
  }

export default combineReducers({
  packList: SkillingPacksListSlice.reducer,
  lessons: SkillingLessonsSlice.reducer,
  sections: SkillingSectionsSlice.reducer,
})
