const actions = {
  GET_LIST: 'subjects/GET_LIST',
  SET_LIST: 'subjects/SET_LIST',
}

export default actions

export const getSubjects = filter => ({
  type: actions.GET_LIST,
  payload: {
    ...filter,
  },
})
