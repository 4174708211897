import { Dictionary } from 'lodash'

export const REQUESTED_USERS = 'users/REQUESTED'
export const REQUESTED_USERS_FAILED = 'users/FAILED'
export const REQUESTED_USERS_SUCCEEDED = 'users/SUCCEEDED'
export const RECEIVED_USERS = 'users/RECEIVED'
export const FETCH_USERS = 'users/FETCH'

export const REQUESTED_USER = 'user/REQUESTED'
export const REQUESTED_USER_FAILED = 'user/FAILED'
export const REQUESTED_USER_SUCCEEDED = 'user/SUCCEEDED'
export const RECEIVED_USER = 'user/RECEIVED'
export const FETCH_USER = 'user/FETCH'
export const LOGIN_USER = 'user/LOGIN'
export const LOGOUT_USER = 'user/LOGOUT'
export const RESET_USER = 'user/RESET'

export enum UserRoles {
  admin = 'admin',
  contentReviewer = 'content-reviewer',
  dataSpecialist = 'data-specialist',
  academicHead = 'academic-head',
  videoEditor = 'video-editor',
  videoManager = 'video-manager',
}

// Categorized User Roles
export const userRoles = {
  superAdmins: [String(UserRoles.admin)],
  admins: [String(UserRoles.admin), String(UserRoles.academicHead)],
  users: [String(UserRoles.dataSpecialist), String(UserRoles.contentReviewer)],
  videoAdmins: [
    String(UserRoles.admin),
    String(UserRoles.videoManager),
    String(UserRoles.academicHead),
  ],
  videoUsers: [
    String(UserRoles.videoEditor),
    String(UserRoles.admin),
    String(UserRoles.videoManager),
    String(UserRoles.academicHead),
  ],
  all: [
    String(UserRoles.admin),
    String(UserRoles.academicHead),
    String(UserRoles.contentReviewer),
    String(UserRoles.dataSpecialist),
  ],
}

export interface UserActionImpl {
  type: string
  payload?: UserImpl | UsersImpl | UserPartialImpl[] | LoginImpl | LogoutImpl
}

export interface UserPartialImpl {
  id: number
  username: string
  role?: string
}

export interface UsersImpl {
  entities: Dictionary<UserPartialImpl>
  allIds: string[]
  adminIds: string[]
  reviewerIds: string[]
  specialistIds: string[]
  users: UserPartialImpl[]
}

export interface UserImpl {
  id: string
  name: string
  username?: string
  isVerified: Boolean
  role: UserRoles | null
  email: string
  avatar: string
  authorized: boolean
  isLoading: boolean
  hasError: boolean
  permissions?: any
  requestedPath: string
}

export interface IVertical {
  name: string,
  value: string,
}

export interface LoginImpl {
  phone: string
  password: string
}

export interface LogoutImpl {
  shouldCallAPI: Boolean
}

export interface UserFilter {
  username?: string
  questionReportAccess?: boolean
}

export interface UserGetAction {
  type: string
  payload: { filter: UserFilter }
}
