import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InitialLookupState } from './types'

export const initialLookupState: InitialLookupState = {
  teachers: {
    data: [],
    isLoading: false,
  },
  editors: {
    data: [],
    isLoading: false,
  },
  cameraOperators: {
    data: [],
    isLoading: false,
  },
  videoPlaylists: {
    data: [],
    isLoading: false,
    filters: {
      packIds: '',
    },
  },
}
const lookups = createSlice({
  name: 'Lookups',
  initialState: initialLookupState,
  reducers: {
    requestLookups(state, action) {
      const { name } = action.payload
      return {
        ...state,
        [name]: {
          ...state[name],
          isLoading: true,
        },
      }
    },
    receivedLookups(state, action: PayloadAction<any>) {
      const { data, name } = action.payload
      return {
        ...state,
        [name]: {
          ...state[name],
          data,
          isLoading: false,
        },
      }
    },
    requestedLookupsFailed(state, action) {
      const { name } = action.payload
      return {
        ...state,
        [name]: {
          ...state[name],
          isLoading: false,
        },
      }
    },
    requestVideoPlaylists(state) {
      return {
        ...state,
        videoPlaylists: {
          ...state.videoPlaylists,
          isLoading: true,
          hasError: false,
        },
      }
    },
    receivedVideoPlaylists(state, action: PayloadAction<any>) {
      const { data, filters } = action.payload
      return {
        ...state,
        videoPlaylists: {
          ...state.videoPlaylists,
          data,
          filters,
          isLoading: false,
          hasError: false,
        },
      }
    },
    requestedVideoPlaylistsFailed(state) {
      return {
        ...state,
        videoPlaylists: {
          ...state.videoPlaylists,
          isLoading: false,
          hasError: true,
        },
      }
    },
  },
})

export const {
  requestLookups,
  receivedLookups,
  requestedLookupsFailed,
  requestVideoPlaylists,
  receivedVideoPlaylists,
  requestedVideoPlaylistsFailed,
} = lookups.actions

export default lookups.reducer
