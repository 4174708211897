import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { Filter, PackListState, PackListPayload } from '../../types/Packs'
import { AppThunk, RootState } from '../..'
import { getPlatformPacks } from '../../api/platform'

export const initialPacksList: PackListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const PacksListSlice = createSlice({
  name: 'packListing',
  initialState: initialPacksList,
  reducers: {
    requestPacks(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedPacksFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedPacks(state, action: PayloadAction<PackListPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: Filter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
  },
})

export const { receivedPacks, requestPacks, requestedPacksFailed } = PacksListSlice.actions

export const fetchPlatformPacks =
  (platformId: number, filter: Filter): AppThunk =>
  async dispatch => {
    dispatch(requestPacks)
    try {
      const response = await getPlatformPacks(platformId, filter)
      if (response) {
        const { results: data, ...meta } = response.data
        dispatch(
          receivedPacks({
            data,
            filter,
            meta,
          }),
        )
      }
    } catch (error) {
      dispatch(requestedPacksFailed())
    }
  }

export const selectPacks = (state: RootState) => state.packs.list

export default combineReducers({
  list: PacksListSlice.reducer,
})
