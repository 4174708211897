export const NOTIFICATION_SUCCESS = 'notification/SUCCESS'
export const NOTIFICATION_ERROR = 'notification/ERROR'

export interface MessageImpl {
  duration?: number
  message: string
}

export interface NotificationImpl {
  title?: string
  description: string
}

export enum NotificationTypes {
  message = 'message',
  notification = 'notification',
}

export interface NotificationActionImpl {
  type: string
  payload: {
    type: NotificationTypes
    data: MessageImpl | NotificationImpl
  }
}
