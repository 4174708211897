import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import { keyBy } from 'lodash'
import { notification } from 'antd'
import getLanguages from 'api/languages'
import actions from './actions'

/**
 * Invoked when Get Language is dispatched
 */
export function* GET_LANGUAGES() {
  try {
    // is language data is already present in the store
    const isLangLoaded = yield select(state => !!state.languages.data)
    // is a fetch request currently running
    const isLangLoading = yield select(state => state.languages.isLoading)
    if (isLangLoaded || isLangLoading) return undefined

    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: true,
      },
    })
    const response = yield call(getLanguages)
    const codeByObject = keyBy(response?.data, 'code')
    yield put({
      type: actions.SET_LIST,
      payload: {
        data: [...response.data],
        codeByObject,
        isLoading: false,
      },
    })
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong while fetching languages',
    })
    yield put({
      type: actions.SET_LIST,
      payload: {
        isLoading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_LIST, GET_LANGUAGES)])
}
