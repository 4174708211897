import mixpanel from "mixpanel-browser";
import { EVENT_PREFIX } from "./tracker";

export const initializeMixPanel = (token?: string, debug: boolean = false) => {
  if (token) {
    mixpanel.init(token, { debug });
  } else {
    console.log("token not passed - MixPanel Init");
  }
};

export const sendMixpanelEvent = (name: string, payload = {}) => {
  const eventName = EVENT_PREFIX + '_' + name;
  if (process.env.NODE_ENV === "development") {
    console.log(eventName, payload, 'MIXPANEL EVENT')
  } else {
    mixpanel.track(eventName, payload);
  }
};

export const identifyMixpanel = (userId: number) => {
  mixpanel.identify(`${userId}`);
};

export const mixpanelUnRegister = (name: string) => {
  mixpanel.unregister(name);
};

export const mixpanelInrementValue = (property: string, value: number) => {
  mixpanel.people.increment(property, value);
};

export const mixpanelUserLogout = () => {
  mixpanel.reset();
};

export const mixpanelLoginRegister = (user: any) => {
  mixpanel.identify(`${user?.id}`);
  mixpanel.people.set({
    user_id: user.id,
    username: user.username,
    app_platform: "streamline",
  });
  mixpanel.register({
    app_platform: "streamline",
    username: user.username,
    user_id: user.id,
  });
};