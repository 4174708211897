import { AxiosResponse } from 'axios'
import httpService from '../httpService'
import { Profile } from '../types/Profile'

export function getStates(): Promise<AxiosResponse<Profile.State[]>> {
  return httpService.get(`/v7/content/sources/states/`)
}

export function getRoles(): Promise<AxiosResponse<Profile.Role[]>> {
  return httpService.get(`/v7/content/sources/roles/`)
}

export function getQualifications(): Promise<AxiosResponse<Profile.Qualification[]>> {
  return httpService.get(`/v7/content/sources/qualifications/`)
}

export function getStaffProfiles(
  filter: Profile.Filter,
): Promise<AxiosResponse<Profile.ProfileListResponse>> {
  return httpService.get(`/v7/content/sources/staffs/`, {
    params: filter,
  })
}

export function searchUserProfiles(phone: string): Promise<AxiosResponse<Profile.User>> {
  return httpService.get(`/v7/content/sources/staffs/search/`, {
    params: {
      phone,
    },
  })
}

export function getProfile(profileId: number): Promise<AxiosResponse<Profile.User>> {
  return httpService.get(`/v7/content/sources/staffs/${profileId}/`)
}

// POST api/v7/content/sources/staffs/

// Update Staff
// PUT api/v7/content/sources/staffs/<user_id>/

export function createProfile(
  profileDetails: Omit<Profile.User, 'id'>,
): Promise<AxiosResponse<Profile.User>> {
  return httpService.post(`/v7/content/sources/staffs/`, profileDetails)
}

export function updateProfile(
  profileId: number,
  profileDetails: Omit<Profile.User, 'id'>,
): Promise<AxiosResponse<Profile.User>> {
  return httpService.put(`/v7/content/sources/staffs/${profileId}/`, profileDetails)
}
