import { all, takeEvery, put, call, takeLatest, select } from 'redux-saga/effects'
import store from 'store'
import keyBy from 'lodash/keyBy'
import isEqual from 'lodash/isEqual'
import { login, logout } from '../../api/auth'
import { getCurrentUser, getUsers } from '../../api/lookups'
import {
  LOGIN_USER,
  FETCH_USER,
  LOGOUT_USER,
  FETCH_USERS,
  UserActionImpl,
  LoginImpl,
  UsersImpl,
  UserPartialImpl,
  LogoutImpl,
  UserFilter,
  UserGetAction,
} from './types'
import {
  requestUsers,
  requestUsersSuccess,
  requestUsersError,
  receivedUsers,
  requestUser,
  requestUserSuccess,
  requestUserError,
  receivedUser,
  resetUser,
  getUser,
} from './actions'
import { showErrorNotification } from '../notifications/actions'
import { deleteCookie } from '../../api/cookie'
import { initialUsersState } from './reducers'
import { AxiosResponse } from 'axios'
import { ViewPermissions } from '../../types/AccessControls'
import { saveViewAccess } from '../accessControls/actions'
import { removeSessionStorageData } from '../../helper/sessionStorage'
import { resetAccessControl } from '../accessControls/slice'
import { mixpanelLoginStatusEvent } from "../../tracker/mixpanel/events/user"
import { mixpanelLoginRegister, mixpanelUserLogout } from '../../tracker/mixpanel/mixPanel'

export function* LOGIN({ payload }: UserActionImpl) {
  const { phone, password } = <LoginImpl>payload
  yield put(requestUser())
  try {
    const response: AxiosResponse = yield call(login, phone, password)
    if (response) {
      store.set('token', response.data.token)
      yield put(getUser())
      yield put(requestUserSuccess())
    }
  } catch (error) {
    mixpanelLoginStatusEvent({ status: "fail", userId: undefined })
    yield put(requestUserError())
    yield put(showErrorNotification('Phone number / Password is wrong'))
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put(requestUser())
  try {
    let data = null
    if (!store.get('user')) {
      const response: AxiosResponse = yield call(getCurrentUser)
      data = response.data
      mixpanelLoginStatusEvent({ status: "success", userId: data?.id })
    } else {
      data = store.get('user')
    }
    if (data) {
      yield put(
        receivedUser({
          ...data,
          authorized: true,
        }),
      )
      store.set('user', data)
      const permissions: ViewPermissions = data.permissions
      yield put(saveViewAccess(permissions))
      yield put(requestUserSuccess())
      mixpanelLoginRegister(data)
    }
  } catch (e) {
    console.error(e)
    yield put(requestUserError())
  }
}

export function* LOGOUT({ payload }: UserActionImpl) {
  const { shouldCallAPI = true } = <LogoutImpl>payload
  try {
    if (shouldCallAPI) {
      yield call(logout)
      mixpanelUserLogout()
    }
  } catch (e) {
    console.log(e)
  }
  yield call(deleteCookie, 'csrftoken')
  store.remove('token')
  store.remove('user')
  removeSessionStorageData('access')
  yield put(resetUser())
  yield put(resetAccessControl())
}

export function* GET_USER_LIST({ payload: { filter } }: UserGetAction) {
  yield put(requestUsers())
  try {
    const response: AxiosResponse = yield call(getUsers, filter)
    if (response) {
      const { data }: { data: UserPartialImpl[] } = response
      const userToIdMap = keyBy(data, 'id')
      const allIds = Object.keys(userToIdMap)
      const adminIds: string[] = []
      const specialistIds: string[] = []
      const reviewerIds: string[] = []
      allIds.forEach(id => {
        switch (userToIdMap[id].role) {
          case 'admin': {
            adminIds.push(id)
            break
          }
          case 'content-reviewer': {
            reviewerIds.push(id)
            break
          }
          case 'data-specialist': {
            specialistIds.push(id)
            break
          }
        }
      })
      const payloadToStore: UsersImpl = {
        entities: userToIdMap,
        allIds,
        adminIds,
        reviewerIds,
        specialistIds,
        users: data,
      }
      yield put(receivedUsers(payloadToStore))
      yield put(requestUsersSuccess())
    }
  } catch (error) {
    yield put(requestUsersError())
    yield put(showErrorNotification('Something went wrong while fetching users'))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(LOGIN_USER, LOGIN),
    takeEvery(FETCH_USER, LOAD_CURRENT_ACCOUNT),
    takeEvery(LOGOUT_USER, LOGOUT),
    takeLatest(FETCH_USERS, GET_USER_LIST),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
