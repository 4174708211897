import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../..'
import { getTeachers } from '../../api/teachers'
import { combineReducers } from 'redux'
import { TeachersListFilter, TeachersListPayload, TeachersListState, TeachersResponse } from '../../types/Teachers'
import { pushParamsToUrl } from '../../helper/urlParamSerializer'

export const initialTeachersState: TeachersListState = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 20,
  },
  filter: {
    pageSize: 20,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const TeacherListSlice = createSlice({
  name: 'teacherState',
  initialState: initialTeachersState,
  reducers: {
    requestTeachers(state) {
      return { ...state, isLoading: true, hasError: false }
    },
    requestedTeachersFailed(state) {
      return { ...state, isLoading: false, hasError: true }
    },
    receivedTeachers(state, action: PayloadAction<TeachersListPayload>) {
      const { data, filter, meta } = action.payload
      const newFilter: TeachersListFilter = {
        ...state.filter,
        ...filter,
      }
      return { ...state, data, meta, filter: newFilter, isLoading: false, hasError: false }
    },
  },
})

function instanceOfTeachersListResponse(object: any): object is TeachersResponse {
  return 'results' in object
}

export const { receivedTeachers, requestTeachers, requestedTeachersFailed } =
  TeacherListSlice.actions

export const fetchTeachers = (filter?: TeachersListFilter): AppThunk => async dispatch => {
  dispatch(requestTeachers())
  try {
    const response = await getTeachers(filter)
    if (response) {
      if (instanceOfTeachersListResponse(response.data) && filter) {
        pushParamsToUrl(filter)
        const { results: data, ...meta } = response.data
        dispatch(receivedTeachers({
          data,
          filter,
          meta,
        }))
      } else {
        const data = response.data
        dispatch(receivedTeachers({
          data,
          filter,
        }))
      }
    }
  } catch (error) {
    dispatch(requestedTeachersFailed())
  }
}


export const selectTeachers = (state: RootState) => state.teachers.list

export default combineReducers({
  list: TeacherListSlice.reducer,
})
