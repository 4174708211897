export const REQUESTED_QUESTIONS = 'questions/REQUESTED'
export const REQUESTED_QUESTIONS_FAILED = 'questions/FAILED'
export const REQUESTED_QUESTIONS_SUCCEEDED = 'questions/SUCCEEDED'
export const RECEIVED_QUESTIONS = 'questions/RECEIVED'
export const SET_QUESTIONS = 'questions/SET'
export const APPROVE_QUESTIONS = 'questions/APPROVE'
export const PATCH_QUESTIONS = 'questions/PATCH'

export const REQUESTED_QUESTION = 'question/REQUESTED'
export const REQUESTED_QUESTION_FAILED = 'question/FAILED'
export const REQUESTED_QUESTION_SUCCEEDED = 'question/SUCCEEDED'
export const RECEIVED_QUESTION = 'question/RECEIVED'
export const FETCH_QUESTION = 'question/FETCH'
export const RESET_QUESTION = 'question/RESET'
export const APPROVE_QUESTION = 'question/APPROVE'
export const APPROVE_QA_STATUS = 'question/APPROVE_QA_STATUS'
export const APPROVE_QA_QUESTION = 'question/QA_APPROVE'
export const APPEAL_QA_QUESTION = 'question/QA_APPEAL'

export const AH_APPEAL_ACCEPT = 'question/AH_APPEAL_ACCEPT'
export const AH_APPEAL_REJECT = 'question/AH_APPEAL_REJECT'
export const AH_REJECT_QUESTION = 'question/AH_REJECT_QUESTION'

export const PATCH_QUESTION = 'question/PATCH'

export const FETCH_QUESTIONS_FOR_ADMIN = 'questions/FETCH_FOR_ADMIN'
export const FETCH_QUESTIONS_FOR_CE = 'questions/FETCH_FOR_CE'
export const FETCH_QUESTIONS_FOR_QA_LISTING = 'questions/FETCH_FOR_QA_LISTING'
export const FETCH_QUESTIONS_FOR_APPEAL_LISTING = 'questions/FETCH_FOR_APPEAL_LISTING'
export const FETCH_QUESTIONS_FOR_EXPIRED_LISTING = 'questions/FETCH_FOR_EXPIRED_LISTING'

export const REQUESTED_COMMON_MISTAKES = 'common-mistakes/REQUESTED'
export const REQUESTED_COMMON_MISTAKES_FAILED = 'common-mistakes/FAILED'
export const REQUESTED_COMMON_MISTAKES_SUCCEEDED = 'common-mistakes/SUCCEEDED'
export const RECEIVED_COMMON_MISTAKES = 'common-mistakes/RECEIVED'
export const FETCH_COMMON_MISTAKES = 'common-mistakes/FETCH'

/**
 * /////////////////////////////////////////////////////
 * ***************** Questions / List ******************
 * /////////////////////////////////////////////////////
 */

/**
 * Notifies store that questions is being fetched
 */
export const requestQuestions = () => ({ type: REQUESTED_QUESTIONS })

/**
 * Notifies store that questions is fetch was a success
 */
export const requestQuestionsSuccess = () => ({ type: REQUESTED_QUESTIONS_SUCCEEDED })

/**
 * Notifies store that questions is fetch failed
 */
export const requestQuestionsError = () => ({ type: REQUESTED_QUESTIONS_FAILED })

/**
 * Sets received questions to store
 */
export const receivedQuestions = data => ({ type: RECEIVED_QUESTIONS, data })

export const setReceivedQuestions = data => ({ type: SET_QUESTIONS, payload: data })

/**
 * Initiates request to fetch questions
 */
export const getQuestionsAdmin = (id, filter) => ({
  type: FETCH_QUESTIONS_FOR_ADMIN,
  payload: {
    id,
    filter,
  },
})

/**
 * Initiates request to fetch questions
 */
export const getQuestionsQA = (languageCode, jobId, qcId, filter) => ({
  type: FETCH_QUESTIONS_FOR_QA_LISTING,
  payload: {
    languageCode,
    jobId,
    qcId,
    filter,
  },
})

/**
 * Initiates request to fetch questions
 */
export const getQuestionsCR = (jobId, qcId, filter) => ({
  type: FETCH_QUESTIONS_FOR_CE,
  payload: {
    jobId,
    qcId,
    filter,
  },
})

/**
 * Initiates request to fetch questions
 */
export const getQuestionsAppeal = language => ({
  type: FETCH_QUESTIONS_FOR_APPEAL_LISTING,
  payload: {
    language,
  },
})

/**
 * Initiates request to fetch expired questions
 * @param {object} filter question object filter
 */
export const fetchExpiredQuestions = filter => ({
  type: FETCH_QUESTIONS_FOR_EXPIRED_LISTING,
  payload: {
    ...filter,
  },
})

/**
 * Initiates a request to update question
 * @param {Number} id question id
 * @param {Object} updatedQuestionProp updated question object
 */
export const patchQuestions = (id, updatedQuestionProp) => ({
  type: PATCH_QUESTIONS,
  payload: {
    id,
    updatedQuestionProp,
  },
})

/**
 * /////////////////////////////////////////////////////
 * ************************ Question *******************
 * /////////////////////////////////////////////////////
 */

/**
 * Notifies store that question is being fetched
 */
export const requestQuestion = () => ({ type: REQUESTED_QUESTION })

/**
 * Notifies store that question is fetch was a success
 */
export const requestQuestionSuccess = () => ({ type: REQUESTED_QUESTION_SUCCEEDED })

/**
 * Notifies store that question is fetch failed
 */
export const requestQuestionError = () => ({ type: REQUESTED_QUESTION_FAILED })

/**
 * Sets received questions to store
 */
export const receivedQuestion = data => ({ type: RECEIVED_QUESTION, payload: data })

/**
 * Initiates request to fetch question
 */
export const getQuestion = id => ({
  type: FETCH_QUESTION,
  payload: {
    id,
  },
})

/**
 * Initiates a request to update question
 * @param {Number} id question id
 * @param {Object} updatedQuestionProp updated question object
 */
export const patchQuestion = (id, updatedQuestionProp) => ({
  type: PATCH_QUESTION,
  payload: {
    id,
    updatedQuestionProp,
  },
})

/**
 * Initiates request to fetch question
 */
export const resetQuestion = id => ({
  type: RESET_QUESTION,
  payload: {
    id,
  },
})

/**
 * Notifies reducer to update localized question
 * @param {string} langCode question language code
 * @param {boolean} isApproved if localized question is approved
 */
export const approveLocalQuestion = (langCode, isApproved) => ({
  type: APPROVE_QUESTION,
  payload: {
    [langCode]: isApproved,
  },
})

/**
 * /////////////////////////////////////////////////////
 * ************************ QA *******************
 * /////////////////////////////////////////////////////
 */

/**
 *
 * @param {number} jobId job id
 * @param {number} qcId question collection id
 * @param {number} questionGroupId question group id
 * @param {object} rejectionPayload contains questionErrors and comment
 */
export const rejectQuestionInCollection = (jobId, qcId, questionGroupId, rejectionPayload) => ({
  type: AH_REJECT_QUESTION,
  payload: {
    jobId,
    qcId,
    questionGroupId,
    rejectionPayload,
  },
})

/**
 * Notifies reducer to update localized question
 * @param {string} langCode question language code
 * @param {boolean} isApproved if localized question is approved
 */
export const approveLocalQuestions = (id, langCode, isApproved) => ({
  type: APPROVE_QUESTIONS,
  payload: {
    id,
    langCode,
    isApproved,
  },
})

/**
 * Notifies reducer to update localized question
 * @param {Number} id question id
 * @param {string} langCode question language code
 * @param {boolean} status Question status 'approved' or rejected
 */
export const approveQAStatus = (id, langCode, isApproved) => ({
  type: APPROVE_QA_STATUS,
  payload: {
    id,
    langCode,
    isApproved,
  },
})

export const acceptQuestionAppeal = (questionGroupId, languageCode) => ({
  type: AH_APPEAL_ACCEPT,
  payload: {
    questionGroupId,
    languageCode,
  },
})

export const rejectQuestionAppeal = (questionGroupId, languageCode, comments) => ({
  type: AH_APPEAL_REJECT,
  payload: {
    questionGroupId,
    rejectionPayload: {
      languageCode,
      comments,
    },
  },
})

export const approveQuestion = approvalDetails => ({
  type: APPROVE_QA_QUESTION,
  payload: {
    approvalDetails,
  },
})

export const appealQuestion = (questionGroupId, languageCode) => ({
  type: APPEAL_QA_QUESTION,
  payload: {
    questionGroupId,
    languageCode,
  },
})

/**
 *
 * Common Mistakes
 *
 */
export const requestCommonMistakes = () => ({ type: REQUESTED_COMMON_MISTAKES })
export const requestCommonMistakesSuccess = () => ({ type: REQUESTED_COMMON_MISTAKES_SUCCEEDED })
export const requestCommonMistakesError = () => ({ type: REQUESTED_COMMON_MISTAKES_FAILED })
export const receivedCommonMistakes = data => ({
  type: RECEIVED_COMMON_MISTAKES,
  payload: data,
})
export const getCommonMistakes = () => ({ type: FETCH_COMMON_MISTAKES })
