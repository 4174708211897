import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../.."
import { ElevateOverallBatchReportState, ElevateStudentListingState, OverallReport, StudentListing, StudentReportState, StudentReport, ElevateCourseDataState, ElevateCourseData, DetailedReportState, DetailedReport } from "./types"


const initialOverallState: ElevateOverallBatchReportState = {
    loading: false,
    data: null
}

const overallBatchReport = createSlice({
    name: "overallBatchReport",
    initialState: initialOverallState,
    reducers: {
        fetchOverallReport: (state) => {
            state.loading = true
        },
        receivedOverallReport: (state, action: PayloadAction<OverallReport>) => {
            return state = {
                loading: false,
                data: action.payload
            }
        },
        fetchOverallReportError: (state) => {
            return state = {
                loading: false,
                data: null,
                error: "Something went wrong"
            }
        }
    }
});

export const { fetchOverallReport, receivedOverallReport, fetchOverallReportError } = overallBatchReport.actions;

const initialStudentListing: ElevateStudentListingState = {
    loading: false,
    data: null
}
const studentsListing = createSlice({
    name: 'studentsListing',
    initialState: initialStudentListing,
    reducers: {
        fetchBatchStudents: (state) => {
            state.loading = true
            state.error = undefined
        },
        receivedBatchStudents: (state, action: PayloadAction<StudentListing.Root>) => {
            return state = {
                loading: false,
                data: action.payload,
                error: undefined
            }
        },
        fetchStudentsError: (state) => {
            return state = {
                loading: false,
                data: null,
                error: "Something went wrong"
            }
        }
    }
})

export const { fetchBatchStudents, receivedBatchStudents, fetchStudentsError } = studentsListing.actions

const initialStudentReport: ElevateCourseDataState = {
    loading: false,
    data: null
}

const studentReport = createSlice({
    name: 'studentReport',
    initialState: initialStudentReport,
    reducers: {
        fetchStudentReport: (state) => {
            return state = {
                loading: true,
                data: null,
            }
        },
        receivedStudentReport: (state, action: PayloadAction<ElevateCourseData.Root>) => {
            return state = {
                loading: false,
                data: action.payload,
                error: ""
            }
        },
        errorStudentReport: (state) => {
            return state = {
                loading: false,
                data: null,
                error: "Something went wrong"
            }
        }
    }
})

const initialOldStudentReport: StudentReportState = {
    loading: false,
    data: null
}


const oldStudentReport = createSlice({
    name: 'oldStudentReport',
    initialState: initialOldStudentReport,
    reducers: {
        fetchOldStudentReport: (state) => {
            return state = {
                loading: true,
                data: null,
            }
        },
        receivedOldStudentReport: (state, action: PayloadAction<StudentReport.Root>) => {
            return state = {
                loading: false,
                data: action.payload,
                error: ""
            }
        },
        errorOldStudentReport: (state) => {
            return state = {
                loading: false,
                data: null,
                error: "Something went wrong"
            }
        }
    }
})


const initialDetailedReport: DetailedReportState = {
    loading: false,
    data: null
}

const detailedStudentReport = createSlice({
    name: 'detailedStudentReport',
    initialState: initialDetailedReport,
    reducers: {
        fetchDetailedReport: (state) => {
            return state = {
                loading: true,
                data: null,
                error: ""
            }
        },
        receivedDetailedReport: (state, action: PayloadAction<DetailedReport.Root>) => {
            return state = {
                loading: false,
                data: action.payload,
                error: ""
            }
        },
        errorDetailedReport: (state) => {
            return state = {
                loading: false,
                data: null,
                error: "Something Went wrong"
            }
        }
    }
})


export const { fetchStudentReport, receivedStudentReport, errorStudentReport } = studentReport.actions;
export const { fetchOldStudentReport, receivedOldStudentReport, errorOldStudentReport } = oldStudentReport.actions;
export const { fetchDetailedReport, receivedDetailedReport, errorDetailedReport } = detailedStudentReport.actions

export const overallReportSelector = (state: RootState) => state.elevateBatchReport.overall;
export const studentsSelector = (state: RootState) => state.elevateBatchReport.students;
export const studentReportSelector = (state: RootState) => state.elevateBatchReport.studentReport;
export const oldStudentReportSelector = (state: RootState) => state.elevateBatchReport.oldStudentReport;
export const studentDetailedReportSelector = (state: RootState) => state.elevateBatchReport.detailedReport;

export default combineReducers({
    overall: overallBatchReport.reducer,
    students: studentsListing.reducer,
    studentReport: studentReport.reducer,
    oldStudentReport: oldStudentReport.reducer,
    detailedReport: detailedStudentReport.reducer
})