export const SET_STATE = 'settings/SET_STATE'
export const CHANGE_SETTING = 'settings/CHANGE_SETTING'
export const UPDATE_AVAILABLE = 'settings/UPDATE_AVAILABLE'
export const UPDATE_SUCCESS = 'settings/UPDATE_SUCCESS'
export const APPLY_UPDATE = 'settings/UPDATE_APPLY'

export const updateAvailable = () => ({ type: UPDATE_AVAILABLE })
export const updateSuccess = () => ({ type: UPDATE_SUCCESS })
export const applyUpdate = () => ({ type: APPLY_UPDATE })
export const updateSettings = payload => ({ type: SET_STATE, payload })
