import { combineReducers } from 'redux'
import {
  RECEIVED_JOB,
  RECEIVED_JOBS,
  REQUESTED_JOB,
  REQUESTED_JOBS,
  REQUESTED_JOBS_FAILED,
  REQUESTED_JOBS_SUCCEEDED,
  REQUESTED_JOB_FAILED,
  REQUESTED_JOB_SUCCEEDED,
  UPDATE_JOBS,
  JobActionImpl,
  JobsStateImpl,
  JobStateImpl,
  JobsImpl,
  JobImpl,
  UPDATE_JOB,
  JobDetailImpl,
  JobStatus,
  JobTypeStateImpl,
  REQUESTED_JOB_TYPES,
  REQUESTED_JOB_TYPES_SUCCEEDED,
  REQUESTED_JOB_TYPES_FAILED,
  RECEIVED_JOB_TYPES,
  JobTypesImpl,
  FilterImpl,
  SAVE_JOBS_FILTER,
  RESET_JOB,
} from './types'
import comments from './comments/reducers'

export const initialJob: JobStateImpl = {
  data: {
    files: [],
    job: {},
    questionCollections: [],
    studycardPacks: [],
    studycardStacks: [],
  },
  isLoading: false,
  hasError: false,
}

export const initialJobList: JobsStateImpl = {
  data: [],
  meta: {
    page: 1,
    pageCount: 1,
    limit: 50,
  },
  filter: {
    jobStatus: JobStatus.all,
    jobCategory: 'NORMAL',
    pageSize: 50,
    page: 1,
  },
  isLoading: false,
  hasError: false,
}

const initialJobType: JobTypeStateImpl = {
  data: [],
  isLoading: false,
  hasError: false,
}

const jobsList = (state: JobsStateImpl = initialJobList, action: JobActionImpl): JobsStateImpl => {
  switch (action.type) {
    case REQUESTED_JOBS:
      return { ...state, isLoading: true, hasError: false }
    case REQUESTED_JOBS_SUCCEEDED:
      return { ...state, isLoading: false, hasError: false }
    case REQUESTED_JOBS_FAILED:
      return { ...state, isLoading: false, hasError: true }
    case RECEIVED_JOBS: {
      const { data, meta, filter } = action.payload as JobsImpl
      return { ...state, data, meta, filter }
    }
    case SAVE_JOBS_FILTER:
      return { ...state, filter: action.payload as FilterImpl }
    case UPDATE_JOBS: {
      const newJob = action.payload as JobImpl
      return {
        ...state,
        data: state.data?.map(job => {
          if (job.id === newJob.id) {
            return newJob
          }
          return job
        }),
      }
    }
    default:
      return state
  }
}

function JobDetails(state: JobStateImpl = initialJob, action: JobActionImpl): JobStateImpl {
  switch (action.type) {
    case REQUESTED_JOB:
      return { ...state, isLoading: true, hasError: false }
    case REQUESTED_JOB_SUCCEEDED:
      return { ...state, isLoading: false, hasError: false }
    case REQUESTED_JOB_FAILED:
      return { ...state, isLoading: false, hasError: true }
    case RECEIVED_JOB: {
      const data = action.payload as JobDetailImpl
      return { ...state, data }
    }
    case UPDATE_JOB: {
      const data = action.payload as JobImpl
      return {
        ...state,
        data: {
          ...state.data,
          job: data,
        },
      }
    }
    case RESET_JOB:
      return initialJob
    default:
      return state
  }
}

function jobTypes(state = initialJobType, action: JobActionImpl) {
  switch (action.type) {
    case REQUESTED_JOB_TYPES:
      return { ...state, isLoading: true, hasError: false }
    case REQUESTED_JOB_TYPES_SUCCEEDED:
      return { ...state, isLoading: false, hasError: false }
    case REQUESTED_JOB_TYPES_FAILED:
      return { ...state, isLoading: false, hasError: true }
    case RECEIVED_JOB_TYPES: {
      const data = action.payload as JobTypesImpl
      return {
        ...state,
        data,
      }
    }
    default:
      return state
  }
}

export { jobsList, JobDetails, jobTypes }

export const jobs = combineReducers({
  list: jobsList,
  details: JobDetails,
  jobTypes,
  comments,
})
