import httpService from '../httpService'
import {
  Filter,
  VideoPack,
  PlayList,
  Video,
  PlatformFilters,
  SubjectFilters,
  ModuleRelationFilters,
  QaVideoListingFilter,
  PackSortingPayload,
  ValidateVideoPack,
} from '../redux/videos/types'

import { VideoQAPayload } from '../types/VideoQA'
import { GetPreSignedURL } from '../types/HDVideo'

// Pack related API start from here
export const getTracks = () => {
  return httpService.get(`/v6/video-dashboard/tracks/`)
}

export const getVideoPacks = (filter: Filter) => {
  return httpService.get(`/v6/video-dashboard/packs/`, {
    params: filter,
  })
}

export const getVideoPack = (id: number) => {
  return httpService.get(`/v6/video-dashboard/packs/${id}/`)
}

export const getSortedVideoPacks = (filter: Filter) => {
  return httpService.get(`/v6/video-dashboard/packs/sorting_list/`, {
    params: filter,
  })
}

export const updateVideoPack = (id: number, payload: VideoPack) => {
  return httpService.put(`/v6/video-dashboard/packs/${id}/`, { ...payload })
}

export const addVideoPack = (payload: VideoPack) => {
  return httpService.post(`/v6/video-dashboard/packs/`, { ...payload })
}

export const togglePackStatus = (packId: number) => {
  return httpService.put(`/v6/video-dashboard/packs/${packId}/activate/`)
}

export const getPlatforms = (filter: PlatformFilters) => {
  return httpService.get(`/v6/video-dashboard/platforms/`, {
    params: filter,
  })
}

export const getSubjects = (filter: SubjectFilters) => {
  return httpService.get(`/v6/video-dashboard/subjects/`, {
    params: filter,
  })
}

export const getModuleRelations = (filter: ModuleRelationFilters) => {
  return httpService.get(`/v6/video-dashboard/module-relations/`, { params: filter })
}

export const updatePackOrder = (payload: PackSortingPayload) => {
  return httpService.put(`/v6/video-dashboard/packs/sorting/`, {
    ...payload,
  })
}

export const validateVideoPack = (payload: ValidateVideoPack) => {
  return httpService.post('/v6/video-dashboard/packs/validate/', payload)
}

// playlist related API start from here
export const getPlaylists = (packId: number, filter: Filter) => {
  return httpService.get(`/v6/video-dashboard/packs/${packId}/playlists/`, {
    params: filter,
  })
}

export const getPlaylist = (playlistId: number) => {
  return httpService.get(`/v6/video-dashboard/playlists/${playlistId}/`)
}

export const addPlaylist = (payload: PlayList) => {
  return httpService.post(`/v6/video-dashboard/playlists/`, { ...payload })
}

export const updatePlaylist = (playlistId: number, payload: PlayList) => {
  return httpService.put(`/v6/video-dashboard/playlists/${playlistId}/`, {
    ...payload,
  })
}

// video related API start from here
export const getVideos = (playlistId: number, filter: Filter) => {
  return httpService.get(`/v6/video-dashboard/playlists/${playlistId}/videos/`, {
    params: filter,
  })
}

export const getVideo = (videoId: number, params: { packId: number }) => {
  return httpService.get(`/v6/video-dashboard/videos/${videoId}/`, {
    params,
  })
}

export const addVideo = (payload: Video) => {
  return httpService.post(`/v6/video-dashboard/videos/`, {
    ...payload,
  })
}
export const updateVideo = (videoId: number, payload: Video) => {
  return httpService.put(`/v6/video-dashboard/videos/${videoId}/`, {
    ...payload,
  })
}

export const updateVideoStatus = (videoId: number, payload: { isActive: boolean }) => {
  return httpService.patch(`/v6/video-dashboard/videos/${videoId}/`, {
    ...payload,
  })
}

export const updateVideoWeight = (playlistId: number, payload: Video) => {
  return httpService.put(`/v6/video-dashboard/playlists/${playlistId}/sorting/`, {
    ...payload,
  })
}

// HD videos related API strat from here
export const getHDVideos = (filter: Filter) => {
  return httpService.get(`/v6/video-dashboard/hdvideos/`, {
    params: filter,
  })
}

export const addHDVideo = (payload: any) => {
  return httpService.post(`/v6/video-dashboard/hdvideos/`, payload)
}

export const updateHDVideo = (hdVideoId: number, payload: any) => {
  return httpService.put(`/v6/video-dashboard/hdvideos/${hdVideoId}/`, payload)
}

export const getHDVideo = (hdVideoId: number) => {
  return httpService.get(`/v6/video-dashboard/hdvideos/${hdVideoId}/`)
}

export const getPresignedS3Url = (payload: GetPreSignedURL) => {
  return httpService.get(`/v6/video-dashboard/hdvideos/presigned_url/`, {
    params: payload,
  })
}

// Video QA related end points
export const getVideoQA = (parentJobId: number, childJobId: number) => {
  return httpService.get(`/v6/content/sources/jobs/${parentJobId}/videos/${childJobId}/`)
}

export const submitQaReport = (
  parentJobId: number,
  childJobId: number,
  payload: VideoQAPayload,
) => {
  return httpService.post(
    `/v6/content/sources/jobs/${parentJobId}/videos/${childJobId}/reports/`,
    payload,
  )
}

export const getQAVideos = (filter: QaVideoListingFilter) => {
  return httpService.get(`/v6/content/sources/jobs/videos/`, { params: filter })
}
